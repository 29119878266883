import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/vendors-admin-view.css';
import {Button, Icon, Card, Select, Image} from "semantic-ui-react";
import Request from "../../Request";

class VendorsAdminView extends React.Component {

    state = {
        viewId: null,
        vendor: {},
        renderVendor: false,
        pickedStore: {},
        renderStoresSelect: false,
        hideDeactivatedCategories: true,
        hideDeactivatedItems: true,
        renderProduct: false,
        products: []
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({viewId: this.props.match.params.id}, ()=>{
                this.getVendor();
                this.getProducts();
            })
        }
    }

    getVendor = () => {
        if(this.state.viewId) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + this.state.viewId + '/view';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let vendor = JSON.parse(response);
                    if(vendor && vendor.success === true && vendor.data) {
                        this.setState({vendor: vendor.data}, () => {
                            this.setState({renderVendor: true});
                            this.transformStoresToOptions();
                        });
                    }
                    else {
                        this.setState({vendor: {}}, () => {
                            this.setState({renderVendor: true})
                        });
                    }
                })
                .catch(error => {
                    this.setState({vendor: {}}, () => {
                        this.setState({renderVendor: true})
                    });
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Vendor', 'Error');
                    }
                });
        }
    };

    getProducts = () => {
        new Promise((resolve, reject) => {
            let items = this.state.hideDeactivatedItems === null ? '' : ('&activeP=' + (this.state.hideDeactivatedItems ? 1 : 0));
            let path = '/vendors/' + this.state.viewId + '/products?activeC=' + (this.state.hideDeactivatedCategories ? 1 : 0) + items;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let products = JSON.parse(response);
                if(products && products.success === true && products.data) {
                    this.setState({products: products.data}, () => {
                        this.setState({renderProducts:true})
                    });
                }
                else {
                    this.setState({products: []}, () => {
                        this.setState({renderProducts:true})
                    });
                    NotificationManager.error('Could not get Products', 'Error');
                }
            })
            .catch(error => {
                this.setState({products: []}, () => {
                    this.setState({renderProducts:true})
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Products', 'Error');
                }
            });
    };

    deactivateVendor = () => {
        if(this.state.viewId) {
            new Promise((resolve, reject) => {
                let path = '/vendors/'+ this.state.viewId + '/deactivate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully deactivated Vendor', 'Success');
                        this.redirectBack();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Vendor', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not deactivate Vendor', 'Error');
        }
    };

    linkToStore = () => {
        let viewId = this.state.viewId;
        let storeId = this.state.pickedStore.id;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + viewId + '/link/' + storeId;
                this.request.post(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully linked Store', 'Success');
                    this.getVendor();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not link Store', 'Error');
                    }
                });
        }
    };

    unlinkFromStore = (storeId) => {
        let viewId = this.state.viewId;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + viewId + '/link/' + storeId;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully unlinked Store', 'Success');
                    this.getVendor();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not unlink Store', 'Error');
                    }
                });
        }
    };

    getStoresTable = () => {
        let stores = this.state.vendor.stores || [];
        return (
            <div>
                <div className="vendors-admin-view-table-div">
                    <table className="vendors-admin-view-table" align="center">
                        <thead>
                        <tr>
                            <th className="vendors-admin-view-table-header">#</th>
                            <th className="vendors-admin-view-table-header">Title</th>
                            <th className="vendors-admin-view-table-header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        { stores.linked.map((store, index) =>{
                            return (
                                <tr key={index}>
                                    <td>{store.number || ''}</td>
                                    <td>{store.title || ''}</td>
                                    <td className="vendors-admin-view-table-actions-td">
                                        <Button.Group icon>
                                            <Button size='tiny' onClick={()=>{this.redirectToStoresEdit(store.id)}}>
                                                <Icon name='eye' />
                                            </Button>
                                            <Button size='tiny' onClick={()=>this.unlinkFromStore(store.id)}>
                                                <Icon name='unlink' />
                                            </Button>
                                        </Button.Group>
                                    </td>
                                </tr>
                            )
                        })
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
                <div>
                    { this.state.renderStoresSelect ?
                        <div className="users-admin-view-stores-card-actions">
                            <Select
                                text={this.state.pickedStore.title}
                                value={this.state.pickedStore.id}
                                options={this.state.storeOptions}
                                onChange={this.handleChangeStore}
                            />
                            <Button color="orange" size="mini" onClick={this.linkToStore}>
                                Link to Store
                            </Button>
                        </div>
                        : ''
                    }
                    <Button icon labelPosition='left' color="orange" onClick={this.redirectToStores}>
                        <Icon name='plus' />
                        Store
                    </Button>
                </div>
            </div>
        )
    };

    cloneProduct = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id + '/clone';
                this.request.post(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true && response.data && response.data.id) {
                        let id = response.data.id;
                        this.redirectToProductsEdit(id);
                        NotificationManager.success('Successfully cloned Product', 'Success');
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not clone Product', 'Error');
                    }
                });
        }
    };

    deactivateProduct = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id + '/deactivate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deactivated Product', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Product', 'Error');
                    }
                });
        }
    };

    activateProduct = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id + '/activate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully activated Product', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate Product', 'Error');
                    }
                });
        }
    };

    activateCategorie = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + id + '/activate';
                let params = null;
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully activated Category', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate Category', 'Error');
                    }
                });
        }
    };

    deactivateCategory = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + id + '/deactivate';
                let params = null;
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deactivated Category', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Category', 'Error');
                    }
                });
        }
    };

    redirectToProductsEdit = (productId) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/products/' + productId;
    };

    redirectToNewProduct = (productId) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/products/' + productId + '/' + this.state.viewId;
    };

    getOneProductTable = (products, category) => {
        return (
            <div>
                <div className="vendors-admin-view-table-div">
                    <table className="vendors-admin-view-table" align="center">
                        <thead>
                        <tr>
                            <th className="vendors-admin-view-table-header">Image</th>
                            <th className="vendors-admin-view-table-header">Item #</th>
                            <th className="vendors-admin-view-table-header">Description</th>
                            <th className="vendors-admin-view-table-header">Cost</th>
                            <th className="vendors-admin-view-table-header">Retail</th>
                            <th className="vendors-admin-view-table-header">U/M</th>
                            <th className="vendors-admin-view-table-header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            { products && Array.isArray(products) ?
                                products.map((product, index) => {
                                    return (
                                        <tr key={index} className={product.active === '0' ? "vendors-admin-view-table-deactivated-row" : ''}>
                                            <td>
                                                { product.file ?
                                                    <Image avatar size="massive" src={product.file || ''} />
                                                    : ''
                                                }
                                            </td>
                                            <td className="vendors-admin-view-table-link-td">
                                                <a href={'/admin/products/' + product.id}><b>{product.item_no || ''}</b></a>
                                            </td>
                                            <td>{product.description || ''}</td>
                                            <td>{product.cost || ''}</td>
                                            <td>{product.retail || ''}</td>
                                            <td>{product.um || ''}</td>
                                                <td className="vendors-admin-view-table-actions-td">
                                                    <Button.Group icon>
                                                        <Button size='tiny' title="Edit Product" onClick={()=>this.redirectToProductsEdit(product.id)}>
                                                            <Icon name='pencil' />Edit
                                                        </Button>
                                                        <Button size='tiny' title="Copy Product" onClick={()=>this.cloneProduct(product.id)}>
                                                            <Icon name='copy' />Copy
                                                        </Button>
                                                        { product.active === '0' ?
                                                            <Button size='tiny' title="Activate Product" onClick={()=>this.activateProduct(product.id)}>
                                                                <Icon name='refresh' />Activate
                                                            </Button>
                                                            :
                                                            <Button size='tiny' title="Deactivate Product" onClick={()=>this.deactivateProduct(product.id)}>
                                                                <Icon name='trash' />Deactivate
                                                            </Button>
                                                        }
                                                    </Button.Group>
                                                </td>

                                        </tr>
                                    )
                                })
                                :''
                            }
                        </tbody>
                    </table>
                </div>
                <br/>
                { category.id !== 0 ?
                    category.deleted_at !== null ?
                            <div>
                                <Button icon labelPosition='left' color="orange" onClick={()=>this.redirectToCategorieEdit(category.id)}>
                                    <Icon name='pencil' />
                                    Edit
                                </Button>
                                <Button icon labelPosition='left' color="orange" onClick={()=>this.activateCategorie(category.id)}>
                                    <Icon name='refresh' />
                                    Activate
                                </Button>
                            </div>
                            :
                            <div>
                                <Button icon labelPosition='left' color="orange" onClick={()=>this.redirectToCategorieEdit(category.id)}>
                                    <Icon name='pencil' />
                                    Edit
                                </Button>
                                <Button icon labelPosition='left' color="orange" onClick={()=>this.deactivateCategory(category.id)}>
                                    <Icon name='trash' />
                                    Deactivate
                                </Button>
                            </div>
                    :
                    ''
                }
            </div>
        )
    };

    getProductsTables = () => {
        let products = this.state.products;
        return (
            <div>
                { products.map((product, index) => {
                    return (
                        <div className="vendors-admin-view-products-table-div" key={index}>
                            <div className="vendors-admin-view-products-card-div">
                                <Card className="vendors-admin-view-products-card" fluid>
                                    <Card.Content className="vendors-admin-view-products-card-header-2" header={<h3>{product.category.name}</h3>} />
                                    <Card.Content className="vendors-admin-view-products-card-description" description={this.getOneProductTable(product.products, product.category)} />
                                </Card>
                            </div>
                        </div>
                    )
                })

                }
                <br/>
                <div>
                    <Button icon labelPosition='left' color="orange" onClick={()=>this.redirectToNewProduct('new')}>
                        <Icon name='plus' />
                        Product
                    </Button>
                </div>
            </div>
        )
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors';
    };

    redirectToEdit = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors/' + this.state.vendor.id + '/edit';
    };

    redirectToCategorieEdit = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/categories/' + id;
    };

    redirectToStores = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/new';
    };

    redirectToStoresEdit = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/' + id;
    };

    transformStoresToOptions = () => {
        let stores = this.state.vendor.stores.unlinked;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.id,
                key: store.id,
                value: store.id,
                text: store.title
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedStore: stores[0]}, ()=>{
                this.setState({renderStoresSelect: true});
            });
        }
        else {
            this.setState({renderStoresSelect: false});
        }

        this.setState({storeOptions: options})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.vendor.stores.unlinked.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundStore) {
            this.setState({pickedStore: foundStore});
        }
    };

    toogleProductsCategories = () => {
        if(this.state.hideDeactivatedCategories === true) {
            this.setState({hideDeactivatedCategories: !this.state.hideDeactivatedCategories, hideDeactivatedItems: null}, () => {
                this.getProducts()
            })
        } else {
            this.setState({hideDeactivatedCategories: !this.state.hideDeactivatedCategories, hideDeactivatedItems: true}, () => {
                this.getProducts()
            })
        }

    };

    toogleProductsItems = () => {
        if(this.state.hideDeactivatedItems === true) {
            this.setState({hideDeactivatedCategories: true, hideDeactivatedItems: null}, () => {
                this.getProducts()
            })
        }
        else {
            this.setState({hideDeactivatedCategories: true, hideDeactivatedItems: true}, () => {
                this.getProducts()
            })
        }

    };

    render() {
        return (
            <div className="vendors-admin-view-root">
                {this.state.renderVendor ?
                    <div>
                        <div className="vendors-admin-view-header">
                            <h2><Icon color="orange" name="truck" /> {this.state.vendor.name} <small>(VENDOR)</small></h2>
                        </div>
                        <div className="vendors-admin-view-header-buttons">
                            <Button color="orange" icon labelPosition='left' size="tiny" onClick={this.redirectToEdit}>
                                <Icon name='pencil' />
                                Edit
                            </Button>
                            <Button color="orange" icon labelPosition='left' size="tiny" onClick={this.deactivateVendor}>
                                <Icon name='trash' />
                                Deactivate
                            </Button>
                            <Button color="orange" icon labelPosition='left' size="tiny" onClick={this.redirectBack}>
                                <Icon name='angle left' />
                                Back to all Vendors
                            </Button>
                        </div>
                        <div className="vendors-admin-view-header-informations">
                            <b>Address</b><br/>
                            <span>{this.state.vendor.street1}</span><br/>
                            <span>{this.state.vendor.city + ', ' + this.state.vendor.state + ' ' + this.state.vendor.zip }</span><br/><br/>
                            <b>Sales Contact</b><br/>
                            <span>{this.state.vendor.salesContact}</span><br/><br/>
                            <b>Phone</b><br/>
                            <span>{this.state.vendor.phone}</span><br/><br/>
                            <b>Email</b><br/>
                            <span>{this.state.vendor.email}</span><br/><br/>
                        </div>
                        <div className="vendors-admin-view-stores-table-div">
                            <div className="vendors-admin-view-stores-card-div">
                                <Card className="vendors-admin-view-stores-card" fluid>
                                    <Card.Content className="vendors-admin-view-stores-card-header" header={<h3>Stores Serviced</h3>} />
                                    <Card.Content className="vendors-admin-view-stores-card-description" description={this.getStoresTable()} />
                                </Card>
                            </div>
                        </div>
                        <br/>
                        <div>
                            {this.state.hideDeactivatedCategories === true && this.state.hideDeactivatedItems === true ?
                                <div>
                                    <Button color="orange"  size="tiny" onClick={this.toogleProductsCategories}>Show Deactivated Categories</Button>
                                    <Button color="orange"  size="tiny" onClick={this.toogleProductsItems}>Show Deactivated Items</Button>
                                </div>
                                : ''
                            }
                            { this.state.hideDeactivatedCategories === false ?
                                <div>
                                    <Button color="orange"  size="tiny" onClick={this.toogleProductsCategories}>Show Active Categories</Button>
                                </div>
                                : ''
                            }
                            { this.state.hideDeactivatedCategories === true && this.state.hideDeactivatedItems === null ?
                                <div>
                                    <Button color="orange"  size="tiny" onClick={this.toogleProductsCategories}>Show Deactivated Categories</Button>
                                    <Button color="orange"  size="tiny" onClick={this.toogleProductsItems}>Hide Deactivated Items</Button>
                                </div>
                                :
                                ''
                            }
                        </div>
                        <div className="vendors-admin-view-products-table-div">
                            <div className="vendors-admin-view-products-card-div">
                                <Card className="vendors-admin-view-products-card" fluid>
                                    <Card.Content className="vendors-admin-view-products-card-header" header={<h3>Products</h3>} />
                                    <Card.Content className="vendors-admin-view-products-card-description" description={this.getProductsTables()} />
                                </Card>
                            </div>
                        </div>
                    </div>
                :''
                }
                <NotificationContainer/>
            </div>
        )
    }
}

export default VendorsAdminView;