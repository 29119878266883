import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Menu, Accordion, Icon } from 'semantic-ui-react'
import { push as BurgerMenu } from 'react-burger-menu';
import MenuItems from 'config/MenuItems';
import 'assets/css/components/sidebar.css';

class Sidebar extends React.Component
{
    state = {
        activeItem: null, // TODO: get item from route
        activeIndex: null,
        menuOpen: false,
        width: 20
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        if (window.innerWidth <= 1023 && window.innerWidth > 550) {
            this.setState({width: 40});
        }
        else if (window.innerWidth < 550) {
            this.setState({width: 100});
        }
        else {
            this.setState({width: 25});
        }
    }

    render() {

        const menu = this.getMenu(this.props.activeItem);
        const Close = () => <div className="close"/>;

        const props = {
            'noOverlay': true,
            'width': this.state.width + '%',
            'outerContainerId': 'App',
            'pageWrapId': 'content-main',
            'isOpen': this.state.menuOpen,
            'onStateChange': (state) => this.handleStateChange(state),
            'customCrossIcon': false,
            ...(this.state.menuOpen === true && { 'customBurgerIcon': <Close/> })
        };

        return (
            <BurgerMenu {...props}>
                <Accordion as={Menu} vertical fluid>
                    {menu}
                </Accordion>
            </BurgerMenu>
        );
    }

    getMenu = () => {

        const { activeIndex } = this.state;
        const { activeItem } = this.state;
        const menuItems = MenuItems();
        const handleItemClick = this.handleItemClick;
        const handleTitleClick = this.handleTitleClick;

        return Object.keys(menuItems).map(function(key) {

            let menu = [];

            if (menuItems[key].submenu) {

                const submenuItems = menuItems[key].submenu;
                const openSubmenu = activeIndex === menuItems[key].title.index;

                const submenu = Object.keys(submenuItems).map(function(key) {

                    let submenu = [];

                    submenu.push(
                        <Menu.Item
                            className="menu-item"
                            as={Link}
                            to={submenuItems[key].path}
                            name={submenuItems[key].name}
                            active={activeItem === key}
                            onClick={handleItemClick.bind(this, false)}
                            key={'item-' + key}
                        >{submenuItems[key].content}</Menu.Item>
                    );
                    submenu.push(<Route path={submenuItems[key].path} key={'route-' + key} />);

                    return submenu;
                });

                menu.push(
                    <Menu.Item key={'item-' + key} className={openSubmenu ? 'active menu-parent-item' : 'default menu-parent-item'} >
                        <Accordion.Title
                            as={Menu.Header}
                            active={openSubmenu}
                            index={menuItems[key].title.index}
                            key={'title-' + key}
                            onClick={handleTitleClick}
                        >
                            <svg className={'menu-header-icon ' + key} />{menuItems[key].title.content}
                            <Icon name={openSubmenu ? 'chevron down' : 'chevron right' } className='menu-chevron' />
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === menuItems[key].title.index}>
                            {submenu}
                        </Accordion.Content>
                    </Menu.Item>
                );
            } else {
                menu.push(
                    <Menu.Item
                        as={Link}
                        to={menuItems[key].title.path}
                        name={menuItems[key].title.name}
                        active={activeItem === key}
                        onClick={handleItemClick.bind(this, true)}
                        key={'item' + key}
                        className="menu-parent-item"
                    >
                        <Menu.Header><svg className={'menu-header-icon ' + key} />{menuItems[key].title.content}</Menu.Header>
                    </Menu.Item>
                );
                menu.push(<Route path={menuItems[key].title.path} key={'route-' + key} />);
            }

            return menu;
        });
    };

    handleTitleClick = (e, titleProps) => {

        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    };

    handleItemClick = (isTitle, e, {name}) => {

        if (!isTitle) {
            this.setState({ activeItem: name });
        } else {
            this.setState({ activeItem: name, activeIndex: -1 });
        }
        this.closeMenu();
    };

    handleStateChange = (state) => {
        this.setState({menuOpen: state.isOpen});
        const contentElem = document.getElementById('content-main');
        if(state.isOpen){
            contentElem.classList.add('menu-active');
        } else {
            if(contentElem.classList.contains('menu-active')){
                contentElem.classList.remove('menu-active');
            }
        }
    };

    closeMenu = () => {
        this.setState({menuOpen: false});
    };
}

export default Sidebar;
