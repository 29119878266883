import React from 'react';
import './../../assets/css/pages/check-lists/commSheet.css'

import ShiftComments from "../../components/ShiftComments";
import CheckListFilters from "../../components/CheckListFilters";
import { Placeholder } from 'semantic-ui-react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Request from "../../Request";

class CommSheet extends React.Component
{
    state = {
        date: new Date(),
        store: {},
        renderShiftComments: false,
        urlParams: {
            store: null,
            date: null
        },
        comments: {
            open:{},
            mid:{},
            close:{}
        },
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.initializeParams();
        this.setState({renderShiftComments:false});
    }

    isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    };

    initializeParams = () => {
        let stateParams = this.state.urlParams;
        if(this.props.match.params) {
            if(this.props.match.params.store) {
                stateParams.store = this.props.match.params.store;
            }
            if(this.props.match.params.date) {
                let date = new Date(this.props.match.params.date);
                if (this.isValidDate(date)) {
                    stateParams.date = date;
                } else {
                    stateParams.date = new Date();
                }
            }
            else {
                stateParams.date = new Date();
            }
            this.setState({urlParams: stateParams})
        }
        else {
            this.setState({urlParams: {store: null, date: new Date()}})
        }
    };

    onGetStores = (store) => {
        if(store && store.id) {
            let urlParams = this.state.urlParams;
            if(store.id !== parseInt(urlParams.store)) {
                urlParams.store = store.id;
            }
            this.setState({store:store, renderShiftComments:false, urlParams: urlParams}, () => {
                this.getCommSheetData();
            });
        }
    };

    getCommSheetData = () => {
        if(this.state.store && this.state.store.id) {
            new Promise((resolve, reject) => {
                let path = '/comm-sheet/' + this.state.store.id + '/' + this.formatDate(this.state.date);
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let comments = JSON.parse(response);
                    if(comments && comments.success === true && comments.data) {
                        let stateComments = this.state.comments;
                        ['open', 'mid', 'close'].forEach((shift) => {
                            if(comments.data[shift]) {
                                stateComments[shift] = comments.data[shift];
                            }
                            else{
                                stateComments[shift] = {};
                            }
                        });
                        this.setState({comments: stateComments}, () => {
                            window.history.replaceState(
                                {},
                                null,
                                '/check-lists/comm-sheet/'+ this.state.urlParams.store +'/' + this.formatDate(this.state.urlParams.date)
                            );
                            this.setState({renderShiftComments:true})
                        });
                    }
                    else {
                        this.setState({renderShiftComments:true});
                    }
                })
                .catch(error => {
                    let defaultCommentsObject = {
                        open:{},
                        mid:{},
                        close:{}
                    };
                    this.setState({comments: defaultCommentsObject}, ()=>{
                        this.setState({renderShiftComments:true});
                    });
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Communication Sheets', 'Error');
                    }
                });
        }
    };

    handleChangeDate = (event, date) => {
        let urlParams = this.state.urlParams;
        if(date.value !== urlParams.date) {
            urlParams.date = date.value;
        }
        this.setState({date: date.value, renderShiftComments:false, urlParams: urlParams}, () => {
            this.getCommSheetData();
        });
    };

    handleChangeStore = (store) => {
        let urlParams = this.state.urlParams;
        if(store.id !== parseInt(urlParams.store)) {
            urlParams.store = store.id;
        }
        this.setState({store: store, renderShiftComments:false, urlParams: urlParams}, () => {
            this.getCommSheetData();
        });
    };

    handleChangeComments = () => {
        this.setState({renderShiftComments:false}, () => {
            this.getCommSheetData();
        });
    };

    formatDate = (date) => {
        if(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
        return '';
    };

    render() {
        return (
            <div className="comm-sheet">
                <CheckListFilters
                    paramsStoreId={this.state.urlParams.store}
                    date={this.state.urlParams.date}
                    onGetStores={this.onGetStores}
                    onChangeDate={this.handleChangeDate}
                    onChangeStore={this.handleChangeStore}
                    pageTitle="Communication Sheet"
                />
                <div className="comm-sheet-content">
                    {this.state.renderShiftComments ?
                        <div>
                        <ShiftComments
                            handleChangeComments={this.handleChangeComments} className="shiftComment"
                            shiftType="open" comments={this.state.comments} store={this.state.store} date={this.state.date}
                            imageLinks={this.state.imageLinks}
                        />
                        <ShiftComments
                            handleChangeComments={this.handleChangeComments} className="shiftComment"
                            shiftType="mid" comments={this.state.comments} store={this.state.store} date={this.state.date}
                            imageLinks={this.state.imageLinks}
                        />
                        <ShiftComments
                            handleChangeComments={this.handleChangeComments} className="shiftComment"
                            shiftType="close" comments={this.state.comments} store={this.state.store} date={this.state.date}
                            imageLinks={this.state.imageLinks}
                        />
                        </div>
                        :
                        <div>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                            <br/>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                            <br/>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        </div>
                    }
                </div>
                <NotificationContainer/>
            </div>

        );
    }
}

export default CommSheet;
