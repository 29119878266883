import React from 'react';
import {Button, Grid, Icon, Modal, Popup, Select} from "semantic-ui-react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import '../../assets/css/pages/managment/newOrders.css';
import NewOrdersCard from "../../components/NewOrdersCard";
import Request from "../../Request";
import Title from 'components/Title';

class NewOrders extends React.Component
{
    state = {
        changeStore: false,
        renderStoreInfos: false,
        renderVendorInfos: false,
        renderTables: false,
        store: {},
        stores: [],
        options: [],
        vendor: {},
        vendors: [],
        vendorOptions: [],
        products: [],
        openModal: false,
        openVendorsModal: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        loading: false
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.setState({renderStoreInfos: false});
        this.getStoresData();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    // STORES
    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    let activeStore = localStorage.getItem('activeStore');
                    if(activeStore && typeof activeStore !== 'undefined') {
                        activeStore = {
                            franchisees: [],
                            managers: [],
                            store: JSON.parse(activeStore)
                        };
                    } else {
                        activeStore = stores.data[0]
                    }

                    this.setState({stores: stores.data, store: activeStore}, () => {
                        this.transformStoresToOptions();
                        this.setState({renderStoreInfos: true});
                        this.getVendorsData();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({stores: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({renderStoreInfos: true});
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({options: options})
    };

    handleStoresInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                </div>
                                :''
                            }
                        </Grid.Column> :''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 :16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.setState({changeStore:false, store: foundStore, renderVendorInfos: false}, () => {
                this.getVendorsData();
            });
            localStorage.setItem('activeStore', JSON.stringify(foundStore.store));
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br/>
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    :''

                                }
                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };
    // STORES END

    // VENDORS

    getVendorsData = () => {
        let store = this.state.store;
        new Promise((resolve, reject) => {
            let path = '/stores/'+ store.store.id +'/vendors';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let vendors = JSON.parse(response);
                if(vendors && vendors.success === true && vendors.data) {
                    this.setState({vendors: vendors.data, vendor: vendors.data[0]}, () => {
                        this.transformVendorsToOptions();
                        this.setState({renderVendorInfos: true});
                        this.getProducts();
                    });
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            })
            .catch(error => {
                this.setState({vendors: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
                this.setState({renderVendorInfos: true});
            });
    };

    transformVendorsToOptions = () => {
        let vendors = this.state.vendors;
        let options = [];
        vendors.forEach((vendor, index) => {
            let tempOption = {
                id: vendor.id,
                key: vendor.id,
                value: vendor.id,
                text: vendor.name
            };
            options.push(tempOption);
        });
        this.setState({vendorOptions: options})
    };

    handleChangeVendor = (event, data) => {
        const foundStore = this.state.vendors.find(element => element.id === data.value);
        if(foundStore) {
            this.setState({vendor: foundStore}, ()=> {
                this.getProducts();
            });
        }
    };

    handleVendorsInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openVendorsModal: true})
        } else {
            this.setState({ openVendorsModal: false });
        }
    };

    handleCloseVendorsModal = () => {
        this.setState({ openVendorsModal: false });
    };

    getVendorsModalInfos = () => {
        let emailString = this.state.vendor.email;
        let emailArray = [];
        if(emailString !== '' && emailString) {
            if(emailString.indexOf(',') !== -1) {
                emailArray = emailString.split(',');
            }
            else {
                emailArray =[emailString];
            }
        }
        return (
            <Modal className="vendors-info-modal" open={this.state.openVendorsModal} onClose={this.handleCloseVendorsModal} size="mini">
                <Modal.Header>Vendor Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openVendorsModal?
                            <div className="modal-vendors-info">
                                <h5>{this.state.vendor.name || ''}</h5>
                                <p>{this.state.vendor.street1 || ''}</p>
                                <p>{this.state.vendor.street2 || ''}</p>
                                <h5>Account #</h5>
                                <p>{this.state.vendor.accountNo || ''}</p>
                                <h5>Sales Contact</h5>
                                <p>{this.state.vendor.salesContact || ''}</p>
                                {emailArray.length > 0 ?
                                    <div>
                                    <h5>Email</h5>
                                        {emailArray.map((email, index) => {
                                            return (
                                                <a href={'mailto:' + email} target="_top" key={index}>
                                                    {email}
                                                </a>
                                            );
                                        })}
                                    </div> : ''
                                }
                                <h5>Phone</h5>
                                <p>{this.state.vendor.phone || ''}</p>
                                <h5>Phone 2</h5>
                                <p>{this.state.vendor.phone2 || ''}</p>

                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseVendorsModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    getVendorsPopupInfos = () => {
        let emailString = this.state.vendor.email;
        let emailArray = [];
        if(emailString !== '' && emailString) {
            if(emailString.indexOf(',') !== -1) {
                emailArray = emailString.split(',');
            }
            else {
                emailArray =[emailString];
            }
        }
        return (
            <div className="vendor-info-popup-content">
                {this.state.renderVendorInfos?
                    <div className="modal-vendors-info">
                        <h5>{this.state.vendor.name || ''}</h5>
                        <p>{this.state.vendor.street1 || ''}</p>
                        <p>{this.state.vendor.street2 || ''}</p>
                        <h5>Account #</h5>
                        <p>{this.state.vendor.accountNo || ''}</p>
                        <h5>Sales Contact</h5>
                        <p>{this.state.vendor.salesContact || ''}</p>
                        {emailArray.length > 0 ?
                            <div>
                                <h5>Email</h5>
                                {emailArray.map((email, index) => {
                                    return (
                                        <a href={'mailto:' + email} target="_top" key={index}>
                                            {email}
                                        </a>
                                    );
                                })}
                            </div> : ''
                        }
                        <h5>Phone</h5>
                        <p>{this.state.vendor.phone || ''}</p>
                        <h5>Phone 2</h5>
                        <p>{this.state.vendor.phone2 || ''}</p>

                    </div>
                    :''}
            </div>
        );
    };

    handleRedirectToHistory = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/management/order-history';
    };

    // VENDORS END

    // PRODUCTS

    getProducts = () => {
        let vendor = this.state.vendor;
        new Promise((resolve, reject) => {
            let path = '/vendors/'+ vendor.id + '/products?activeP=1';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let products = JSON.parse(response);
                if(products && products.success === true && products.data) {
                    this.setState({products: products.data}, () => {
                        this.setState({renderTables: true});
                    });
                }
                else {
                    NotificationManager.error('Could not get Products', 'Error');
                }
            })
            .catch(error => {
                this.setState({products: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Products', 'Error');
                }
                this.setState({renderTables: true});
            });
    };

    handleChangeProduct = (productId, historyValues, index) => {
        let products = this.state.products;
        let orderProducts = products[index].products;
        let productIndex = orderProducts.findIndex((product)=>{return product.id === productId});

        if(productIndex !== -1) {
            orderProducts[productIndex].on_hand = historyValues.on_hand;
            orderProducts[productIndex].qty = historyValues.qty;
            products[index].products = orderProducts;
            this.setState({products: products});
        }
    };

    getNewOrdersCards = () => {
        return (
            <div>
                {this.state.renderTables && this.state.products && Array.isArray(this.state.products) ? this.state.products.map((product, index)=>{
                    return (
                        <NewOrdersCard
                            key={index}
                            product={product}
                            storeId={this.state.store.store.id}
                            index={index}
                            handleChangeProduct={this.handleChangeProduct}
                        />
                    );
                }) :''
                }
            </div>
        );
    };

    // PRODUCTS END

    printNewOrders = () => {
        window.print();return false;
    };

    getNewOrdersFilters = () => {
        return (
            <div className="new-orders-filters">

                    <Grid stackable>
                        <Grid.Row columns={3}>
                            <Grid.Column width={5}>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <div className="new-orders-vendors-and-history-and-order">
                                    <div className="new-orders-vendors-and-history-row">
                                        <div className="new-orders-vendors-select">
                                            <Select
                                                text={this.state.vendor.name}
                                                value={this.state.vendor.id}
                                                options={this.state.vendorOptions}
                                                onChange={this.handleChangeVendor}/>
                                            <div className="ui icon buttons new-orders-filters-buttons">
                                                <button className="ui button" onClick={this.handleVendorsInfoButton}>
                                                    {   !this.state.openVendorsModal ?
                                                        <Popup
                                                            on='click'
                                                            trigger={<i className="info circle icon"></i>}
                                                            size='small'
                                                            pinned
                                                            wide='very'
                                                            position={this.state.mobileSize ? 'bottom center' : 'bottom left'}
                                                        >
                                                            {
                                                                this.state.renderVendorInfos ?
                                                                    this.getVendorsPopupInfos()
                                                                    : ''
                                                            }
                                                        </Popup> : ''
                                                    }
                                                </button>
                                                <button className="ui button" onClick={this.handleRedirectToHistory}><i className="book icon"></i>History</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <div className="new-orders-print-button">
                                    <Button icon floated={this.state.tabletSize ? '' : 'right'} onClick={this.printNewOrders}>
                                        <Icon name='print' />
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            </div>
        );
    };

    getStoresHtml = () => {
        return (
            <div className="new-orders-stores">
                <div className="new-orders-content">
                    <div className="store-number">
                        <Select
                            text={this.state.store.store.title}
                            value={this.state.store.store.id}
                            options={this.state.options}
                            onChange={this.handleChangeStore}/>
                    </div>
                </div>
            </div>
        )
    }

    transformToSubmitData = () => {
        let products = this.state.products;
        let submitData = {
            vendor_id: this.state.vendor.id,
            products: []
        };
        products.forEach((product, index) => {
            product.products.forEach((childProduct, index) => {
                if (childProduct.qty) {
                    let submitDataProduct = {
                        "category_id": product.category.id,
                        "product_id": childProduct.id,
                        "on_hand": childProduct.on_hand || null,
                        "qty": childProduct.qty,
                        "item_no": childProduct.item_no,
                        "description": childProduct.description,
                        "cost": childProduct.cost,
                        "wholesale": childProduct.wholesale,
                        "retail": childProduct.retail,
                        "um": childProduct.um
                    };
                    submitData.products.push(submitDataProduct);
                }
            });
        });
        return submitData;
    };

    submitOrders = () => {
        this.setState({loading: true});
        let products = this.transformToSubmitData();
        new Promise((resolve, reject) => {
            let path = '/stores/' + this.state.store.store.id + '/order';
            this.request.post(path, products, resolve, reject);
        })
            .then(response => {
                this.setState({loading: false});
                NotificationManager.success('Successfully submited orders', 'Success');
            })
            .catch(error => {
                this.setState({loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not submit orders', 'Error');
                }
            });
    };

    render() {
        return (
            <div className="new-orders-root">
                {this.getStoresModalInfos()}
                {this.getVendorsModalInfos()}
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                            <Title
                                paramsPageTitle="New Orders"
                            />
                        </Grid.Column>
                        <Grid.Column width={8} className="col-right">
                            {this.state.renderStoreInfos ? this.getStoresHtml() : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.state.renderStoreInfos ? this.getNewOrdersFilters() : ''}
                {this.getNewOrdersCards()}
                <div className="new-orders-submit-button-div">
                    {this.state.loading ?
                        <Button loading color="orange" size="tiny">
                            Loading
                        </Button>
                        :
                        <Button
                            onClick={this.submitOrders}
                            content='Submit'
                            color='orange'
                        />
                    }
                </div>
                <NotificationContainer/>
            </div>
        );
    }
}

export default NewOrders;
