import React from 'react';
import {Menu, Grid} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import './../assets/css/components/dashboard-menu.css';

class DashboardMenu extends React.Component
{

    render() {
        const menuItems = [
            {
                'name': 'check-lists',
                'content': 'Check Lists',
                'path': '/check-lists'
            }, {
                'name': 'recipe',
                'content': 'Food Prep Guides',
                'path': '/recipes'
            }, {
                'name': 'management',
                'content': 'Management',
                'path': '/management'
            }, {
                'name': 'manager',
                'content': 'Regional Manager',
                'path': '/management'
            }, {
                'name': 'settings',
                'content': 'Admin',
                'path': '/admin'
            }, {
                'name': 'user',
                'content': 'Users',
                'path': '/admin/users'
            }
        ];

        return (
            <div className="grid-wrapper">
                <Grid columns={3} divided>
                    <Grid.Row>              
                        { menuItems.map((item, key) => {
                                return (
                                    <Grid.Column>
                                        <Menu.Item
                                            as={Link}
                                            to={item.path}
                                            name={item.name}
                                            key={'item' + key}
                                            className="menu-item"
                                        >
                                            <svg className={'menu-home-icon ' + item.name} />
                                            <span className="menu-label">{item.content}</span>
                                        </Menu.Item>
                                    </Grid.Column>
                                )
                            })
                        }
                    </Grid.Row>
                </Grid>
            </div>  
        );
    }
}

export default DashboardMenu;
