import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import {Button, Grid, Modal, Popup, Select, Checkbox, Form, Label, Icon} from "semantic-ui-react";
import '../../assets/css/pages/managment/keyRelease.css';
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import Request from "../../Request";
import Title from 'components/Title';

class KeyRelease extends React.Component
{
    state = {
        changeStore: false,
        renderStoreInfos: false,
        renderKeyReleaseTable: false,
        store: {},
        stores: [],
        openModal: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        customSize: window.innerWidth <= 1400, // for the popup position
        keyReleases: [],
        checkboxes: {
            building: 0,
            office: 0,
            gate: 0
        },
        keyTypes: {
            firstName: '',
            lastName: '',
            signature: '',
            dateGiven: null,
            dateReturned: null
        }
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.setState({renderStoreInfos: false});
        this.getStoresData();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768, customSize: window.innerWidth <= 1400}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    formatDate = (date) => {
        if(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
        return '';
    };

    // STORES
    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    this.setState({stores: stores.data, store: stores.data[0]}, () => {
                        this.transformStoresToOptions();
                        this.setState({renderStoreInfos: true});
                        this.getKeyReleases();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({stores: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({renderStoreInfos: true});
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({options: options})
    };

    handleStoresInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                </div>
                                :''
                            }
                        </Grid.Column> :''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 :16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.setState({changeStore:false, store: foundStore}, () => {
                this.getKeyReleases();
            });
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br/>
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username} onClick={()=>this.handleRedirectToProfile(manager.username)}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    :''

                                }
                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    getStoresHtml = () => {
        return (
            <div className="key-release-stores">
                <div>
                    <div className="store-number">
                        <Select
                            text={this.state.store.store.title}
                            value={this.state.store.store.id}
                            options={this.state.options}
                            onChange={this.handleChangeStore}/>
                    </div>
                </div>
            </div>
        );
    };

    // STORES END

    getKeyReleases = () => {
        if(this.state.store.store && this.state.store.store.id) {
            new Promise((resolve, reject) => {
                let path = '/key-release/' + this.state.store.store.id;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let releases = JSON.parse(response);
                    if(releases && releases.success === true && releases.data) {
                        let stateKeyReleases = releases.data;
                        this.setState({keyReleases: stateKeyReleases}, () => {
                            this.setState({renderKeyReleaseTable: true});
                        });
                    }
                    else {
                        this.setState({keyReleases: []}, () => {
                            this.setState({renderKeyReleaseTable: true})
                        });
                    }
                })
                .catch(error => {
                    this.setState({keyReleases: []}, () => {
                        this.setState({renderKeyReleaseTable: true})
                    });
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Key Releases', 'Error');
                    }
                });
        }
    };

    addKeyRelease = () => {
        new Promise((resolve, reject) => {
            let path = '/key-release/' + this.state.store.store.id;
            let params = {
                building: this.state.checkboxes.building,
                office: this.state.checkboxes.office,
                gate: this.state.checkboxes.gate,
                first_name: this.state.keyTypes.firstName,
                last_name: this.state.keyTypes.lastName,
                signature: this.state.keyTypes.signature,
                time_given: this.formatDate(this.state.keyTypes.dateGiven),
                time_returned: this.formatDate(this.state.keyTypes.dateReturned)
            };
            this.request.post(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully saved new Key Release', 'Success');
                this.getKeyReleases();
                this.clearKeyTypes();
            })
            .catch(error => {
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not create new Key Release', 'Error');
                }
            });
    };

    updateKeyRelease = (index) => {
        let keyRelease = this.state.keyReleases[index];
        if(keyRelease.id) {
            new Promise((resolve, reject) => {
                let path = '/key-release/' + keyRelease.id;
                let params = {
                    building: keyRelease.buildingKey,
                    office: keyRelease.officeKey,
                    gate: keyRelease.gateKey,
                    first_name: keyRelease.firstName,
                    last_name: keyRelease.lastName,
                    signature: keyRelease.signature,
                    time_given: this.formatDate(keyRelease.timeGiven && keyRelease.timeGiven.date ? keyRelease.timeGiven.date : null),
                    time_returned: this.formatDate(keyRelease.timeReturned && keyRelease.timeReturned.date ? keyRelease.timeReturned.date : null)
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Key Release', 'Success');
                    this.getKeyReleases();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Key Release', 'Error');
                    }
                });
        }
    };

    deleteKeyRelease = (index) => {
        let keyRelease = this.state.keyReleases[index];
        if(keyRelease.id) {
            new Promise((resolve, reject) => {
                let path = '/key-release/' + keyRelease.id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        this.getKeyReleases();
                        NotificationManager.success('Successfully removed Key Release', 'Success');
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove Key Release', 'Error');
                    }
                });
        }
    };

    keyTypesCheckboxChanged = (checkedBox) => {
        let checkboxes = this.state.checkboxes;
        checkboxes[checkedBox] = checkboxes[checkedBox] === 0 ? 1 : 0;
        this.setState({checkboxes: checkboxes});
    };

    tableCheckboxChanged = (index, checkedBox) => {
        let keyReleases = this.state.keyReleases;
        keyReleases[index][checkedBox] = keyReleases[index][checkedBox] === 0 ? 1 : 0;
        this.setState({keyReleases: keyReleases});
    };

    onKeyTypesInputChange = (event, name) => {
        let keyTypes = this.state.keyTypes;
        keyTypes[name] = event.target.value;
        this.setState({keyTypes: keyTypes});
    };

    onTableInputChange = (event, name, index) => {
        let keyReleases = this.state.keyReleases;
        keyReleases[index][name] = event.target.value;
        this.setState({keyReleases: keyReleases});
    };

    onKeyTypesDateChanged = (event, date, name) => {
        let keyTypes = this.state.keyTypes;
        keyTypes[name] = date.value;
        this.setState({keyTypes: keyTypes});
    };

    onTableDateChanged = (event, date, name, index) => {
        let keyReleases = this.state.keyReleases;
        keyReleases[index][name] = {};
        keyReleases[index][name].date = date.value;
        keyReleases[index][name].timezone = 'UTC';
        keyReleases[index][name].timezone_type = 3;
        this.setState({keyReleases: keyReleases});
    };

    clearKeyTypes = () => {
        let keyTypes = this.state.keyTypes;
        let checkboxes = this.state.checkboxes;
        checkboxes.building = 0;
        checkboxes.office = 0;
        checkboxes.gate = 0;

        keyTypes.dateReturned = null;
        keyTypes.dateGiven = null;
        keyTypes.signature = '';
        keyTypes.lastName = '';
        keyTypes.firstName = '';

        this.setState({keyTypes: keyTypes, checkboxes:checkboxes})
    };

    getKeyTypesHtml = () => {
        return (
            <div className="key-release-types-div">
                <h3>Key Types Given</h3>
                <div>
                    <Checkbox className="key-release-types-checkbox" label={{ children: 'Building' }} checked={this.state.checkboxes.building} onChange={()=>this.keyTypesCheckboxChanged('building')}/>
                    <Checkbox className="key-release-types-checkbox" label={{ children: 'Office' }} checked={this.state.checkboxes.office} onChange={()=>this.keyTypesCheckboxChanged('office')}/>
                    <Checkbox className="key-release-types-checkbox" label={{ children: 'Gate' }} checked={this.state.checkboxes.gate} onChange={()=>this.keyTypesCheckboxChanged('gate')}/>
                </div>
                <div>
                    <Grid className="user-info-inputs-grid" columns={7} stackable>
                        <Grid.Row >
                            {this.state.customSize ?
                                ''
                                : <Grid.Column width={3}></Grid.Column>
                            }
                            <Grid.Column width={this.state.customSize ? 3 : 2}>
                                <Form>
                                    <Form.Field>
                                        <Label className="user-info-input-label" pointing='below'>First Name</Label>
                                        <input
                                            className="user-info-input"
                                            type='text'
                                            value={this.state.keyTypes.firstName}
                                            placeholder="Enter first name"
                                            onChange={(e)=>this.onKeyTypesInputChange(e, 'firstName')}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={this.state.customSize ? 3 : 2}>
                                <Form>
                                    <Form.Field>
                                        <Label className="user-info-input-label" pointing='below'>Last Name</Label>
                                        <input
                                            className="user-info-input"
                                            type='text'
                                            value={this.state.keyTypes.lastName}
                                            placeholder="Enter last name"
                                            onChange={(e)=>this.onKeyTypesInputChange(e, 'lastName')}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={this.state.customSize ? 4 : 2}>
                                <Form>
                                    <Form.Field>
                                        <Label className="user-info-input-label" pointing='below'>Signature</Label>
                                        <input
                                            className="user-info-input"
                                            type='text'
                                            value={this.state.keyTypes.signature}
                                            placeholder="Enter signature"
                                            onChange={(e)=>this.onKeyTypesInputChange(e, 'signature')}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={this.state.customSize ? 3 : 2}>
                                <Form>
                                    <Form.Field className="user-info-input-form-field">
                                        <Label className="user-info-input-label" pointing='below'>Date Given</Label>
                                        <SemanticDatepicker
                                            datePickerOnly={true}
                                            placeholder="YYYY-MM-DD"
                                            clearable={false}
                                            format="MMMM D, YYYY"
                                            value={this.state.keyTypes.dateGiven}
                                            onChange={(e,d)=> this.onKeyTypesDateChanged(e,d,'dateGiven')}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={this.state.customSize ? 3 : 2}>
                                <Form>
                                    <Form.Field className="user-info-input-form-field">
                                        <Label className="user-info-input-label" pointing='below'>Date Returned</Label>
                                        <SemanticDatepicker
                                            datePickerOnly={true}
                                            placeholder="YYYY-MM-DD"
                                            clearable={false} format="MMMM D, YYYY"
                                            value={this.state.keyTypes.dateReturned}
                                            onChange={(e,d)=> this.onKeyTypesDateChanged(e,d,'dateReturned')}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            {this.state.customSize ?
                                ''
                                : <Grid.Column width={3}></Grid.Column>
                            }
                        </Grid.Row>
                    </Grid>
                </div>
                <div className="key-release-types-button-div">
                    <Button icon labelPosition='left' onClick={this.addKeyRelease}>
                        <Icon name='plus' />
                        Add
                    </Button>
                </div>
            </div>
        );
    };

    getKeyReleaseTable = () => {
        let keyReleases = this.state.keyReleases;
        return (
            <table className="key-release-table" align="center">
                <thead>
                    <tr>
                        <th className="key-release-table-header-building">Building</th>
                        <th className="key-release-table-header-office">Office</th>
                        <th className="key-release-table-header-gate">Gate</th>
                        <th className="key-release-table-header">First Name</th>
                        <th className="key-release-table-header">Last Name</th>
                        <th className="key-release-table-header">Signature</th>
                        <th className="key-release-table-header">Date Given</th>
                        <th className="key-release-table-header">Date Returned</th>
                        <th className="key-release-table-header">Actions</th>
                    </tr>
                </thead>
                <tbody>
                { keyReleases && Array.isArray(keyReleases) ?
                    keyReleases.map((release, index) => {
                        return (
                            <tr>
                                <td className="key-release-table-header-building">
                                    <Checkbox className="key-release-types-checkbox" checked={release.buildingKey} onChange={()=>this.tableCheckboxChanged(index, 'buildingKey')}/>
                                </td>
                                <td className="key-release-table-header-office">
                                    <Checkbox className="key-release-types-checkbox" checked={release.officeKey} onChange={()=>this.tableCheckboxChanged(index, 'officeKey')}/>
                                </td>
                                <td className="key-release-table-header-gate">
                                    <Checkbox className="key-release-types-checkbox" checked={release.gateKey} onChange={()=>this.tableCheckboxChanged(index, 'gateKey')}/>
                                </td>
                                <td className="key-release-table-input-cell">
                                    <input type="text" className="cell"
                                           value={release.firstName}
                                           onChange={(e)=>this.onTableInputChange(e, 'firstName', index)}
                                    />
                                </td>
                                <td>
                                    <input type="text" className="cell"
                                           value={release.lastName}
                                           onChange={(e)=>this.onTableInputChange(e, 'lastName', index)}
                                    />
                                </td>
                                <td>
                                    <input type="text" className="cell"
                                           value={release.signature}
                                           onChange={(e)=>this.onTableInputChange(e, 'signature', index)}
                                    />
                                </td>
                                {release.timeGiven && release.timeGiven.date && typeof release.timeGiven.date === 'string' && moment(release.timeGiven.date).isValid()?
                                    <td>{moment(release.timeGiven.date).format('ddd, MMM Do')}</td>
                                    :
                                    <td>
                                        <SemanticDatepicker
                                            datePickerOnly={true}
                                            placeholder="YYYY-MM-DD"
                                            clearable={false}
                                            format="MMMM D, YYYY"
                                            onChange={(e,d)=> this.onTableDateChanged(e,d,'timeGiven', index)}
                                        />
                                    </td>
                                }
                                {release.timeReturned && release.timeReturned.date && typeof release.timeReturned.date === 'string' && moment(release.timeReturned.date).isValid() ?
                                    <td>{moment(release.timeReturned.date).format('ddd, MMM Do')}</td>
                                    :
                                    <td>
                                        <SemanticDatepicker
                                            datePickerOnly={true}
                                            placeholder="YYYY-MM-DD"
                                            clearable={false}
                                            format="MMMM D, YYYY"
                                            onChange={(e,d)=> this.onTableDateChanged(e,d,'timeReturned', index)}
                                        />
                                    </td>
                                }
                                <td>
                                    <Button content='Save' color="orange" onClick={()=>{this.updateKeyRelease(index)}}/>
                                    <Button content='Remove' color="red" onClick={()=>{this.deleteKeyRelease(index)}}/>
                                </td>
                            </tr>
                        );
                    })
                    : ''
                }
                </tbody>
            </table>
        );
    };

    render() {
        return (
            <div className="key-release-root">
                {this.getStoresModalInfos()}
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                            <Title
                                paramsPageTitle="Key Release"
                            />
                        </Grid.Column>
                        <Grid.Column width={8} className="col-right">
                            {this.state.renderStoreInfos ? this.getStoresHtml() : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className="key-release-content">
                    <hr/>
                    {this.getKeyTypesHtml()}
                    <div className="key-release-table-div">
                        {this.state.renderKeyReleaseTable? this.getKeyReleaseTable() : ''}
                    </div>
                </div>
                <NotificationContainer/>  
            </div>
        );
    }
}

export default KeyRelease;
