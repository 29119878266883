import React from 'react';
import {Card, List, Input, Grid} from 'semantic-ui-react';
import './../assets/css/components/dailyCheckListCard.css';

const typeToHeader = {
    open: 'Opening Shift',
    mid: 'Mid Shift',
    close: 'Closing Shift',
    weekly: 'Weekly Tasks'
};

class DailyCheckListCard extends React.Component
{
    state = {
        tasks: [],
        allTasks: [],
        store: null,
        date: null,
        header: '',
        type: null,
        isWeekly: false,
        rerenderChildComponent: false
    };

    componentDidMount() {
        if(this.props.tasks && this.props.shiftType) {
            let type = this.props.shiftType;
            let tasks = this.props.tasks[type];
            this.setState({tasks: tasks, allTasks:this.props.tasks});
        }

        this.initializeProps();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.store !== this.props.store) || (prevProps.date !== this.props.date)) {
            this.initializeProps();
        }
    }

    initializeProps = () => {
        if(this.props.store) {
            this.setState({store: this.props.store});
        }

        if(this.props.date) {
            this.setState({date: this.props.date});
        }

        if(this.props.shiftType) {
            this.setState({
                header: typeToHeader[this.props.shiftType],
                type: this.props.shiftType,
                isWeekly: this.props.shiftType === 'weekly'
            })
        }
    };

    handleChangeName = (event, index) => {
        let tasks = this.state.allTasks;
        let task = tasks[this.state.type];
        task[index].name = event.target.value;
        tasks[this.state.type] = task;
        this.setState({tasks: task, allTasks:tasks}, () => {
            this.props.handleChangeDailyList(this.state.allTasks)
        })
    };

    getCardContent = () => {
        return (
            <List divided verticalAlign='middle'>
                { !this.state.tasks || !Array.isArray(this.state.tasks) ? '' :this.state.tasks.map((task, index) => {
                    let isWeekly = task.type === 'weekly';
                    return (
                        <List.Item key={task.checklist_id}>
                                <Grid className="tasks" columns={isWeekly? 3 : 2} stackable>
                                    <Grid.Row className="task-row">
                                        <Grid.Column
                                            className="daily-checklist-task-text-container"
                                            mobile={isWeekly? 5 : 8}
                                            tablet={isWeekly? 10 : 12}
                                            computer={isWeekly? 10 : 12}
                                        >
                                            <div className="task-column">
                                                <div className="daily-checklist-task-text">{index+1 + '. ' + task.task}</div>
                                            </div>
                                        </Grid.Column>
                                        {isWeekly ?
                                            <Grid.Column
                                                className="daily-checklist-task-text-container"
                                                mobile={3}
                                                tablet={2}
                                                computer={2}
                                            >
                                                <div className="task-note-column">
                                                    <b className="daily-checklist-task-note-text">{task.note}</b>
                                                </div>
                                            </Grid.Column>
                                                : ''
                                        }
                                        <Grid.Column className="daily-checklist-name-container" mobile={8} tablet={4} computer={4}>
                                            <div className="employee-name-column" >
                                                <Input value={task.name || ''} onChange={(e) => this.handleChangeName(e, index)} type="text" placeholder="Enter employee name" />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                        </List.Item>
                    );
                })}
            </List>
        );
    };

    render() {
        return (
            <Card className="daily-list-card" fluid>
                <Card.Content className={"cardHeader "+ (this.state.isWeekly ? "weekle-header" : "default-header") } header={this.state.header} />
                <Card.Content description={this.getCardContent()} />
            </Card>
        );
    }

}

export default DailyCheckListCard;