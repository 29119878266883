import React from 'react';
import {Button, Card, Grid, Header, Icon, Image, Modal} from "semantic-ui-react";
import './../../assets/css/pages/admin/users.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';

class Users extends React.Component
{
    state = {
        showTrashed: false,
        customSize: window.innerWidth <= 1150,
        users: [],
        renderUsers: false,
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getUsers();

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({customSize: window.innerWidth <= 1150});
    }

    getUsers = () => {
        new Promise((resolve, reject) => {
            let path = '/users/?trashed=' + (this.state.showTrashed ? '1' : '0');
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let users = JSON.parse(response);
                if(users && users.success === true && users.data) {
                    //console.log(users.data)
                    this.setState({users: users.data}, () => {
                        this.setState({renderUsers: true});
                    });
                }
                else {
                    this.setState({users: []}, () => {
                        this.setState({renderUsers: true})
                    });
                }
            })
            .catch(error => {
                this.setState({users: []}, () => {
                    this.setState({renderUsers: true})
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Users', 'Error');
                }
            });
    };

    deleteUser = (id) => {
        this.setState({modalOpen: false});
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/users/'+ id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully removed User', 'Success');
                        this.getUsers();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove User', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove User', 'Error');
        }
    };

    deactivateUser = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/users/'+ id + '/deactivate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully deactivated User', 'Success');
                        this.getUsers();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate User', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not deactivate User', 'Error');
        }
    };

    restoreUser = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/users/'+ id + '/activate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully activated User', 'Success');
                        this.getUsers();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate User', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not activate User', 'Error');
        }
    };

    showTrashedToogle = () => {
        let showTrashed = this.state.showTrashed;
        showTrashed = !showTrashed;
        this.setState({showTrashed: showTrashed}, () => {
            this.getUsers();
        });
    };

    redirectToViewPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users/' + id;
    };

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users/edit/' + id;
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if(removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete User' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this User?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={()=>this.deleteUser(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    removeUserConfirmation = (id) => {
        this.setState({removeId: id, modalOpen:true});
    };

    getOneCardDescription = (user, index) => {
        return (
            <Grid className="users-admin-card-grid">
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <Image src={user.gravatar || ''} avatar />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Name: </b><a href={'/admin/users/' + user.id}><b>{user.name || ''}</b></a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Username: </b>{user.username || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Email: </b>{user.email || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Stores: </b>{user.stores || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Manages: </b>{user.manages || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Roles: </b>{user.roles || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="users-admin-card-grid-row">
                    <Grid.Column width={16}>
                        { !this.state.showTrashed ?
                            <div className="users-admin-card-grid-row-buttons-div">
                                <Button icon labelPosition='left' onClick={(e)=>this.redirectToViewPage(user.id)}>
                                    <Icon name='eye' />
                                    View User
                                </Button>
                                <Button icon labelPosition='left' onClick={()=>this.deactivateUser(user.id)}>
                                    <Icon name='trash' />
                                    Deactivate User
                                </Button>
                            </div>
                            :
                            <div className="users-admin-card-grid-row-buttons-div">
                                <Button icon labelPosition='left' onClick={()=>this.restoreUser(user.id)}>
                                    <Icon name='refresh' />
                                    Restore User
                                </Button>
                                <Button icon labelPosition='left' onClick={()=>this.removeUserConfirmation(user.id)}>
                                    <Icon name='times' />
                                    Delete User
                                </Button>
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    getUsersCard = () => {
        let cards = [];
        let users = this.state.users;
        users.forEach((user, index) => {
            cards.push(
                <Card className="users-admin-card" fluid key={index}>
                    <Card.Content className="users-admin-card-header" header={user.name} />
                    <Card.Content description={this.getOneCardDescription(user, index)} />
                </Card>
            );
        });
        cards.push(
            <div key="users-admin-plus-button">
                <Button icon labelPosition='left' onClick={(e)=>this.redirectToViewPage('new')}>
                    <Icon name='plus' />
                    User
                </Button>
            </div>
        );
        return cards;
    };

    getUsersContent = () => {
        return (
            <Grid className="users-admin-grid">
                <Grid.Row className="users-admin-grid-header-row">
                    <Grid.Column width={1}>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        Name
                    </Grid.Column>
                    <Grid.Column width={2}>
                        Username
                    </Grid.Column>
                    <Grid.Column width={3}>
                        Email
                    </Grid.Column>
                    <Grid.Column width={2}>
                        Stores
                    </Grid.Column>
                    <Grid.Column width={2}>
                        Manages
                    </Grid.Column>
                    <Grid.Column width={2}>
                        Roles
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
                { this.state.users.map((user, index)=>{
                    return(
                        <Grid.Row className="users-admin-grid-row" key={index}>
                            <Grid.Column width={1}>
                                <Image src={user.gravatar || ''} avatar />
                            </Grid.Column>
                            <Grid.Column width={2} className="users-admin-grid-column">
                                <a href={'/admin/users/' + user.id}><b>{user.name || ''}</b></a>
                            </Grid.Column>
                            <Grid.Column width={2} className="users-admin-grid-column">
                                {user.username || ''}
                            </Grid.Column>
                            <Grid.Column width={3} className="users-admin-grid-column">
                                {user.email || ''}
                            </Grid.Column>
                            <Grid.Column width={2} className="users-admin-grid-column">
                                {user.stores || ''}
                            </Grid.Column>
                            <Grid.Column width={2} className="users-admin-grid-column">
                                {user.manages || ''}
                            </Grid.Column>
                            <Grid.Column width={2} className="users-admin-grid-column">
                                {user.roles || ''}
                            </Grid.Column>
                            <Grid.Column width={2} className="users-admin-grid-actions-column">
                                <Button.Group icon>
                                    { !this.state.showTrashed ?
                                        <div>
                                            <Button size='tiny' onClick={(e)=>this.redirectToViewPage(user.id)} title="View User">
                                                <Icon name='eye' />
                                            </Button>
                                            <Button size='tiny' title="Deactivate User" onClick={()=>this.deactivateUser(user.id)}>
                                                <Icon name='trash' />
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            <Button size='tiny' title="Restore User" onClick={()=>this.restoreUser(user.id)}>
                                                <Icon name='refresh' />
                                            </Button>
                                            <Button size='tiny' title="Delete User" onClick={()=>this.removeUserConfirmation(user.id)}>
                                                <Icon name='times' />
                                            </Button>
                                        </div>
                                    }

                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    )
                })
                }
                <Grid.Row className="users-admin-grid-footer-row">
                    <Grid.Column width={2}>
                        <Button icon labelPosition='left' onClick={(e)=>this.redirectToEditPage('new')}>
                            <Icon name='plus' />
                            User
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    render() {
        return (
            <div className="users-admin-root">
                <Title
                    paramsPageTitle="Users"
                />
                <div className="users-admin-trash-button-div">
                    { this.state.showTrashed ?
                        <Button color="orange" onClick={this.showTrashedToogle}><Icon name="angle left"/>Show Active</Button>
                        :
                        <Button color="orange" onClick={this.showTrashedToogle}><Icon name="trash"/>Show Trashed</Button>
                    }
                </div>
                { this.state.renderUsers ?
                    this.state.customSize ? this.getUsersCard() : this.getUsersContent()
                    :
                    ''
                }
                {this.getConfirmationModal()}
                <NotificationContainer/>
            </div>
        );
    }
}

export default Users;
