import React from 'react';
import './../assets/css/components/title.css';

class Title extends React.Component
{

    state = {
        paramsPageTitle: null,
    };

    render() {
        if(this.props.paramsPageTitle)
            return (
                <div className="title">
                    <h1>{ this.props.paramsPageTitle }</h1>
                </div>
            )
        return null;
    }
}

export default Title;
