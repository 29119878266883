import React from 'react';
import {Button, Card, Grid, Header, Icon, Modal, Select} from "semantic-ui-react";
import './../../assets/css/pages/admin/categories.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';

class Categories extends React.Component {
    state = {
        vendor: {},
        vendors: [],
        vendorOptions: [],
        categories: [],
        renderCategories: false,
        showDeactivated: 1,
        customSize: window.innerWidth <= 1000,
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getVendors();

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({customSize: window.innerWidth <= 1000});
    }

    getVendors = () => {
        new Promise((resolve, reject) => {
            let path = '/vendors';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let vendors = JSON.parse(response);
                if(vendors && vendors.success === true && vendors.data) {
                    this.setState({vendors: vendors.data, vendor: vendors.data[0]}, () => {
                        this.transformVendorsToOptions();
                        this.getCategories();
                    });
                }
                else {
                    this.setState({vendors: []});
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            })
            .catch(error => {
                this.setState({vendors: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            });
    };

    getCategories = () => {
        if(this.state.vendor.id) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + this.state.vendor.id + '/categories?active=' + this.state.showDeactivated;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let categories = JSON.parse(response);
                    if(categories && categories.success === true && categories.data) {
                        this.setState({categories: categories.data}, () => {
                            this.setState({renderCategories: true});
                        });
                    }
                    else {
                        this.setState({categories: [],renderCategories: true});
                    }
                })
                .catch(error => {
                    this.setState({categories: [],renderCategories: true});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Categories', 'Error');
                    }
                });
        }
    };

    activateCategorie = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + id + '/activate';
                let params = null;
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully activated Category', 'Success');
                    this.getCategories();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate Category', 'Error');
                    }
                });
        }
    };

    deactivateCategory = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + id + '/deactivate';
                let params = null;
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deactivated Category', 'Success');
                    this.getCategories();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Category', 'Error');
                    }
                });
        }
    };

    deleteCategory = (id) => {
        this.setState({modalOpen: false});
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deleted Category', 'Success');
                    this.getCategories();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not delete Category', 'Error');
                    }
                });
        }
    };

    transformVendorsToOptions = () => {
        let vendors = this.state.vendors;
        let options = [];
        vendors.forEach((vendor, index) => {
            let tempOption = {
                id: vendor.id,
                key: vendor.id,
                value: vendor.id,
                text: vendor.name
            };
            options.push(tempOption);
        });
        this.setState({vendorOptions: options})
    };

    handleChangeVendor = (event, data) => {
        const foundVendor = this.state.vendors.find(element => element.id === parseInt(data.value));
        if(foundVendor) {
            this.setState({vendor: foundVendor}, ()=> {
                this.getCategories();
            });
        }
    };

    getCategoriesContent = () => {
        return (
            <Grid className="categories-admin-grid">
                <Grid.Row className="categories-admin-grid-header-row">
                    <Grid.Column width={8}>
                        Name
                    </Grid.Column>
                    <Grid.Column width={8} className="categories-admin-grid-header-row-actions">
                        Actions
                    </Grid.Column>
                </Grid.Row>
                { this.state.categories.map((categorie, index)=> {
                    return (
                        <Grid.Row className="categories-admin-grid-row" key={index}>
                            <Grid.Column width={8} className="categories-admin-grid-column">
                                <a href={'/admin/categories/' + categorie.id}><b>{categorie.name || ''}</b></a>
                            </Grid.Column>
                            <Grid.Column width={8} className="categories-admin-grid-actions-column">
                                <Button.Group icon>
                                    { this.state.showDeactivated ?
                                        <div>
                                            <Button size='tiny' title="Edit Category" onClick={()=>this.redirectToEdit(categorie.id)}>
                                                <Icon name='pencil' />
                                            </Button>
                                            <Button size='tiny' title="Deactivate Category" onClick={()=>this.deactivateCategory(categorie.id)}>
                                                <Icon name='trash' />
                                            </Button>
                                            <Button size='tiny' title="Delete Category" onClick={()=>this.removeCategoryConfirmation(categorie.id)}>
                                                <Icon name='times' />
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            <Button size='tiny' title="Edit Category" onClick={()=>this.redirectToEdit(categorie.id)}>
                                                <Icon name='pencil' />
                                            </Button>
                                            <Button size='tiny' title="Restore Category" onClick={()=>this.activateCategorie(categorie.id)}>
                                                <Icon name='refresh' />
                                            </Button>
                                            <Button size='tiny' title="Delete Category" onClick={()=>this.removeCategoryConfirmation(categorie.id)}>
                                                <Icon name='times' />
                                            </Button>
                                        </div>
                                    }
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    )
                })}
                <Grid.Row className="categories-admin-grid-footer-row">
                    <Grid.Column width={2}>
                        <Button icon labelPosition='left' onClick={()=>this.redirectToEdit('new')}>
                            <Icon name='plus' />
                            Category
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    showDeactivatedToogle = () => {
        let showDeactivated = this.state.showDeactivated;
        showDeactivated = showDeactivated === 0 ? 1 : 0;
        this.setState({showDeactivated: showDeactivated}, () => {
            this.getCategories();
        });
    };

    redirectToEdit = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/categories/' + id;
    };

    getOneCardDescription = (categorie, index) => {
        return (
            <Grid className="categories-admin-card-grid">
                <Grid.Row className="categories-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Name: </b><a href={'/admin/categories/' + categorie.id}><b>{categorie.name || ''}</b></a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="categories-admin-card-grid-row">
                    <Grid.Column width={16}>
                        { this.state.showDeactivated ?
                            <div className="categories-admin-card-grid-row-buttons-div">
                                <Button color="orange" size='tiny' title="Edit Category" onClick={()=>this.redirectToEdit(categorie.id)}>
                                    <Icon name='pencil' />
                                    Edit Category
                                </Button>
                                <Button color="orange" size='tiny' title="Deactivate Category" onClick={()=>this.deactivateCategory(categorie.id)}>
                                    <Icon name='trash' />
                                    Deactivate Category
                                </Button>
                                <Button color="orange" size='tiny' title="Delete Category" onClick={()=>this.removeCategoryConfirmation(categorie.id)}>
                                    <Icon name='times' />
                                    Delete Category
                                </Button>
                            </div>
                            :
                            <div className="categories-admin-card-grid-row-buttons-div">
                                <Button color="orange" size='tiny' title="Edit Category" onClick={()=>this.redirectToEdit(categorie.id)}>
                                    <Icon name='pencil' />
                                    Edit Category
                                </Button>
                                <Button color="orange" size='tiny' title="Restore Category" onClick={()=>this.activateCategorie(categorie.id)}>
                                    <Icon name='refresh' />
                                    Restore Category
                                </Button>
                                <Button color="orange" size='tiny' title="Delete Category" onClick={()=>this.removeCategoryConfirmation(categorie.id)}>
                                    <Icon name='times' />
                                    Delete Category
                                </Button>
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    getCategoriesCard = () => {
        let cards = [];
        let categories = this.state.categories;
        categories.forEach((categorie, index) => {
            cards.push(
                <Card className="categories-admin-card" fluid key={index}>
                    <Card.Content className="categories-admin-card-header" header={categorie.name} />
                    <Card.Content description={this.getOneCardDescription(categorie, index)} />
                </Card>
            );
        });
        cards.push(
            <div className="categories-admin-add-button-div" key="categories-admin-plus-button">
                <Button icon labelPosition='left' onClick={()=>this.redirectToEdit('new')}>
                    <Icon name='plus' />
                    Category
                </Button>
            </div>
        );
        return cards;
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if(removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Category' />
                    <Modal.Content>
                        <h3>This will completely remove the category with all products.</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={()=>this.deleteCategory(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    removeCategoryConfirmation = (id) => {
        this.setState({removeId: id, modalOpen:true});
    };

    render() {
        return (
            <div className="categories-admin-root">
                <Title
                    paramsPageTitle="categories"
                />
                <div className="categories-admin-filters">
                    <b>Vendor</b><br/>
                    <Select
                        className="categories-admin-filters-vendors"
                        text={this.state.vendor.name}
                        value={this.state.vendor.id}
                        options={this.state.vendorOptions}
                        onChange={this.handleChangeVendor}
                    />
                    { !this.state.showDeactivated ?
                        <div className="categories-admin-filters-buttons">
                            <Button color="orange" onClick={this.showDeactivatedToogle} size="tiny">Hide Deactivated Categories</Button>
                        </div>
                        :
                        <div className="categories-admin-filters-buttons">
                            <Button color="orange" onClick={this.showDeactivatedToogle} size="tiny">Show Deactivated Categories</Button>
                        </div>
                    }
                </div>
                <div className="categories-admin-table-div">
                    {
                        this.state.renderCategories ?
                            this.state.customSize ? this.getCategoriesCard() : this.getCategoriesContent()
                            :
                            ''
                    }
                </div>
                {this.getConfirmationModal()}
                <NotificationContainer/>
            </div>
        )
    }
}

export default Categories;