import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import '../assets/css/pages/admin/users-admin-view.css';
import {Image, Card, Button, Icon} from "semantic-ui-react";
import Request from "../Request";
import Cookies from "universal-cookie";

const jwt_decode = require('jwt-decode');

class UsersProfile extends React.Component {

    state = {
        username: null,
        user: {},
        stores:[],
        renderUser: false,
        showEdit: false
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if(this.props.match.params.username) {
            this.setState({username: this.props.match.params.username}, () => {
                this.getUser();
            });
            let cookies = new Cookies();
            let decodedCookie = cookies.getAll();
            if(decodedCookie.token) {
                let decoded = jwt_decode(decodedCookie.token);
                let tokenUsername = decoded.username ? decoded.username : null;
                this.setState({showEdit: tokenUsername.toLowerCase() === this.props.match.params.username.toLowerCase()})
            }
        }
    }

    getUser = () => {
        if(this.state.username) {
            new Promise((resolve, reject) => {
                let path = '/profile/' + this.state.username;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let user = JSON.parse(response);
                    if(user && user.success === true && user.data) {
                        this.setState({user: user.data, renderUser: true});
                    }
                    else {
                        this.setState({user: {}, renderUser: true});
                    }
                })
                .catch(error => {
                    this.setState({user: {}, renderUser: true});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get User', 'Error');
                    }
                });
        }
    };

    redirectToEditPage = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/'+ this.state.username +'/edit';
    };

    getUserHeaderDescriptions = () => {
        let user = this.state.user;
        return (
            <div className="users-admin-view-header-description-div">
                <div className="users-admin-view-avatar-div">
                    <div className="users-admin-view-avatar">
                        <Image src={user.gravatar} size='tiny' verticalAlign='middle' circular/>{' '}
                    </div>
                    <div className="users-admin-view-avatar-text-div">
                        <b>{user.name} <small>({user.username})</small></b>
                        </div>
                </div>
                <div className="users-admin-view-links">
                </div>
                { this.state.showEdit ?
                    <div className="users-admin-view-buttons">
                        <Button className="users-admin-view-button" icon labelPosition='left' color="orange" size="tiny" onClick={this.redirectToEditPage}>
                            <Icon name='pencil' />
                            Edit
                        </Button>
                    </div>
                    :''
                }
                <div className="users-admin-view-email-phone-div">
                    <b>Phone</b>
                    <p>{user.phone}</p>
                    <b>Email</b>
                    <p>{user.email}</p>
                </div>
            </div>
        );
    };

    getStoresTable = () => {
        let user = this.state.user;
        return (
            <div>
                <div className="users-admin-view-table-div">
                    <table className="users-admin-view-table" align="center">
                        <thead>
                            <tr>
                                <th className="users-admin-view-table-header">Name</th>
                                <th className="users-admin-view-table-header">Email</th>
                                <th className="users-admin-view-table-header">Location</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.renderUser && user && user.stores && user.stores.linked ?
                            user.stores.linked.map((store, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{store.title + ' (' + store.role + ')'}</td>
                                        <td>{store.email}</td>
                                        <td>{store.city + ', ' + store.state}</td>
                                    </tr>
                                )
                            })
                        :''
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
            </div>
        )
    };

    getRolesTable = () => {
        let roles = this.state.user && this.state.user.roles ? this.state.user.roles : [];
        return (
            <div>
                <div className="users-admin-view-roles-table-div">
                    <table className="users-admin-view-roles-table" align="center">
                        <thead>
                        <tr>
                            <th className="users-admin-view-table-header">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        { roles && roles.length > 0 ?
                            roles.map((role, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {role.name}
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td>{this.state.user.username} has no roles</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
            </div>
        )
    };

    render() {
        return (
            <div className="users-admin-view-root">
                {this.state.renderUser ? this.getUserHeaderDescriptions() : ''}
                <div className="users-admin-view-tables-div">
                    <div>
                        <div className="users-admin-view-stores-card-div">
                            <Card className="users-admin-view-stores-card" fluid>
                                <Card.Content className="users-admin-view-stores-card-header" header={<h3>Stores</h3>} />
                                <Card.Content className="users-admin-view-stores-card-description" description={this.getStoresTable()} />
                            </Card>
                        </div>
                        <div className="users-admin-view-roles-card-div">
                            <Card className="users-admin-view-roles-card" fluid>
                                <Card.Content className="documents-card-header" header={<h3>Roles</h3>} />
                                <Card.Content className="users-admin-view-roles-card-description" description={this.getRolesTable()} />
                            </Card>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default UsersProfile;