import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/categories-admin-edit.css';
import {Input, Button, Select} from "semantic-ui-react";
import Request from "../../Request";
import Title from 'components/Title';

class CategoriesEdit extends React.Component {

    state = {
        editId: null,
        category: {},
        vendor: {},
        vendors: [],
        vendorOptions: [],
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            if (this.props.match.params.id !== 'new') {
                this.setState({editId: this.props.match.params.id}, () => {
                    this.getCategory();
                });
            } else {
                this.setState({editId: this.props.match.params.id}, () => {
                    this.getVendors();
                })
            }

        }
    }

    getCategory = () => {
        if(this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/categories/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let category = JSON.parse(response);
                    if(category && category.success === true && category.data) {
                        this.setState({category: category.data});
                    }
                    else {
                        this.setState({category: {}});
                    }
                })
                .catch(error => {
                    this.setState({category: {}});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Category', 'Error');
                    }
                });
        }
    };

    getVendors = () => {
        new Promise((resolve, reject) => {
            let path = '/vendors';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let vendors = JSON.parse(response);
                if(vendors && vendors.success === true && vendors.data) {
                    this.setState({vendors: vendors.data, vendor: vendors.data[0]}, () => {
                        this.transformVendorsToOptions();
                    });
                }
                else {
                    this.setState({vendors: []});
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            })
            .catch(error => {
                this.setState({vendors: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            });
    };

    saveNewCategory = () => {
        let category = this.state.category;
        let vendorId = this.state.vendor.id;
        let editId = this.state.editId;
        if(editId === 'new' && category.name) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + vendorId;
                let params = {
                    name: category.name
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved Category', 'Success');
                    this.redirectToCategories();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save Category', 'Error');
                    }
                });
        }
    };

    updateCategory = () => {
        let category = this.state.category;
        let editId = this.state.editId;
        if(editId !== 'new' && category.name) {
            new Promise((resolve, reject) => {
                let path = '/categories/' + editId;
                let params = {
                    name: category.name
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Category', 'Success');
                    this.redirectToCategories();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Category', 'Error');
                    }
                });
        }
    };

    transformVendorsToOptions = () => {
        let vendors = this.state.vendors;
        let options = [];
        vendors.forEach((vendor, index) => {
            let tempOption = {
                id: vendor.id,
                key: vendor.id,
                value: vendor.id,
                text: vendor.name
            };
            options.push(tempOption);
        });
        this.setState({vendorOptions: options})
    };

    handleChangeVendor = (event, data) => {
        const foundVendor = this.state.vendors.find(element => element.id === parseInt(data.value));
        if(foundVendor) {
            this.setState({vendor: foundVendor});
        }
    };

    handleInputChanged = (event, field) => {
        let category = this.state.category;
        category[field] = event.target.value;
        this.setState({category: category});
    };

    redirectToCategories = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/categories'
    };

    render() {
        let category = this.state.category;
        return (
            <div className="categories-admin-edit-root">
                <Title
                    paramsPageTitle={this.state.editId === 'new' ? 'Category NEW' : 'Category EDIT'}
                />
                <div className="categories-admin-edit-fields">
                    { this.state.editId === 'new' ?
                        <div className="categories-admin-edit-fields-vendor">
                            <b>Vendor</b><br/>
                            <Select
                                className="categories-admin-filters-vendors"
                                text={this.state.vendor.name}
                                value={this.state.vendor.id}
                                options={this.state.vendorOptions}
                                onChange={this.handleChangeVendor}
                            />
                        </div>
                        :''
                    }
                    <b>Name:</b>
                    <Input
                        fluid
                        value={category.name || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'name')}
                    />
                    <br/>
                    <br/>
                    { this.state.editId === 'new' ?
                        <Button color="orange" size="tiny" onClick={this.saveNewCategory}>Submit</Button>
                        :
                        <Button color="orange" size="tiny" onClick={this.updateCategory}>Update</Button>
                    }
                    <Button color="orange" size="tiny" onClick={this.redirectToCategories}>Cancel</Button>
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default CategoriesEdit;