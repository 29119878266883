import React from 'react';
import './../../assets/css/pages/admin/schematics-admin-edit.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import {Grid, Button, Card, Form, Label} from "semantic-ui-react";
import ModalImage from "react-modal-image";
import Request from "../../Request";

class SchematicsEdit extends React.Component
{
    state = {
        mobileSize: window.innerWidth <= 768,
        tabletSize: window.innerWidth <= 1024,
        editId: null,
        schematic: null,
        file: null,
        filename: null,
        defaultSchematic: {
            schematic: {
                name: ''
            },
            files: []
        },
        renderSchema: false,
        loading: false
    };

    constructor(props) {
        super(props);
        this.request = new Request();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if(this.props.match.params.id) {
            this.setState({editId: this.props.match.params.id}, () => {
                this.getShematic();
            })
        }
    }

    resize() {
        this.setState({tabletSize: window.innerWidth <= 1024, mobileSize: window.innerWidth <= 768});
    }

    getShematic = () => {
        if(this.state.editId) {
            new Promise((resolve, reject) => {
                let path = '/schematics/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let schematics = JSON.parse(response);
                    if(schematics && schematics.success === true && schematics.data) {
                        this.setState({schematic: schematics.data}, ()=>{
                            this.setState({renderSchema:true, loading: false});
                        })
                    }
                    else {
                        this.setState({schematics: this.state.defaultSchematic}, ()=>{
                            this.setState({renderSchema:true, loading: false});
                        });
                    }
                })
                .catch(error => {
                    this.setState({schematics: this.state.defaultSchematic}, ()=>{
                        this.setState({renderSchema:true, loading: false});
                    });
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Schematics', 'Error');
                    }
                });
        }
    };

    saveSchematic = () => {
        this.setState({loading: true});
        new Promise((resolve, reject) => {
            let path = '/schematics/' + this.state.editId;
            let params = {
                name: this.state.schematic.schematic.name
            };
            if(this.state.filename && this.state.file) {
                params.file = this.state.file;
                params.filename = this.state.filename;
            }
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully saved Schematic', 'Success');
                this.resetFile();
                this.getShematic();
            })
            .catch(error => {
                this.setState({loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not edit Schematic', 'Error');
                }
            });
    };

    handleRemoveImage = (index) => {
        let schematic = this.state.schematic;
        let media = schematic.files.splice(index, 1);

        if(Array.isArray(media) && media.length ===1 && media[0].url) {
            new Promise((resolve, reject) => {
                let apiUrl = media[0].url;
                this.request.delete('', resolve, reject, apiUrl);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully removed media content', 'Success');
                        this.getShematic();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove media content', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove media content', 'Error');
        }
    };

    handleChangeName = (event) => {
        let schema = this.state.schematic;
        if(event.target.value.trim() !== '') {
            schema.schematic.name = event.target.value;
            this.setState({schema: schema}, ()=>{
                this.setState({renderSchema: true})
            })
        }
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/schematics/';
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({filename: event.target.files[0].name});
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result
                });
            };
        }
    };

    resetFile = () => {
        this.setState({file: null, filename: null});
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    getSchematicsEditContent = () => {
        let schema = this.state.schematic;
        let media = this.sortMedia(schema.files);
        if(this.state.renderSchema) {
            return (
                <div>
                    <Grid className="schematics-edit-grid">
                        <Grid.Row columns={2} className="schematics-edit-rows">
                            <Grid.Column width={this.state.mobileSize ? 12 : (this.state.tabletSize ? 8 : 5)}>
                                <Form>
                                    <Form.Field>
                                        <Label className="schematics-edit-name-label" pointing='below'>Name</Label>
                                        <input
                                            className="schematics-edit-name-input"
                                            type='text'
                                            value={schema.schematic.name || ''}
                                            placeholder="Enter name"
                                            onChange={this.handleChangeName}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} className="schematics-edit-rows">
                            <Grid.Column>
                                {this.state.loading ?
                                    <Button loading color="orange">
                                        Loading
                                    </Button>
                                    :
                                    <Button color="orange" onClick={() => this.saveSchematic()}>Save</Button>
                                }
                                <Button color="orange" onClick={()=>this.redirectBack()}>Cancel</Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} className="schematics-edit-rows">
                            <Grid.Column width={4}>
                                <div className="fileInput">
                                    <input ref={this.fileInput} type="file" accept=".jpeg, .jpg, .png, .svg, .mp4, .mov, .gif" onChange={this.handleFileChanged}/>
                                </div>
                                <div className="fileInputTypes">(jpg, jpeg, png, gif, svg, mp4, mov)</div>
                            </Grid.Column>
                        </Grid.Row>
                        {
                            Array.isArray(schema.files) && schema.files.length > 0 ?
                                <hr/>
                                : ''
                        }
                    </Grid>
                    <div>
                        <div className="schematics-edit-media">
                        {
                            media.map((file, index) => {
                                return (
                                        <div key={index}>
                                            { file.is_video ?
                                                <div className="schematics-edit-video-media-div" key={index}>
                                                    <video className="schematics-edit-videos" controls key={index + 'schema-edit-video'}>
                                                        <source src={file.url} type="video/mp4"/>
                                                    </video>
                                                    <div className="schematics-edit-remove-image-link">
                                                        <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                                                    </div>
                                                </div>
                                                :
                                                <div className="schematics-edit-images-media-div" key={index}>
                                                    <ModalImage
                                                        key={index + 'schema-edit-image'}
                                                        className="schematics-edit-images"
                                                        small={file.url}
                                                        large={file.url}
                                                        alt=""
                                                    />
                                                    <div className="schematics-edit-remove-image-link">
                                                        <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                );
                            })
                        }
                        </div>
                    </div>
                    <NotificationContainer/>
                </div>
            );
        }
        return '';
    };

    render() {
        return(
            <Card className="schematics-edit-card" fluid>
                <Card.Content className="schematics-edit-card-header" header="Schematics edit" />
                <Card.Content description={this.state.renderSchema ? this.getSchematicsEditContent() : ''} />
            </Card>
        )

    }
}

export default SchematicsEdit;