import React from 'react';

class NotFound extends React.Component
{
    render() {
        return this.getContent();
    }

    getContent = () => {
        return <h1>Page not found</h1>
    }
}

export default NotFound;
