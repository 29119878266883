import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/stores-admin-view.css';
import {Button, Select, Image, Icon, Card} from "semantic-ui-react";
import Request from "../../Request";


class StoresAdminView extends React.Component {
    state = {
        viewId: null,
        store: {},
        renderStore: false,
        vendorOptions: [],
        pickedVendor: {},
        rolesOptions: [],
        pickedRole: {},
        renderRolesSelect: false,
        renderVendorsSelect: false,
        userOptions: [],
        pickedUser: {},
        renderUsersSelect: false
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({viewId: this.props.match.params.id}, () => {
                this.getStore();
            })
        }
    }

    getStore = () => {
        if(this.state.viewId) {
            new Promise((resolve, reject) => {
                let path = '/stores/' + this.state.viewId + '/view';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let store = JSON.parse(response);
                    if(store && store.success === true && store.data) {
                        this.setState({store: store.data, renderStore: true},()=>{
                            this.transformVendorsToOptions();
                            this.transformUsersToOptions();
                            this.transformRolesToOptions();
                        });
                    }
                    else {
                        this.setState({user: {}, renderUser: true});
                    }
                })
                .catch(error => {
                    this.setState({user: {}, renderUser: true});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get User', 'Error');
                    }
                });
        }
    };

    linkToVendor = () => {
        let viewId = this.state.viewId;
        let vendorId = this.state.pickedVendor.id;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + vendorId + '/link/' + viewId;
                this.request.post(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Store', 'Success');
                    this.getStore();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Store', 'Error');
                    }
                });
        }
    };

    unlinkFromVendor = (vendorId) => {
        let viewId = this.state.viewId;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + vendorId + '/link/' + viewId;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Store', 'Success');
                    this.getStore();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Store', 'Error');
                    }
                });
        }
    };

    linkToUser = () => {
        let viewId = this.state.viewId;
        let userId = this.state.pickedUser.id;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/stores/' + viewId + '/users/' + userId + '/link';
                let params = {
                    role_id: this.state.pickedRole && this.state.pickedRole.id ? this.state.pickedRole.id : 0
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Store', 'Success');
                    this.getStore();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Store', 'Error');
                    }
                });
        }
    };

    unlinkFromUser = (userId) => {
        let viewId = this.state.viewId;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/stores/' + viewId + '/users/' + userId + '/link';
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Store', 'Success');
                    this.getStore();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Store', 'Error');
                    }
                });
        }
    };

    transformUsersToOptions = () => {
        let users = this.state.store.users.unlinked;
        let options = [];
        users.forEach((user, index) => {
            let tempOption = {
                id: user.id,
                key: user.id,
                value: user.id,
                text: user.name
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedUser: users[0]}, ()=>{
                this.setState({renderUsersSelect: true});
            });
        }
        else {
            this.setState({renderUsersSelect: false});
        }

        this.setState({userOptions: options})
    };

    transformRolesToOptions = () => {
        let roles = this.state.store.store_roles;
        let options = [];
        roles.forEach((role, index) => {
            let tempOption = {
                id: role.id,
                key: role.id,
                value: role.id,
                text: role.name
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedRole: roles[0]}, ()=>{
                this.setState({renderRolesSelect: true});
            });
        }
        else {
            this.setState({renderRolesSelect: false});
        }

        this.setState({rolesOptions: options})
    };

    transformVendorsToOptions = () => {
        let vendors = this.state.store.vendors.unlinked;
        let options = [];
        vendors.forEach((vendor, index) => {
            let tempOption = {
                id: vendor.id,
                key: vendor.id,
                value: vendor.id,
                text: vendor.name
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedVendor: vendors[0]}, ()=>{
                this.setState({renderVendorsSelect: true});
            });
        }
        else {
            this.setState({renderVendorsSelect: false});
        }

        this.setState({vendorOptions: options})
    };

    redirectBackToStores = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores';
    };

    redirectToStoresEdit = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/edit/' + id;
    };

    redirectToVendorView = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors/' + id;
    };

    redirectToUserView = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users/' + id;
    };

    redirectToVendorNew = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors/new/edit';
    };

    redirectToUserNew = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users/edit/new';
    };

    getStoreHeaderDescriptions = () => {
        let store = this.state.store;
        return (
            <div className="stores-admin-view-header-description-div">
                <div className="stores-admin-view-name-div">
                    <h1>{store.title} (STORE)</h1>
                </div>
                <div className="stores-admin-view-buttons">
                    <Button className="stores-admin-view-button" icon labelPosition='left' color="orange" size="tiny" onClick={()=>this.redirectToStoresEdit(store.id)}>
                        <Icon name='pencil' />
                        Edit
                    </Button>
                    <Button className="stores-admin-view-button" icon labelPosition='left' color="orange" size="tiny" onClick={this.redirectBackToStores}>
                        <Icon name='users' />
                        Back to all Stores
                    </Button>
                </div>
                <div className="stores-admin-view-store-infos">
                    <b>Address</b><br/>
                    <span>{store.street1 || ''}</span><br/>
                    <span>{store.city + ', ' + store.state + ' ' + store.zip}</span><br/>
                    <b>Phone</b><br/>
                    <span>{store.phone || ''}</span><br/>
                    <b>Email</b><br/>
                    <span>{store.email || ''}</span><br/>
                    <b>Email 2</b><br/>
                    <span>{store.email2 || ''}</span><br/>
                    <b>Fax</b><br/>
                    <span>{store.fax || ''}</span><br/>
                </div>
            </div>
        )
    };

    handleChangeUser = (event, data) => {
        const foundUser = this.state.store.users.unlinked.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundUser) {
            this.setState({pickedUser: foundUser});
        }
    };

    handleChangeRole = (event, data) => {
        const foundRole = this.state.store.store_roles.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundRole) {
            this.setState({pickedRole: foundRole});
        }
    };

    handleChangeVendor = (event, data) => {
        const foundVendor = this.state.store.vendors.unlinked.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundVendor) {
            this.setState({pickedVendor: foundVendor});
        }
    };

    getVendorsTable = () => {
        let store = this.state.store;
        return (
            <div>
                <div className="stores-admin-view-table-div">
                    <table className="stores-admin-view-table" align="center">
                        <thead>
                        <tr>
                            <th className="stores-admin-view-table-header">Name</th>
                            <th className="stores-admin-view-table-header">Email</th>
                            <th className="stores-admin-view-table-header">Location</th>
                            <th className="stores-admin-view-table-header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.renderStore && store && store.vendors && store.vendors.linked ?
                            store.vendors.linked.map((vendor, index) => {
                                return (
                                    <tr>
                                        <td>{vendor.name || ''}</td>
                                        <td>{vendor.email || ''}</td>
                                        <td>{vendor.city + ', ' + vendor.state}</td>
                                        <td className="stores-admin-view-table-actions-td">
                                            <Button.Group icon>
                                                <Button size='tiny' onClick={()=>this.redirectToVendorView(vendor.id)}>
                                                    <Icon name='eye' />
                                                </Button>
                                                <Button size='tiny' title="Unlink Store from Vendor" onClick={()=>this.unlinkFromVendor(vendor.id)}>
                                                    <Icon name='unlink' />
                                                </Button>
                                            </Button.Group>
                                        </td>
                                    </tr>
                                )
                            })
                        :''
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className="stores-admin-view-card-actions">
                    {this.state.renderVendorsSelect ?
                        <div>
                            <Select
                                text={this.state.pickedVendor.name}
                                value={this.state.pickedVendor.id}
                                options={this.state.vendorOptions}
                                onChange={this.handleChangeVendor}
                            />
                            <Button color="orange" size="mini" onClick={this.linkToVendor}>
                                Link to Vendor
                            </Button>
                        </div>
                        : ''
                    }

                    <Button icon labelPosition='left' color="orange" size="mini" onClick={this.redirectToVendorNew}>
                        <Icon name='plus' />
                        Vendor
                    </Button>
                </div>
            </div>
        )
    };

    getUsersTable = () => {
        let store = this.state.store;
        return (
            <div>
                <div className="stores-admin-view-table-div">
                    <table className="stores-admin-view-table" align="center">
                        <thead>
                        <tr>
                            <th className="stores-admin-view-table-header"></th>
                            <th className="stores-admin-view-table-header">Name</th>
                            <th className="stores-admin-view-table-header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.renderStore && store && store.users && store.users.linked ?
                            store.users.linked.map((user, index) => {
                                return (
                                    <tr>
                                        <td><Image src={user.gravatar || ''} avatar /></td>
                                        <td>{user.name + ' (' + user.role + ')'}</td>
                                        <td className="stores-admin-view-table-actions-td">
                                            <Button.Group icon>
                                                <Button size='tiny' onClick={()=>this.redirectToUserView(user.id)}>
                                                    <Icon name='eye' />
                                                </Button>
                                                <Button size='tiny' title="Unlink Store from Vendor" onClick={()=>this.unlinkFromUser(user.id)}>
                                                    <Icon name='unlink' />
                                                </Button>
                                            </Button.Group>
                                        </td>
                                    </tr>
                                )
                            })
                            :''
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className="stores-admin-view-card-actions">
                    {this.state.renderUsersSelect ?
                        <div>
                            <Select
                                text={this.state.pickedUser.name}
                                value={this.state.pickedUser.id}
                                options={this.state.userOptions}
                                onChange={this.handleChangeUser}
                            />
                            {this.state.renderRolesSelect ?
                                <Select
                                    text={this.state.pickedRole.name}
                                    value={this.state.pickedRole.id}
                                    options={this.state.rolesOptions}
                                    onChange={this.handleChangeRole}
                                />
                                :''
                            }
                            <Button color="orange" size="mini" onClick={this.linkToUser}>
                                Link to User
                            </Button>
                        </div>
                        : ''
                    }
                    <Button icon labelPosition='left' color="orange" size="mini" onClick={this.redirectToUserNew}>
                        <Icon name='plus' />
                        User
                    </Button>
                </div>
            </div>
        )
    };

    render() {
        return (
            <div className="stores-admin-view-root">
                {this.state.renderStore ? this.getStoreHeaderDescriptions() : ''}
                <div className="stores-admin-view-tables-div">
                    <div>
                        <div className="stores-admin-view-vendors-card-div">
                            <Card className="stores-admin-view-vendors-card" fluid>
                                <Card.Content className="stores-admin-view-vendors-card-header" header={<h3>Vendors<small> (Create, Link, Unlink)</small></h3>} />
                                <Card.Content className="stores-admin-view-vendors-card-description" description={this.getVendorsTable()} />
                            </Card>
                        </div>
                        <div className="stores-admin-view-users-card-div">
                            <Card className="stores-admin-view-users-card" fluid>
                                <Card.Content className="stores-admin-view-users-card-header" header={<h3>Users<small> (Create, Link, Unlink)</small></h3>} />
                                <Card.Content className="stores-admin-view-users-card-description" description={this.getUsersTable()} />
                            </Card>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}
export default StoresAdminView;