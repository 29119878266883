import React from 'react';
import { Grid, Header, Segment, Button, Form, Checkbox } from 'semantic-ui-react'
import 'assets/css/pages/login.css';
import {NotificationManager, NotificationContainer} from "react-notifications";
import Cookies from "universal-cookie";

const requestPromise = require('request-promise');
const jwt_decode = require('jwt-decode');

class Login extends React.Component
{
    state = {
        user : {
            username: '',
            password: ''
        },
        rememberMe: false,
        cookies: null
    };

    UNSAFE_componentWillMount() {
        document.body.classList.add('login-content-background');
    }

    handleLogin = () => {
        let user = this.state.user;
        if(user.username && user.password) {
            let params = {
                username: user.username,
                password: user.password,
                remember_me: this.state.rememberMe
            };
            let url = process.env.REACT_APP_URL;
            let path = '/auth';
            let options = {
                method: 'POST',
                uri: url + path,
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            requestPromise(options)
                .then(response => {
                    response = JSON.parse(response);
                    if( response.token ) {
                        let decoded = jwt_decode(response.token);
                        let date = new Date(decoded.exp * 1000);
                        let cookies = new Cookies();
                        cookies.set('token', response.token, { expires: date, secure: true });
                        this.setState({cookies: cookies});
                        this.saveUserToCookie(response.token, date);
                    }
                })
                .catch(error => {
                    console.log(error)
                    NotificationManager.error('Login failed', 'Error');
                });
        }
    };

    saveUserToCookie = (token, date) => {
        let decoded = jwt_decode(token);
        if(decoded.username) {
            let url = process.env.REACT_APP_URL;
            let path = '/user/' + decoded.username;
            let options = {
                method: 'GET',
                uri: url + path,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };
            requestPromise(options)
                .then(response => {
                    response = JSON.parse(response);
                    if( response.success === true && response.data ) {
                        let name = response.data.firstName + ' ' + response.data.lastName;
                        let cookies = new Cookies();
                        cookies.set('user-name', name, { expires: date, secure: true });
                        cookies.set('user-id', response.data.id, { expires: date, secure: true });
                        this.setState({cookies: cookies});
                        this.redirectToDashboard();
                    }
                })
                .catch(error => {
                    NotificationManager.error('Invalid User or Password', 'Error');
                });
        }
    };

    redirectToDashboard = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url;
    };

    handleInputChanged = (event, field) => {
        let user = this.state.user;
        user[field] = event.target.value;
        this.setState({user: user});
    };

    checkboxChanged = () => {
        this.setState({rememberMe: !this.state.rememberMe});
    };

    render() {
        return (
            <div className='login-content'>
                <Grid columns={2} centered stackable verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h1' className='logo' />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form size='large'>
                                <Segment>
                                    <figure className="mustache" />
                                    <h1 className='login-h1'>Management Portal</h1>
                                    <Form.Input
                                        className='loginInput'
                                        size='large'
                                        fluid
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Username or Email'
                                        value={this.state.user.username}
                                        onChange={(e)=>this.handleInputChanged(e, 'username')}
                                    />
                                    <Form.Input
                                        size='large'
                                        fluid
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='Password'
                                        type='password'
                                        value={this.state.user.password}
                                        onChange={(e)=>this.handleInputChanged(e, 'password')}
                                    />
                                    <Checkbox
                                        label='Remember me'
                                        onChange={this.checkboxChanged}
                                    />
                                    <Button color='light-grey' fluid size='large' onClick={this.handleLogin}>Login</Button>
                                    <br />
                                    <p align="center"><a href="/forgot-password">Forgot password</a></p>
                                </Segment>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <NotificationContainer/>
            </div>
        );
    }
}

export default Login;
