import React from 'react';
import {Checkbox, Grid, Button, Icon, Card} from "semantic-ui-react";
import './../../assets/css/pages/managment/hrDocuments.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import { WithContext as ReactTags } from 'react-tag-input';
import Request from "../../Request";
import Cookies from "universal-cookie";
import Title from 'components/Title';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class HrDocuments extends React.Component
{
    state= {
        emailTags: [],
        emailTagInput: '',
        documents: [],
        renderDocuments: false,
        customSize: window.innerWidth <= 1360,
        tabletSize: window.innerWidth <= 900,
        token: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.setState({renderDocuments:false}, () => {
            this.getHrDocuments();
        });

        this.saveToken();

        window.addEventListener("resize", this.resize.bind(this));
        this.emailsRef = React.createRef();

        this.resize();
    }

    saveToken = () => {
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if(decodedCookie.token) {
            this.setState({token: decodedCookie.token});
        }
        else {
            this.redirectToLogin();
        }
    };

    redirectToLogin ()
    {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/logout';
    }

    resize() {
        this.setState({customSize: window.innerWidth <= 1360, tabletSize: window.innerWidth <= 900});
    }

    getHrDocuments = () => {
        new Promise((resolve, reject) => {
            let path = '/documents';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let documents = JSON.parse(response);
                if(documents && documents.success === true && documents.data) {
                    this.setState({documents: documents.data}, () => {
                        this.setState({renderDocuments: true});
                    });
                }
            })
            .catch(error => {
                this.setState({renderDocuments: true});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Hr-Documents', 'Error');
                }
            });
    };

    sendEmails = () => {
        let documentsForSending = this.gatherDocumentsForSending();
        let emails = this.gatherEmailsForSending();
        new Promise((resolve, reject) => {
            let path = '/documents/send';
            let params = {
                emails: emails,
                ids: documentsForSending
            };
            this.request.post(path, params, resolve, reject);
        })
        .then(response => {
            NotificationManager.success('Successfully sent documents', 'Success');
            this.resetAllFields();
        })
        .catch(error => {
            if(error) {
                NotificationManager.error(error, 'Error');
            }
            else {
                NotificationManager.error('Could not send documents', 'Error');
            }
        });
    };

    gatherEmailsForSending = () => {
        let emailTags = this.state.emailTags;
        let emailsString = [];

        emailTags.forEach((email)=>{
            emailsString.push(email.text);
        });

        return emailsString.join(',');
    };

    gatherDocumentsForSending = () => {
        let documents = this.state.documents;
        let sendData = [];

        documents.forEach((document) => {
            if(document.send === true) {
                sendData.push(document.id);
            }
        });

        return (sendData && Array.isArray(sendData) && sendData.length > 0 ? sendData.join(',') : '');
    };

    resetAllFields = () => {
        let documents = this.state.documents;

        documents.forEach((document) => {
            document.send = false;
        });

        this.setState({documents: documents, emailTags: []})
    };

    handleDeleteEmailTag = (i) => {
        let emailTags = this.state.emailTags;
        this.setState({
            emailTags: emailTags.filter((tag, index) => index !== i),
        });
    };

    handleAdditionEmailTag = (tag) => {
        let emailTags = this.state.emailTags;
        emailTags.push(tag);
        this.setState({ emailTags: emailTags, emailTagInput: ''});
    };

    onTagInputChage = (value) => {
        if(value) {
            this.setState({emailTagInput: value});
        }
    };

    addEmailTagFromButtonClick = () => {
        let emailTagInput = this.state.emailTagInput;
        if(emailTagInput.trim() !== '') {
            this.emailsRef.current.props.handleAddition({
                id: emailTagInput, text: emailTagInput
            });
            this.emailsRef.current.ref.current.resetAndFocusInput();
            this.setState({emailTagInput: ''});
        }
    };

    printHrDocument = (event, url) => {
        event.preventDefault();
        window.open(url, "PRINT", "height=600,width=800");
    };

    getHrDocumentsContent = () => {
        let documents = this.state.documents;
        return (
            <Grid className="hr-documents-grid">
                <Grid.Row className="hr-documents-grid-header-row">
                    <Grid.Column width={1}>
                        Send
                    </Grid.Column>
                    <Grid.Column width={7}>
                        Title
                    </Grid.Column>
                    <Grid.Column width={7}>
                        Filename
                    </Grid.Column>
                    <Grid.Column width={1}>
                    </Grid.Column>
                </Grid.Row>
                {documents && Array.isArray(documents)?
                    documents.map((document, index) => {
                        return (
                            <Grid.Row className="hr-documents-grid-row">
                                <Grid.Column width={1}>
                                    <Checkbox checked={document.send || false} onChange={()=>this.checkboxChanged(index)} />
                                </Grid.Column>
                                <Grid.Column width={this.state.customSize ? 6 : 7}>
                                    {document.title || ''}
                                </Grid.Column>
                                <Grid.Column width={this.state.customSize ? 6 : 7}>
                                    {document.file || ''}
                                </Grid.Column>
                                <Grid.Column width={this.state.customSize ? 3 : 1}>
                                    <Button.Group icon>
                                        <Button size='tiny' onClick={(e)=>this.printHrDocument(e, document.url)}>
                                            <Icon name='print' />
                                        </Button>
                                        <Button size='tiny' as="a" href={document.url + '?token=' + this.state.token} target="_blank">
                                            <Icon name='eye' />
                                        </Button>
                                    </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })
                    : ''
                }
                {documents && Array.isArray(documents) && documents.length > 0 ?
                    <Grid.Row className="hr-documents-grid-footer-row">
                        <Grid.Column width={2}>
                            <Button onClick={this.checkAllCheckboxes}>Check All</Button>
                        </Grid.Column>
                    </Grid.Row>
                    :''
                }
            </Grid>
        );
    };

    checkboxChanged = (index) => {
        let documents = this.state.documents;
        let document = documents[index];
        if(typeof document.send === 'undefined') {
            document.send = true;
        }
        else {
            document.send = !document.send;
        }
        documents[index] = document;
        this.setState({documents: documents});
    };

    checkAllCheckboxes = () => {
        let documents = this.state.documents;

        documents.forEach((document) => {
            document.send = true;
        });

        this.setState({documents: documents})
    };

    getHrDocumentsCard = () => {
        let documents = this.state.documents;
        let cards = [];
        if(documents && Array.isArray(documents)) {
            documents.forEach((document, index) => {
                cards.push(
                    <Card className="hr-documents-card" fluid key={index}>
                        <Card.Content className="hr-documents-card-header" header={document.title} />
                        <Card.Content description={this.getOneCardDescription(document, index)} />
                    </Card>
                );
            });
            if(documents.length > 0) {
                cards.push(
                    <div className="hr-documents-card-check-button-div">
                        <Button onClick={this.checkAllCheckboxes}>Check All</Button>
                    </div>
                );
            }
            return cards;
        }
        return '';

    };

    getOneCardDescription = (document, index) => {
        return (
            <Grid className="hr-documents-card-grid">
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <Checkbox checked={document.send || false} className="hr-documents-card-checkbox" label={{ children: 'Send' }} onChange={()=>this.checkboxChanged(index)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Title: </b>{document.title}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Filename: </b>{document.file}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <Button.Group icon>
                            <Button size='tiny' onClick={(e)=>this.printHrDocument(e, document.url)}>
                                <Icon name='print' />
                                Print
                            </Button>
                            <Button size='tiny' as="a" href={document.url} target="_blank">
                                <Icon name='eye' />
                                View
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    render() {
        return (
            <div className="hr-documents-root">
                <Title
                    paramsPageTitle="HR Documents"
                />
                <div className="hr-documents-email-input-div">
                    <div>
                        <b>Email:</b>
                    </div>
                    {/* <ReactTags  tags={this.state.emailTags}
                        handleDelete={this.handleDeleteEmailTag}
                        handleAddition={this.handleAdditionEmailTag}
                        delimiters={delimiters}
                        allowDragDrop={false}
                        placeholder="Add email"
                        handleInputChange={this.onTagInputChage}
                        ref={this.emailsRef}/> */}
                    <Button className="hr-documents-tags-add-button" size="tiny" onClick={this.addEmailTagFromButtonClick}>Add Email</Button>
                </div>
                {this.state.renderDocuments ? (this.state.tabletSize ? this.getHrDocumentsCard() : this.getHrDocumentsContent()) : ''}
                <div className="hr-documents-send-div">
                    <Button color="orange" onClick={this.sendEmails}>Send</Button>
                </div>
                <NotificationContainer/>
            </div>
        );
    }
}

export default HrDocuments;
