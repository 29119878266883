import React from 'react';
import {Grid, Header, Segment, Button, Form, Modal} from 'semantic-ui-react'
import './../assets/css/pages/forgotPassword.css';
import Cookies from "universal-cookie";
import {NotificationContainer, NotificationManager} from "react-notifications";
const requestPromise = require('request-promise');

class UserInvitePage extends React.Component
{
    state = {
        reset: {
            code: '',
            password: '',
            passwordConfirm: '',
        },
        modalOpen: false,
        message: ''
    };

    constructor(props) {
        super(props);
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if (decodedCookie.token) {
            this.redirectToDashboard();
        }
    }

    UNSAFE_componentWillMount() {
        document.body.classList.add('fp-content');
    }

    componentDidMount() {
        if(this.props.code) {
            let reset = this.state.reset;
            reset.code = this.props.code;
            this.setState({reset: reset});
        }
    }

    handleInputChanged = (event, field) => {
        let reset = this.state.reset;
        reset[field] = event.target.value;
        this.setState({reset: reset});
    };

    handleUserInvite = () => {
        let params = {
            code: this.state.reset.code,
            password: this.state.reset.password,
            password_confirm: this.state.reset.passwordConfirm,
        };
        let url = process.env.REACT_APP_URL;
        let path = '/users/invite';
        let options = {
            method: 'POST',
            uri: url + path,
            body: JSON.stringify(params)
        };

        requestPromise(options)
            .then(response => {
                response = JSON.parse(response);
                if( response.success === true && response.message) {
                    this.setState({modalOpen:true, message: response.message});
                }
            })
            .catch(error => {
                let errorObject = error && error.error ? JSON.parse(error.error) : {};
                let message = errorObject.message ? errorObject.message : null;
                if(message) {
                    NotificationManager.error(message, 'Error');
                }
                else {
                    NotificationManager.error('Password reset failed', 'Error');
                }
            });
    };

    redirectToLogin ()
    {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/login';
    }

    redirectToDashboard ()
    {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url;
    }

    getConfirmationModal = () => {
        return (
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleCloseModal}
                size='small'
            >
                <Header content='Success' />
                <Modal.Content>
                    <h3>You have successfully created a password. Please continue to login.</h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.redirectToLogin} inverted>
                        Login
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    handleCloseModal = () => {
        this.setState({
            modalOpen: false
        });
    };

    render() {
        return (
            <div className='fp-content'>
                <Grid columns={2} centered stackable verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h1' className='logo' />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form size='large' autoComplete='new-password'>
                                <Segment>
                                    <h1 className='fp-h1'>CREATE PASSWORD</h1>
                                    <Form.Input autoComplete='new-password' type="password" className='fp-input' fluid placeholder='Password' value={this.state.reset.password} onChange={(e)=>this.handleInputChanged(e, 'password')} />
                                    <Form.Input autoComplete='new-password' type="password" className='fp-input' fluid placeholder='Confirm Password' value={this.state.reset.passwordConfirm} onChange={(e)=>this.handleInputChanged(e, 'passwordConfirm')} />
                                    <Button color='light-grey' fluid size='large' onClick={this.handleUserInvite}>Save Password</Button>
                                </Segment>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.getConfirmationModal()}
                <NotificationContainer/>
            </div>
        );
    }
}

export default UserInvitePage;
