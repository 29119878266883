import Cookies from "universal-cookie";

const jwt_decode = require('jwt-decode');

function MenuItems() {
    let menu = {
        'check-lists': {
            'title': {
                'name': 'check-lists',
                'content': 'Check Lists',
                'index': 0
            },
            'submenu': {
                'comm-sheet': {
                    'name': 'comm-sheet',
                    'content': 'Communication Sheet',
                    'path': '/check-lists/comm-sheet'
                },
                'cash-count': {
                    'name': 'cash-count',
                    'content': 'Cash Count Sheet',
                    'path': '/check-lists/cash-count'
                },
                'daily-check': {
                    'name': 'daily-check',
                    'content': 'Daily Checklist',
                    'path': '/check-lists/daily-check'
                },
                'wastelog': {
                    'name': 'wastelog',
                    'content': 'Waste Log',
                    'path': '/check-lists/wastelog'
                }
            }
        },
        'recipes': {
            'title': {
                'name': 'recipes',
                'content': 'Recipes',
                'index': 1
            },
            'submenu': {
                'espresso-bar': {
                    'name': 'espresso-bar',
                    'content': 'Espresso Bar',
                    'path': '/recipes/espresso-bar'
                },
                'coffee-bar': {
                    'name': 'coffee-bar',
                    'content': 'Coffee Bar',
                    'path': '/recipes/coffee-bar'
                },
                'tea-bar': {
                    'name': 'tea-bar',
                    'content': 'Tea Bar',
                    'path': '/recipes/tea-bar'
                },
                'cooling-aids': {
                    'name': 'cooling-aids',
                    'content': 'Cooling Aids',
                    'path': '/recipes/cooling-aids'
                },
                'cofftails-dreams': {
                    'name': 'cofftails-dreams',
                    'content': 'Cofftails & Dreams',
                    'path': '/recipes/cofftails-dreams'
                },
                'batch-recipes': {
                    'name': 'batch-recipes',
                    'content': 'Batch Recipes',
                    'path': '/recipes/batch-recipes'
                },
                'hot-food-prep': {
                    'name': 'hot-food-prep',
                    'content': 'Hot Food Prep',
                    'path': '/recipes/hot-food-prep'
                },
            }
        },
        /*
        'batch-recipes': {
            'title': {
                'name': 'batch-recipes',
                'content': 'Batch Recipes',
                'path': '/batch-recipes'
            }
        },
        'drink-recipes': {
            'title': {
                'name': 'drink-recipes',
                'content': 'Drink Recipes',
                'path': '/drink-recipes'
            }
        },
        */
        'schematics': {
            'title': {
                'name': 'schematics',
                'content': 'Schematics',
                'path': '/schematics'
            }
        }
    };
    let cookies = new Cookies();
    let decodedCookie = cookies.getAll();
    if(decodedCookie.token) {
        let decoded = jwt_decode(decodedCookie.token);
        let managementToken = decodedCookie['management-token'];
        let roles = decoded.roles;
        let username = decoded.username;
        let isAdmin = roles.includes("ROLE_ADMIN");

        if(managementToken || isAdmin) {
            menu.management = {
                'title': {
                    'name': 'management',
                    'content': 'Management',
                    'index': 2
                },
                'submenu': {
                    'address-book': {
                        'name': 'address-book',
                        'content': 'Address Book',
                        'path': '/management/address-book'
                    },
                    'checklists': {
                        'name': 'checklists',
                        'content': 'Checklists',
                        'path': '/management/checklists'
                    },
                    'hr-documents': {
                        'name': 'hr-documents',
                        'content': 'HR Documents',
                        'path': '/management/hr-documents'
                    },
                    'key-release': {
                        'name': 'key-release',
                        'content': 'Key Release',
                        'path': '/management/key-release'
                    },
                    'new-orders': {
                        'name': 'new-orders',
                        'content': 'New Orders',
                        'path': '/management/new-orders'
                    },
                    'order-history': {
                        'name': 'order-history',
                        'content': 'Order History',
                        'path': '/management/order-history'
                    },
                    'service-ticket': {
                        'name': 'service-ticket',
                        'content': 'Service Ticket',
                        'path': '/management/service-ticket'
                    },
                    'management-logout': {
                        'name': 'management-logout',
                        'content': 'Logout',
                        'path': '/management/logout'
                    }
                }
            };
            if (isAdmin) {
                delete menu.management.submenu['management-logout'];
            }
        }
        else {
            menu.management = {
                'title': {
                    'name': 'management',
                    'content': 'Management',
                    'index': 2
                },
                'submenu': {
                    'management-login': {
                        'name': 'management-login',
                        'content': 'Login',
                        'path': '/management/login'
                    }
                }
            }
        }

        if(roles.includes("ROLE_ADMIN")) {
            menu.admin = {
                'title': {
                    'name': 'admin',
                    'content': 'Admin',
                    'index': 3
                },
                'submenu': {
                    'dashboard': {
                        'name': 'dashboard',
                        'content': 'Dashboard',
                        'path': '/admin/dashboard'
                    },
                    'promotion': {
                        'name': 'promotion',
                        'content': 'Promotion',
                        'path': '/admin/promotion'
                    },
                    'users': {
                        'name': 'users',
                        'content': 'Users',
                        'path': '/admin/users'
                    },
                    'stores': {
                        'name': 'stores',
                        'content': 'Stores',
                        'path': '/admin/stores'
                    },
                    'vendors': {
                        'name': 'vendors',
                        'content': 'Vendors',
                        'path': '/admin/vendors'
                    },
                    'documents': {
                        'name': 'documents',
                        'content': 'Documents',
                        'path': '/admin/documents'
                    },
                    'schematics-adm': {
                        'name': 'schematics-adm',
                        'content': 'Schematics',
                        'path': '/admin/schematics'
                    },
                    'recipes': {
                        'name': 'recipes',
                        'content': 'Recipes',
                        'path': '/admin/recipes'
                    },
                    'categories': {
                        'name': 'categories',
                        'content': 'Categories',
                        'path': '/admin/categories'
                    },
                    'products': {
                        'name': 'products',
                        'content': 'Products',
                        'path': '/admin/products'
                    }
                }
            }
        }

        if(decodedCookie['user-name'] && decodedCookie['user-id']) {
            menu.account = {
                'title': {
                    'name': 'account',
                    'content': decodedCookie['user-name'],
                    'index': 4
                },
                'submenu': {
                    'user-account': {
                        'name': 'user-account',
                        'content': 'Account',
                        'path': '/profile/' + username
                    },
                    'user-logout': {
                        'name': 'user-logout',
                        'content': 'Logout',
                        'path': '/logout'
                    }
                }
            };
        }

        return menu;
    }

    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + '/login';
}

export default MenuItems;
