import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/users-admin-view.css';
import {Button, Image, Icon, Card, Select} from "semantic-ui-react";
import Request from "../../Request";

class UsersAdminView extends React.Component {

    state = {
        viewId: null,
        user: {},
        stores:[],
        renderUser: false,
        storeOptions: [],
        pickedStore: {},
        renderStoresSelect: false,
        rolesOptions: [],
        pickedRole: {},
        renderRolesSelect: false,
        baseRolesOptions: [],
        pickedBaseRole: {},
        renderBaseRolesSelect: false
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({viewId: this.props.match.params.id}, () => {
                this.getUser();
            })
        }
    }

    getUser = () => {
        if(this.state.viewId) {
            new Promise((resolve, reject) => {
                let path = '/users/' + this.state.viewId + '/view';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let user = JSON.parse(response);
                    if(user && user.success === true && user.data) {
                        this.setState({user: user.data, renderUser: true},()=>{
                            this.transformStoresToOptions();
                            this.transformRolesToOptions();
                            this.transformBaseRolesToOptions();
                        });
                    }
                    else {
                        this.setState({user: {}, renderUser: true});
                    }
                })
                .catch(error => {
                    this.setState({user: {}, renderUser: true});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get User', 'Error');
                    }
                });
        }
    };

    deleteUser = () => {
        if(this.state.viewId) {
            new Promise((resolve, reject) => {
                let path = '/users/'+ this.state.viewId;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully removed User', 'Success');
                        this.redirectBack();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove User', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove User', 'Error');
        }
    };

    linkToStore = () => {
        let viewId = this.state.viewId;
        let storeId = this.state.pickedStore.id;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/users/' + viewId + '/stores/' + storeId + '/link';
                let params = {
                    role_id: this.state.pickedRole && this.state.pickedRole.id ? this.state.pickedRole.id : 0
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated User', 'Success');
                    this.getUser();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update User', 'Error');
                    }
                });
        }
    };

    linkToUser = () => {
        let viewId = this.state.viewId;
        let roleId = this.state.pickedBaseRole.id;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/users/' + viewId + '/roles/' + roleId + '/link';
                this.request.post(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated User', 'Success');
                    this.getUser();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update User', 'Error');
                    }
                });
        }
    };

    unlinkFromStore = (storeId) => {
        let viewId = this.state.viewId;
        if(viewId) {
            new Promise((resolve, reject) => {
                let path = '/users/' + viewId + '/stores/' + storeId + '/link';
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated User', 'Success');
                    this.getUser();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update User', 'Error');
                    }
                });
        }
    };

    unlinkRole = (id) => {
        let viewId = this.state.viewId;
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/users/' + viewId + '/roles/' + id + '/link';
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated User', 'Success');
                    this.getUser();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update User', 'Error');
                    }
                });
        }
    };

    handleChangeRole = (event, data) => {
        const foundRole = this.state.user.roles_select.store_roles.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundRole) {
            this.setState({pickedRole: foundRole});
        }
    };

    handleChangeBaseRole = (event, data) => {
        const foundRole = this.state.user.roles_select.base_roles.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundRole) {
            this.setState({pickedBaseRole: foundRole});
        }
    };

    transformRolesToOptions = () => {
        let roles = this.state.user.roles_select.store_roles;
        let options = [];
        roles.forEach((role, index) => {
            let tempOption = {
                id: role.id,
                key: role.id,
                value: role.id,
                text: role.name
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedRole: roles[0]}, ()=>{
                this.setState({renderRolesSelect: true});
            });
        }
        else {
            this.setState({renderRolesSelect: false});
        }

        this.setState({rolesOptions: options})
    };

    transformBaseRolesToOptions = () => {
        let roles = this.state.user.roles_select.base_roles;
        let options = [];
        roles.forEach((role, index) => {
            let tempOption = {
                id: role.id,
                key: role.id,
                value: role.id,
                text: role.name
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedBaseRole: roles[0]}, ()=>{
                this.setState({renderBaseRolesSelect: true});
            });
        }
        else {
            this.setState({renderBaseRolesSelect: false});
        }

        this.setState({baseRolesOptions: options})
    };

    transformStoresToOptions = () => {
        let stores = this.state.user.stores.unlinked;
        let options = [];
        stores.forEach((store, index) => {
                let tempOption = {
                    id: store.id,
                    key: store.id,
                    value: store.id,
                    text: store.title
                };
                options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({pickedStore: stores[0]}, ()=>{
                this.setState({renderStoresSelect: true});
            });
        }
        else {
            this.setState({renderStoresSelect: false});
        }

        this.setState({storeOptions: options})
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users';
    };

    redirectToEditPage = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users/edit/' + this.state.viewId;
    };

    redirectToStoreView = (storeId) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/' + storeId;
    };

    redirectToStoreEdit = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/edit/new';
    };

    getUserHeaderDescriptions = () => {
        let user = this.state.user;
        return (
            <div className="users-admin-view-header-description-div">
                <div className="users-admin-view-avatar-div">
                    <div className="users-admin-view-avatar">
                        <Image src={user.gravatar} size='tiny' verticalAlign='middle' circular/>{' '}
                    </div>
                    <div className="users-admin-view-avatar-text-div">
                        <b>{user.name} <small>({user.username})</small></b>
                        </div>
                </div>
                <div className="users-admin-view-links">
                    <p>Profile image uses gravatar <a href="https://en.gravatar.com/">Change Avatar</a></p>
                </div>
                <div className="users-admin-view-buttons">
                    <Button className="users-admin-view-button" icon labelPosition='left' color="orange" size="tiny" onClick={this.redirectToEditPage}>
                        <Icon name='pencil' />
                        Edit
                    </Button>
                    <Button className="users-admin-view-button" icon labelPosition='left' color="orange" size="tiny" onClick={this.deleteUser}>
                        <Icon name='remove' />
                        Delete User
                    </Button>
                    <Button className="users-admin-view-button" icon labelPosition='left' color="orange" size="tiny" onClick={this.redirectBack}>
                        <Icon name='users' />
                        Back to all Users
                    </Button>
                </div>
                <div className="users-admin-view-email-phone-div">
                    <b>Phone</b>
                    <p>{user.phone}</p>
                    <b>Email</b>
                    <p>{user.email}</p>
                </div>
            </div>
        );
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.user.stores.unlinked.find(element => parseInt(element.id) === parseInt(data.value));
        if(foundStore) {
            this.setState({pickedStore: foundStore});
        }
    };

    getStoresTable = () => {
        let user = this.state.user;
        return (
            <div>
                <div className="users-admin-view-table-div">
                    <table className="users-admin-view-table" align="center">
                        <thead>
                            <tr>
                                <th className="users-admin-view-table-header">Name</th>
                                <th className="users-admin-view-table-header">Email</th>
                                <th className="users-admin-view-table-header">Location</th>
                                <th className="users-admin-view-table-header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.renderUser && user && user.stores && user.stores.linked ?
                            user.stores.linked.map((store, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{store.title + ' (' + store.role + ')'}</td>
                                        <td>{store.email}</td>
                                        <td>{store.city + ', ' + store.state}</td>
                                        <td className="users-admin-view-table-actions-td">
                                            <Button.Group icon>
                                                <Button size='tiny' onClick={()=>this.redirectToStoreView(store.id)}>
                                                    <Icon name='eye' />
                                                </Button>
                                                <Button size='tiny' title="Unlink User from store" onClick={()=>this.unlinkFromStore(store.id)}>
                                                    <Icon name='unlink' />
                                                </Button>
                                            </Button.Group>
                                        </td>
                                    </tr>
                                )
                            })
                        :''
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className="users-admin-view-stores-card-actions">
                    { this.state.renderStoresSelect ?
                        <div>
                            <Select
                                text={this.state.pickedStore.title}
                                value={this.state.pickedStore.id}
                                options={this.state.storeOptions}
                                onChange={this.handleChangeStore}
                            />
                            {this.state.renderRolesSelect ?
                                <Select
                                    text={this.state.pickedRole.name}
                                    value={this.state.pickedRole.id}
                                    options={this.state.rolesOptions}
                                    onChange={this.handleChangeRole}
                                />
                                :''
                            }
                            <Button color="orange" size="mini" onClick={this.linkToStore}>
                                Link to Store
                            </Button>
                        </div>
                        : ''
                    }
                    <Button icon labelPosition='left' color="orange" size="mini" onClick={this.redirectToStoreEdit}>
                        <Icon name='plus' />
                        Store
                    </Button>
                </div>
            </div>
        )
    };

    getRolesTable = () => {
        let roles = this.state.user && this.state.user.roles ? this.state.user.roles : [];
        return (
            <div>
                <div className="users-admin-view-roles-table-div">
                    <table className="users-admin-view-roles-table" align="center">
                        <thead>
                        <tr>
                            <th className="users-admin-view-table-header">Name</th>
                            <th className="users-admin-view-table-header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        { roles && roles.length > 0 ?
                            roles.map((role, index) => {
                                if(role.id) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {role.name}
                                            </td>
                                            <td>
                                                <Button.Group icon>
                                                    <Button size='tiny' title="Unlink Role from User" onClick={()=>this.unlinkRole(role.id)}>
                                                        <Icon name='unlink' />
                                                    </Button>
                                                </Button.Group>
                                            </td>
                                        </tr>
                                    )
                                }
                                else {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {role.name}
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    )
                                }

                            })
                            :
                            <tr>
                                <td>{this.state.user.username} has no roles</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className="users-admin-view-stores-card-actions">
                    { this.state.renderBaseRolesSelect ?
                        <div>
                            <Select
                                text={this.state.pickedBaseRole.name}
                                value={this.state.pickedBaseRole.id}
                                options={this.state.baseRolesOptions}
                                onChange={this.handleChangeBaseRole}
                            />
                            <Button color="orange" size="mini" onClick={this.linkToUser}>
                                Link to User
                            </Button>
                        </div>
                        : ''
                    }
                </div>
            </div>
        )
    };

    render() {
        return (
            <div className="users-admin-view-root">
                {this.state.renderUser ? this.getUserHeaderDescriptions() : ''}
                <div className="users-admin-view-tables-div">
                    <div>
                        <div className="users-admin-view-stores-card-div">
                            <Card className="users-admin-view-stores-card" fluid>
                                <Card.Content className="users-admin-view-stores-card-header" header={<h3>Stores<small> (Create, Link, Unlink)</small></h3>} />
                                <Card.Content className="users-admin-view-stores-card-description" description={this.getStoresTable()} />
                            </Card>
                        </div>
                        <div className="users-admin-view-roles-card-div">
                            <Card className="users-admin-view-roles-card" fluid>
                                <Card.Content className="documents-card-header" header={<h3>Roles<small> (Create, Link, Unlink)</small></h3>} />
                                <Card.Content className="users-admin-view-roles-card-description" description={this.getRolesTable()} />
                            </Card>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </div>
        )
    }

}

export default UsersAdminView;