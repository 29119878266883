import React from 'react';
import {NotificationManager, NotificationContainer} from "react-notifications";
import '../../assets/css/pages/managment/address-book.css';
import {Button, Grid, Header, Icon, Modal, Select} from "semantic-ui-react";
import Request from "../../Request";
import Title from 'components/Title';

class AddressBook extends React.Component
{

    state = {
        addressBook: [],
        renderAddressBook: false,
        store: {},
        stores: [],
        options: [],
        openModal: false,
        changeStore: false,
        renderStoreInfos: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        confirmationModalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getStoresData();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    getAddressBooks = () => {
        new Promise((resolve, reject) => {
            let path = '/stores/' + this.state.store.store.id + '/address-book';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let books = JSON.parse(response);
                if(books && books.success === true && books.data) {
                    let stateAddressBook = books.data;
                    this.setState({addressBook: stateAddressBook}, () => {
                        this.setState({renderAddressBook: true});
                    });
                }
                else {
                    this.setState({addressBook: []}, () => {
                        this.setState({renderAddressBook: true})
                    });
                }
            })
            .catch(error => {
                this.setState({addressBook: []}, () => {
                    this.setState({renderAddressBook: true})
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Address Book', 'Error');
                }
            });
    };

    deleteAddress = (id) => {
        this.setState({confirmationModalOpen: false});
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/address-book/'+ id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully removed address', 'Success');
                        this.getAddressBooks();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove address', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove address', 'Error');
        }
    };

    // STORES
    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    let activeStore = localStorage.getItem('activeStore');
                    if(activeStore && typeof activeStore !== 'undefined') {
                        activeStore = {
                            franchisees: [],
                            managers: [],
                            store: JSON.parse(activeStore)
                        };
                    } else {
                        activeStore = stores.data[0]
                    }
                    this.setState({stores: stores.data, store: activeStore}, () => {
                        this.transformStoresToOptions();
                        this.setState({renderStoreInfos: true});
                        this.getAddressBooks();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({stores: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({renderStoreInfos: true});
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({options: options})
    };

    handleStoresInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    { this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                            None
                                        </span>
                                    }
                                    <h5>Franchisees</h5>
                                    { this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                            None
                                        </span>
                                    }
                                </div>
                                :''
                            }
                        </Grid.Column> :''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 :16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.setState({changeStore:false, store: foundStore}, () => {
                this.getAddressBooks();
            });
            localStorage.setItem('activeStore', JSON.stringify(foundStore.store));
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br/>
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username} onClick={()=>this.handleRedirectToProfile(manager.username)}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    :''

                                }
                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    getStoresHtml = () => {
        return (
            <div className="key-release-stores">
                <div>
                    <div className="store-number">
                        <Select
                            text={this.state.store.store.title}
                            value={this.state.store.store.id}
                            options={this.state.options}
                            onChange={this.handleChangeStore}/>
                    </div>
                </div>
            </div>
        );
    };

    // STORES END

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/management/address-book/' + id;
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            confirmationModalOpen: false
        });
    };

    removeAddressConfirmation = (id) => {
        this.setState({removeId: id, confirmationModalOpen:true});
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if(removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.confirmationModalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Address' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Address?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={()=>this.deleteAddress(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    getAddressBookTable = () => {
        let addressBook = this.state.addressBook;
        return (
            <table className="address-book-table" align="center">
                <thead>
                <tr>
                    <th className="address-book-table-header">Name</th>
                    <th className="address-book-table-header">Contact Name</th>
                    <th className="address-book-table-header">Email</th>
                    <th className="address-book-table-header">Phone</th>
                    <th className="address-book-table-header-empty"></th>
                </tr>
                </thead>
                <tbody>
                { addressBook && Array.isArray(addressBook) ?
                    addressBook.map((address, index) => {
                        return (
                            <tr key={'row-' + index}>
                                <td className="address-book-table-td">
                                    <a href={'/management/address-book/' + address.id}><b>{address.name || ''}</b></a>
                                </td>
                                <td className="address-book-table-td">
                                    {address.contactName}
                                </td>
                                <td className="address-book-table-td">
                                    {address.email}
                                </td>
                                <td className="address-book-table-td">
                                    {address.phone}
                                </td>
                                <td className="address-book-table-actions-td">
                                    <Button.Group icon>
                                        <Button size='tiny' onClick={(e)=>this.redirectToEditPage(address.id)}>
                                            <Icon name='pencil' />
                                        </Button>
                                        <Button size='tiny' onClick={(e)=>this.removeAddressConfirmation(address.id)}>
                                            <Icon name='times' />
                                        </Button>
                                    </Button.Group>
                                </td>
                            </tr>
                        );
                    })
                :''
                }
                </tbody>
            </table>
        );
    };

    render() {
        return (
            <div>
                {this.getStoresModalInfos()}
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                            <Title
                                paramsPageTitle="Address Book"
                            />
                        </Grid.Column>
                        <Grid.Column width={8} className="col-right">
                            {this.state.renderStoreInfos ? this.getStoresHtml() : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <div className="address-book-content">
                    <div className="address-book-table-div">
                        {this.state.renderAddressBook ? this.getAddressBookTable() : ''}
                    </div>
                    <div className="address-book-button-div">
                        <Button color="orange" onClick={(e)=>this.redirectToEditPage('new')}><Icon name='plus' />Add New</Button>
                    </div>
                </div>
                {this.getConfirmationModal()}
                <NotificationContainer/>
            </div>
        );
    }
}

export default AddressBook;