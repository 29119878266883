import React from 'react';
import {Grid, Header, Segment, Button, Form, Modal} from 'semantic-ui-react'
import './../assets/css/pages/forgotPassword.css';
import Cookies from "universal-cookie";
import {NotificationManager} from "react-notifications";
const requestPromise = require('request-promise');

class Login extends React.Component
{
    state = {
        email: '',
        modalOpen: false,
        message: ''
    };

    constructor() {
        super();
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if (decodedCookie.token) {
            this.redirectToDashboard();
        }
    }

    UNSAFE_componentWillMount() {
        document.body.classList.add('fp-content');
    }

    handleInputChanged = (event) => {
        this.setState({email: event.target.value});
    };

    handleResetPassword = () => {
        let params = {
            email: this.state.email
        };
        let url = process.env.REACT_APP_URL;
        let path = '/users/forgot-password';
        let options = {
            method: 'POST',
            uri: url + path,
            body: JSON.stringify(params)
        };

        requestPromise(options)
            .then(response => {
                response = JSON.parse(response);
                if( response.success === true && response.message) {
                    this.setState({modalOpen:true, message: response.message});
                }
            })
            .catch(error => {
                if(error && error.message && error.message.length < 50) {
                    NotificationManager.error(error.message, 'Error');
                }
                else {
                    NotificationManager.error('Password reset failed', 'Error');
                }
            });
    };

    getConfirmationModal = () => {
        return (
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleCloseModal}
                size='small'
            >
                <Header content='Password reset' />
                <Modal.Content>
                    <h3>{this.state.message}</h3>
                </Modal.Content>
                <Modal.Actions>
                </Modal.Actions>
            </Modal>
        )
    };

    handleCloseModal = () => {
        this.setState({
            modalOpen: false
        });
    };

    redirectToDashboard ()
    {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url;
    }

    render() {
        return (
            <div className='fp-content'>
                <Grid columns={2} centered stackable verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h1' className='logo' />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form size='large'>
                                <Segment>
                                    <h1 className='fp-h1'>FORGOT PASSWORD</h1>
                                    <Form.Input className='fp-input' size='large' fluid icon='user' iconPosition='left' placeholder='Email' value={this.state.email} onChange={this.handleInputChanged} />
                                    <Button color='light-grey' fluid size='large' onClick={this.handleResetPassword}>Send Password Reset</Button>
                                </Segment>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.getConfirmationModal()}
            </div>
        );
    }
}

export default Login;
