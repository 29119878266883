import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Grid, Select, Modal, Button } from 'semantic-ui-react'
import '../assets/css/components/CheckListFilters/checkListFilters.css'
import '../assets/css/components/CheckListFilters/filtersModalDeviceSpecific.css'
import {NotificationContainer, NotificationManager} from "react-notifications";
import Title from "../components/Title";
import Request from "../Request";

class CheckListFilters extends React.Component
{

    state = {
        startDate: new Date(),
        changeStore: false,
        initialStoreRequest: true,
        renderStoreInfos: false,
        store: {},
        stores: [],
        options: [],
        paramsStoreId: null,
        closePopup: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360 // for the popup position
    };

    constructor(props) {
        super(props);
        this.request = new Request();
    }

    componentDidMount() {
        if(this.state.initialStoreRequest) {
            this.setState({initialStoreRequest: false}, () => {
                this.getStoresData();
            });
        }

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    this.setState({stores: stores.data, store: stores.data[0]}, () => {
                        this.transformStoresToOptions();
                        this.props.onGetStores(stores.data[0].store);
                        this.setState({renderStoreInfos: true});
                        let activeStore = localStorage.getItem('activeStore');
                        if(activeStore && typeof activeStore !== 'undefined') {
                            activeStore = JSON.parse(activeStore);
                            this.handleChangeStore(null, {value: activeStore.id});
                        }
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                    this.props.onGetStores({});
                }
            })
            .catch(error => {
                this.setState({stores: {}}, ()=>{
                    this.props.onGetStores([]);
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({options: options})
    };

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.props.onChangeStore(foundStore.store);
            this.setState({changeStore:false, store: foundStore});
            localStorage.setItem('activeStore', JSON.stringify(foundStore.store));
        }
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoreInfos = () => {

        if (!this.props.showStoreInfo) {
            return;
        }
        
        return (
            <div className="store-info-content">
                { this.state.store && this.state.store.managers && this.state.store.franchisees && Array.isArray(this.state.store.managers) ?
                    <div className="info-row">
                        <span className="info-label">Managers</span>
                        { this.state.store.managers && this.state.store.managers.length > 0 ?
                            <span className="info-value">
                                {this.state.store.managers.map((manager, index)=>{
                                    return (
                                        <a href={'/profile/' + manager.username} key={index}>
                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                            {index + 1 !== this.state.store.managers.length ? ', ' : '' }
                                        </a>
                                    )
                                })}
                            </span>
                            :
                            <span className="info-value">
                                None
                            </span>
                        }
                    </div>
                : '' }
                
                {/* <div className="info-row">
                    <span className="info-label">Franchisees</span>
                    { this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                        <span className="info-value">
                            {this.state.store.franchisees.map((franchise, index)=>{
                                return (
                                    <span key={index}>
                                        <a href={'/profile/' + franchise.username}>
                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                        </a>
                                        <p>{franchise.phone}</p>
                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                    </span>
                                )
                            })}
                        </span>
                        :
                        <span className="info-value">
                            None
                        </span>
                    }
                </div> */}
                    
                {this.state.store.store.title || this.state.store.store.street1 || this.state.store.store.street2 ?
                    <div className="info-row">
                        <span className="info-label">Address</span>
                        <span className="info-value">
                            {this.state.store.store.title} {this.state.store.store.street1} {this.state.store.store.street2}
                        </span>
                    </div>
                : ''}

                {/* { this.state.store.store.phone ? 
                    <div className="info-row">
                        <span className="info-label">Phone</span>
                        <span className="info-value">{this.state.store.store.phone}</span>
                    </div>
                : ''} */}

                { this.state.store.store.email ? 
                    <div className="info-row">
                        <span className="info-label">Email</span>
                        <span className="info-value">
                            {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                            {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        </span>
                    </div>
                : ''}

                {/* { this.state.store.store.fax ? 
                    <div className="info-row">
                        <span className="info-label">Fax</span>
                        <span className="info-value">{ this.state.store.store.fax }</span>
                    </div>
                : ''} */}
                

            </div>
        );
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    handleInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    getModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br/>
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    :''

                                }
                        </div>
                        :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    getDatePicker = () => {
        if (false === this.props.showDatePicker ) {
            return '';
        }

        return  (
            <div className="filters-datepicker">
                <SemanticDatepicker datePickerOnly={true} placeholder="YYYY-MM-DD" maxDate={this.props.maxDate || null} clearable={false} value={this.props.date} format="MMMM D, YYYY" onChange={this.props.onChangeDate} />
            </div>
        );
    }

    render() {

        return (
            <Grid className="filters" columns={2} stackable>
                {this.getModalInfos()}
                <Grid.Row>
                    <Grid.Column width={8} className="col-left">
                        <Title 
                            paramsPageTitle={this.props.pageTitle ?? ''}
                        />
                        {this.props.welcomeMsg?
                            <h2 dangerouslySetInnerHTML={{__html: this.props.welcomeMsg}} className='welcome-msg' ></h2>
                        : ''}
                        { this.getDatePicker() }
                    </Grid.Column>
                    <Grid.Column width={8} className="col-right">
                        <div className="filters-store-info">
                            <div>
                                <div className="store-number">
                                    <Select
                                        text={this.state.store.store?.title}
                                        value={this.state.store.store?.id}
                                        options={this.state.options}
                                        onChange={this.handleChangeStore}/>
                                </div>
                                <div className='store-info'>
                                    {this.state.renderStoreInfos ?
                                        this.getStoreInfos()
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <NotificationContainer/>
            </Grid>
        );
    }
}
export default CheckListFilters;