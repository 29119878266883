import React from "react";
import {Card, Icon, Image} from 'semantic-ui-react';
import './../assets/css/components/newOrdersCard.css';
import {NotificationManager} from "react-notifications";
import Request from "../Request";

class NewOrdersCard extends React.Component
{
    state = {
        storeId: null,
        product: null,
        productHistorys: [],
        renderTable: false,
        firstSort: true,
        sort: {
                item: {
                    sort: true,
                    type: 'ASC'
                },
                description: {
                    sort: false,
                    type: 'DESC'
                },
                cost: {
                    sort: false,
                    type: 'DESC'
                },
                retail: {
                    sort: false,
                    type: 'DESC'
                },
                um: {
                    sort: false,
                    type: 'DESC'
                }
        },
        sortItem: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.initializeProps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.storeId !== this.props.storeId) || (JSON.stringify(this.state.product) !== JSON.stringify(this.props.product))) {
            this.initializeProps();
        }
    }

    initializeProps = () => {
        if(this.props.product) {
            this.setState({product: this.props.product}, ()=> {
                this.setProductHistoryData();
                this.setState({renderTable: true});
            });
        }

        if(this.props.storeId) {
            this.setState({storeId: this.props.storeId});
        }
    };

    getProductHistory = (product) => {
        new Promise((resolve, reject) => {
            let storeId = this.state.storeId;
            let path = '/stores/'+ storeId +'/order-products/' + product.id;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let history = JSON.parse(response);
                if(history && history.success === true && history.data) {
                    let stateHistoryIndex = this.state.productHistorys.findIndex((history)=>{return history.id === product.id});
                    if(stateHistoryIndex !== -1) {
                        let productHistorys = this.state.productHistorys;
                        let stateHistory = productHistorys[stateHistoryIndex];
                        if(stateHistory) {
                            this.setShowHistoryDataFalse();
                            stateHistory.data = history.data;
                            stateHistory.hasData = true;
                            productHistorys[stateHistoryIndex] = stateHistory;
                            this.setState({productHistorys: productHistorys});
                        }
                    }
                }
                else {
                    this.setState({productHistorys: []});
                    NotificationManager.error('Could not get History of Products', 'Error');
                }
            })
            .catch(error => {
                this.setState({productHistorys: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get History of Products', 'Error');
                }
            });
    };

    setShowHistoryDataFalse = () => {
        let productHistorys = this.state.productHistorys;
        productHistorys.forEach((history)=> {
            history.hasData = false;
        });
        this.setState({productHistorys: productHistorys});
    };

    setProductHistoryData = () => {
        if(this.state.product.products && Array.isArray(this.state.product.products)) {
            let historys = this.state.productHistorys;
            this.state.product.products.forEach((product, index)=> {
                historys.push({
                    hasData: false,
                    id: product.id,
                    data: null,
                    inputValues: null
                });
            });
            this.setState({productHistorys: historys});
        }
    };

    fillHistoryInputs = (productId, historyId) => {
        let historyIndex = this.state.productHistorys.findIndex((history)=>{return history.id === productId});
        if(historyIndex !== -1) {
            let productHistorys = this.state.productHistorys;
            let history = productHistorys[historyIndex];
            if(history.hasData && history.data !== null){
                let historyIdIndex = history.data.findIndex((history)=>{return history.id === historyId});
                let clickedHistory = history.data[historyIdIndex];
                history.inputValues = {
                    on_hand: clickedHistory.on_hand,
                    qty: clickedHistory.qty
                };
                productHistorys[historyIndex] = history;
                this.setState({productHistorys: productHistorys});
                this.props.handleChangeProduct(productId, history.inputValues, this.props.index);
            }
        }
    };

    onChageInput = (event, product, field) => {
        let productHistorys = this.state.productHistorys;
        let historyIndex = productHistorys.findIndex((history)=>{return history.id === product.id});
        let history = productHistorys[historyIndex];
        if(history.inputValues !== null) {
            history.inputValues[field] = event.target.value;
        }
        else {
            history.inputValues = {};
            history.inputValues[field] = event.target.value;
        }
        if(field === 'on_hand') {
            let tempProduct = history.data[history.data.length -1];
            if(tempProduct) {
                let value = event.target.value && !isNaN(parseInt(event.target.value)) ? event.target.value : 0;
                let usage = parseInt(tempProduct.on_hand) + parseInt(tempProduct.qty) - value;
                history.inputValues.usage = parseInt(usage);
            }
        }
        productHistorys[historyIndex] = history;
        this.setState({productHistorys: productHistorys}, () => {
            this.props.handleChangeProduct(product.id, history.inputValues, this.props.index);
        });
    };

    getProductRow = (index, product) => {
        let historyIndex = this.state.productHistorys.findIndex((history)=>{return history.id === product.id});
        let onHand = null;
        let qty = null;
        let usage = null;
        if(historyIndex !== -1) {
            let history = this.state.productHistorys[historyIndex];
            if(history.inputValues !== null){
                onHand = history.inputValues.on_hand;
                qty = history.inputValues.qty;
                usage = history.inputValues.usage;
            }
        }
        return (
            <tr key={index + '-' + this.state.product.category}>
                <td>
                    { product.file ?
                        <Image avatar size="massive" src={product.file || ''} />
                        : ''
                    }
                </td>
                <td colSpan={1} className="product-cell">{product.item_no}</td>
                <td colSpan={1} className="product-cell">{product.description}</td>
                <td colSpan={1} className="product-cell">{product.cost}</td>
                <td colSpan={1} className="product-cell">{product.retail}</td>
                <td colSpan={1} className="product-cell">
                    <input type="text" className="cell"
                           value={(onHand || onHand === '0.00') ? onHand : ''}
                           onClick={()=>this.getProductHistory(product)}
                           onChange={(e)=>this.onChageInput(e, product, 'on_hand')}
                    />
                </td>
                <td colSpan={1} className="product-cell">{usage || ''}</td>
                <td colSpan={1} className="product-cell">
                    <input type="text" className="cell"
                           value={qty || ''}
                           onClick={()=>this.getProductHistory(product)}
                           onChange={(e)=>this.onChageInput(e, product, 'qty')}
                    />
                </td>
                <td colSpan={1} className="product-cell">{product.um}</td>
            </tr>
        );
    };

    getProductHistoryExtraRow = (index, product) => {
        let historyIndex = this.state.productHistorys.findIndex((history)=>{return history.id === product.id});
        if(historyIndex !== -1) {
            let history = this.state.productHistorys[historyIndex];
            if(history.hasData && history.data !== null){
                return (
                    <tr key={index + '-' + this.state.product.category} className="new-orders-extra-history-row">
                        <td><Icon name="clock outline"/>Order History</td>
                        <td colSpan={8}>
                            { history.data && Array.isArray(history.data) ?
                                history.data.map((data, index) => {
                                    return (
                                        <div className="new-orders-extra-history-cell" onClick={()=>this.fillHistoryInputs(product.id, data.id)} key={index + 'history-cell'}>
                                            <b>{data.date}</b>
                                            <p>O/H: {data.on_hand || '0.00'}, Qty: {data.qty}</p>
                                        </div>
                                    );
                                })
                                : ''
                            }
                        </td>
                    </tr>
                );
            }
        }
        return '';

    };

    getUnsortedObject = () => {
        let sort = {
            item: {
                sort: false,
                type: 'DESC'
            },
            description: {
                sort: false,
                type: 'DESC'
            },
            cost: {
                sort: false,
                type: 'DESC'
            },
            retail: {
                sort: false,
                type: 'DESC'
            },
            um: {
                sort: false,
                type: 'DESC'
            }
        };

        if(this.state.firstSort) {
            sort.item = {
                sort: true,
                type: 'ASC'
            };
            this.setState({firstSort: false})
        }

        this.setState({sort: sort});
        return sort;
    };

    sortTable = (header) => {
        let sortItem = this.state.sortItem;
        let sort;
        if(sortItem !== header) {
            sort = this.getUnsortedObject();
        }
        else {
            sort = this.state.sort;
        }
        let sortHeader = sort[header];
        sortHeader.sort = true;
        sortHeader.type = sortHeader.type === 'ASC' ? 'DESC' : 'ASC';
        sort[header] = sortHeader;
        this.setState({sort : sort, renderTable: false, sortItem: header});
        this.sortTableAsc(header, sortHeader.type);
    };

    sortTableAsc = (header, type) => {
        let orders = this.state.product;
        if(header === 'item') {
            if(type === 'ASC') {
                orders.products.sort(
                    (a,b) => (a.item_no > b.item_no) ? 1 : ((b.item_no > a.item_no) ? -1 : 0)
                )
            }
            else {
                orders.products.sort(
                    (a,b) => (a.item_no < b.item_no) ? 1 : ((b.item_no < a.item_no) ? -1 : 0)
                )
            }
        }
        else if(header === 'description') {
            if(type === 'ASC') {
                orders.products.sort(
                    (a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0)
                )
            }
            else {
                orders.products.sort(
                    (a,b) => (a.description < b.description) ? 1 : ((b.description < a.description) ? -1 : 0)
                )
            }
        }
        else if(header === 'um') {
            if(type === 'ASC') {
                orders.products.sort(
                    (a,b) => (a.um > b.um) ? 1 : ((b.um > a.um) ? -1 : 0)
                )
            }
            else {
                orders.products.sort(
                    (a,b) => (a.um < b.um) ? 1 : ((b.um < a.um) ? -1 : 0)
                )
            }
        }
        else if(header === 'cost') {
            if(type === 'ASC') {
                orders.products.sort(
                    (a,b) => (parseFloat(a.cost) > parseFloat(b.cost)) ? 1 : ((parseFloat(b.cost) > parseFloat(a.cost)) ? -1 : 0)
                )
            }
            else {
                orders.products.sort(
                    (a,b) => (parseFloat(a.cost) < parseFloat(b.cost)) ? 1 : ((parseFloat(b.cost) < parseFloat(a.cost)) ? -1 : 0)
                )
            }
        }
        else if(header === 'retail') {
            if(type === 'ASC') {
                orders.products.sort(
                    (a,b) => (parseFloat(a.retail) > parseFloat(b.retail)) ? 1 : ((parseFloat(b.retail) > parseFloat(a.retail)) ? -1 : 0)
                )
            }
            else {
                orders.products.sort(
                    (a,b) => (parseFloat(a.retail) < parseFloat(b.retail)) ? 1 : ((parseFloat(b.retail) < parseFloat(a.retail)) ? -1 : 0)
                )
            }
        }
        this.setState({product: orders, renderTable: true})
    };

    getProductCardContent = () => {
        if(this.state.renderTable === false) {
            return '';
        }
        let sort = this.state.sort;
        return (
            <table className="product-table sortable" align="center">
                <thead>
                <tr>
                    <th className="product-table-header">Image</th>
                    <th className="product-table-header" onClick={(e) => this.sortTable('item')}>Item
                        {sort.item.sort ?
                        <Icon size='tiny' name={sort.item.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                        :''}
                    </th>
                    <th className="product-table-header" onClick={(e) => this.sortTable('description')}>Description
                        {sort.description.sort ?
                            <Icon size='tiny' name={sort.description.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                            :''}
                    </th>
                    <th className="product-table-header" onClick={(e) => this.sortTable('cost')}>Cost
                        {sort.cost.sort ?
                            <Icon size='tiny' name={sort.cost.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                            :''}
                    </th>
                    <th className="product-table-header" onClick={(e) => this.sortTable('retail')}>Retail
                        {sort.retail.sort ?
                            <Icon size='tiny' name={sort.retail.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                            :''}
                    </th>
                    <th className="product-table-header">O/H</th>
                    <th className="product-table-header">USAGE</th>
                    <th className="product-table-header">QTY</th>
                    <th className="product-table-header" onClick={(e) => this.sortTable('um')}>U/M
                        {sort.um.sort ?
                            <Icon size='tiny' name={sort.um.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                            :''}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.product.products.map((product, index) => {
                    return [
                        this.getProductRow(index, product),
                        this.getProductHistoryExtraRow(index, product)
                    ];})
                }
                </tbody>
            </table>
        );
    };

    render() {
        return(
            <div>
                {this.state.product !== null && this.state.storeId !== null ?
                    <Card className="new-orders-product-card" fluid>
                        <Card.Content className="product-card-header" header={this.state.product.category.name} />
                        <Card.Content className="product-card-description" description={this.getProductCardContent()} />
                    </Card>
                    :''}
            </div>
        )
    }
}

export default NewOrdersCard;