import React from 'react';
import CheckListFilters from "../../components/CheckListFilters";
import './../../assets/css/pages/check-lists/cashCountSheet.css';
import {Grid, Label, Form, TextArea, Button, Placeholder} from "semantic-ui-react";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';

class CashCountSheet extends React.Component
{
    state = {
        date: new Date(),
        store: {},
        totals: {
            bar_safe: {
                open: 0,
                mid: 0,
                bclose: 0,
                eclose: 0
            },
            starting_bank: {
                open: 0,
                mid: 0,
                close: 0
            },
            cashiers: {
                deposit: 0,
                discrepancy: 0
            }
        },
        defaultCashCountObject: {
            leads: {
                open_lead: "",
                mid_lead: "",
                close_lead: "",
                notes: ""
            },
            bar_safe: {
                open: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                },
                mid: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                },
                bclose: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                },
                eclose: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                }
            },
            starting_bank: {
                open: {
                    bank1: 0,
                    bank2: 0,
                    bank3: 0,
                    bank4: 0
                },
                mid: {
                    bank1: 0,
                    bank2: 0,
                    bank3: 0,
                    bank4: 0
                },
                close: {
                    bank1: 0,
                    bank2: 0,
                    bank3: 0,
                    bank4: 0
                }
            },
            cashier: {
                bank: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                },
                expected: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                },
                paid: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                },
                actual: {
                    b100: 0,
                    b50: 0,
                    b20: 0,
                    b10: 0,
                    b5: 0,
                    b1: 0,
                    c50: 0,
                    c25: 0,
                    c10: 0,
                    c5: 0,
                    c1: 0
                }
            },
            cashier_names: {
                name: {
                    b100: "",
                    b50: "",
                    b20: "",
                    b10: "",
                    b5: "",
                    b1: "",
                    c50: "",
                    c25: "",
                    c10: "",
                    c5: "",
                    c1: ""
                },
                cinit: {
                    b100: "",
                    b50: "",
                    b20: "",
                    b10: "",
                    b5: "",
                    b1: "",
                    c50: "",
                    c25: "",
                    c10: "",
                    c5: "",
                    c1: ""
                },
                linit: {
                    b100: "",
                    b50: "",
                    b20: "",
                    b10: "",
                    b5: "",
                    b1: "",
                    c50: "",
                    c25: "",
                    c10: "",
                    c5: "",
                    c1: ""
                }
            }
        },
        cashCount: {},
        discrepancys: {
            b100: 0,
            b50: 0,
            b20: 0,
            b10: 0,
            b5: 0,
            b1: 0,
            c50: 0,
            c25: 0,
            c10: 0,
            c5: 0,
            c1: 0
        },
        renderContent: false,
        urlParams: {
            store: null,
            date: null
        }
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.initializeUrlParams();
        this.setState({renderContent:false})
    }

    initializeUrlParams = () => {
        let stateParams = this.state.urlParams;
        if(this.props.match.params) {
            if(this.props.match.params.store) {
                stateParams.store = this.props.match.params.store;
            }
            if(this.props.match.params.date) {
                let date = new Date(this.props.match.params.date);
                if (this.isValidDate(date)) {
                    stateParams.date = date;
                } else {
                    stateParams.date = new Date();
                }
            }
            else {
                stateParams.date = new Date();
            }
            this.setState({urlParams: stateParams})
        }
        else {
            this.setState({urlParams: {store: null, date: new Date()}})
        }
    };

    isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    };

    formatDate = (date) => {
        if(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
        return '';
    };

    // DATA REQUESTS
    getCashCounts = () => {
        if(this.state.store && this.state.store.id) {
            new Promise((resolve, reject) => {
                let path = '/cash-count/' + this.state.store.id + '/' + this.formatDate(this.state.date);
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let cashCountData = JSON.parse(response);
                    if(cashCountData && cashCountData.success === true && cashCountData.data) {
                        cashCountData = cashCountData.data;
                        let stateData = this.state.cashCount;
                        let defaultCashCountObject = this.state.defaultCashCountObject;
                        ['leads', 'bar_safe', 'starting_bank', 'cashier', 'cashier_names'].forEach((item)=>{
                            if(
                                cashCountData[item] &&
                                (Array.isArray(cashCountData[item])? cashCountData[item].length > 0 : true) &&
                                (cashCountData[item] instanceof Object ? Object.entries(cashCountData[item]).length > 0 : true)
                            ) {
                                stateData[item] = cashCountData[item];
                            }
                            else {
                                stateData[item] = defaultCashCountObject[item];
                            }
                        });

                        this.setState({cashCount: stateData}, ()=>{
                            window.history.replaceState(
                                {},
                                null,
                                '/check-lists/cash-count/'+ this.state.urlParams.store +'/' + this.formatDate(this.state.urlParams.date)
                            );
                            this.calculateAllTotals();
                            this.setState({renderContent:true})
                        })
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Cash Counts', 'Error');
                    }
                    this.setState({renderContent:true})
                });
        }
    };

    onGetStores = (store) => {
        if(store && store.id) {
            let urlParams = this.state.urlParams;
            if(store.id !== parseInt(urlParams.store)) {
                urlParams.store = store.id;
            }
            this.setState({store:store, urlParams: urlParams, renderContent: false}, () => {
                this.getCashCounts();
            });
        }
    };

    saveCashCount = () => {
        new Promise((resolve, reject) => {
            let path = '/cash-count/'+ this.state.store.id +'/' + this.formatDate(this.state.date);
            let params = {cash_count: this.state.cashCount};
            this.request.post(path, params, resolve, reject);
        })
            .then(response => {
                this.setState({renderContent: false});
                this.getCashCounts();
                NotificationManager.success('Successfully updated Cash Counts', 'Success');
            })
            .catch(error => {
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not update Cash Counts', 'Error');
                }
            });
    };
    // END DATA REQUESTS

    // GET HTML
    getShiftLeadInputs = () => {
        return (
            <Grid className="shiftLeadsCard" columns={2} stackable>
                <Grid.Row >
                    <Grid.Column width={2}>
                    </Grid.Column>
                    <Grid.Column width={4} className="shiftLeadsCardRow">
                        <Form>
                            <Form.Field>
                                <Label className="leads-input-label" pointing='below'>Opening Shift Lead</Label>
                                <input
                                    className="leads-input"
                                    type='text'
                                    value={this.state.cashCount.leads.open_lead || ''}
                                    placeholder="Enter lead"
                                    onChange={(e)=>this.handleChangeLead(e, 'open_lead')}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={4} className="shiftLeadsCardRow">
                        <Form>
                            <Form.Field>
                                <Label className="leads-input-label" pointing='below'>Mid Shift Lead</Label>
                                <input
                                    className="leads-input"
                                    type='text'
                                    value={this.state.cashCount.leads.mid_lead || ''}
                                    placeholder="Enter lead"
                                    onChange={(e)=>this.handleChangeLead(e, 'mid_lead')}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={4} className="shiftLeadsCardRow">
                        <Form>
                            <Form.Field>
                                <Label className="leads-input-label" pointing='below'>Closing Shift Lead</Label>
                                <input
                                    className="leads-input"
                                    type='text'
                                    value={this.state.cashCount.leads.close_lead || ''}
                                    placeholder="Enter lead"
                                    onChange={(e)=>this.handleChangeLead(e, 'close_lead')}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };
    // END GET HTML

    // HANDLE CHANGES
    handleChangeDate = (event, date) => {
        let urlParams = this.state.urlParams;
        if(date.value !== urlParams.date) {
            urlParams.date = date.value;
        }
        this.setState({date: date.value, urlParams: urlParams, renderContent: false}, () => {
            this.getCashCounts();
        });
    };

    handleChangeStore = (store) => {
        let urlParams = this.state.urlParams;
        if(store.id !== parseInt(urlParams.store)) {
            urlParams.store = store.id;
        }
        this.setState({store: store, urlParams: urlParams, renderContent: false}, () => {
            this.getCashCounts();
        });
    };

    handleChangeLead = (event, lead) =>{
        if(event.target.value && event.target.value.trim() !== '') {
            let cashCount = this.state.cashCount;
            cashCount.leads[lead] = event.target.value;
            this.setState({cashCount:cashCount});
        }
    };

    handleChangeNote = (event) => {
        let cashCount = this.state.cashCount;
        cashCount.leads.notes = event.target.value;
        this.setState({cashCount: cashCount});
    };

    handleChangeBarSafeInput = (event, shift, billCoin, onChange) => {
        let cashCount = this.state.cashCount;
        if(!onChange) {
            cashCount.bar_safe[shift][billCoin] = !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0;
        }
        else {
            cashCount.bar_safe[shift][billCoin] = event.target.value;
        }
        this.setState({cashCount: cashCount}, ()=> {
            if(!onChange) {
                this.calculateBarSafeTotal(shift);
            }
        })
    };

    handleChangeStartingBanksInput = (event, shift, bank, onChange) => {
        let cashCount = this.state.cashCount;
        if(!onChange) {
            cashCount.starting_bank[shift][bank] = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;
        }
        else {
            cashCount.starting_bank[shift][bank] = event.target.value;
        }

        this.setState({cashCount: cashCount}, ()=> {
            if(!onChange) {
                this.calculateStartingBanksTotal(shift);
            }
        })
    };

    handleChangeCashiersNameInput = (event, billCoin) => {
        let cashCount = this.state.cashCount;
        cashCount.cashier_names.name[billCoin] = event.target.value;

        this.setState({cashCount: cashCount});
    };

    handleChangeCashiersCinitInput = (event, billCoin) => {
        let cashCount = this.state.cashCount;
        cashCount.cashier_names.cinit[billCoin] = event.target.value;

        this.setState({cashCount: cashCount});
    };

    handleChangeCashiersLinitInput = (event, billCoin) => {
        let cashCount = this.state.cashCount;
        cashCount.cashier_names.linit[billCoin] = event.target.value;

        this.setState({cashCount: cashCount});
    };

    handleChangeCashiersBankInput = (event, billCoin, onChange) => {
        let cashCount = this.state.cashCount;
        if(!onChange) {
            cashCount.cashier.bank[billCoin] = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;
        }
        else {
            cashCount.cashier.bank[billCoin] = event.target.value;
        }

        this.setState({cashCount: cashCount});
    };

    handleChangeCashiersExpectedInput = (event, billCoin, onChange) => {
        let cashCount = this.state.cashCount;
        if(!onChange) {
            cashCount.cashier.expected[billCoin] = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;
        }
        else {
            cashCount.cashier.expected[billCoin] = event.target.value;
        }

        this.setState({cashCount: cashCount}, ()=> {
            if(!onChange) {
                this.calculateDiscrepancys(billCoin);
                this.calculateCashiersTotals();
            }
        })
    };

    handleChangeCashiersPaidInput = (event, billCoin, onChange) => {
        let cashCount = this.state.cashCount;
        if(!onChange) {
            cashCount.cashier.paid[billCoin] = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;
        }
        else {
            cashCount.cashier.paid[billCoin] = event.target.value;
        }

        this.setState({cashCount: cashCount}, ()=> {
            if(!onChange) {
                this.calculateDiscrepancys(billCoin);
                this.calculateCashiersTotals();
            }
        })
    };

    handleChangeCashiersActualInput = (event, billCoin, onChange) => {
        let cashCount = this.state.cashCount;
        if(!onChange) {
            cashCount.cashier.actual[billCoin] = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;
        }
        else {
            cashCount.cashier.actual[billCoin] = event.target.value;
        }

        this.setState({cashCount: cashCount}, ()=> {
            if(!onChange) {
                this.calculateDiscrepancys(billCoin);
                this.calculateCashiersTotals();
            }
        })
    };
    // END HANDLE CHANGES

    // TOTALS
    calculateBarSafeTotal = (shift) => {
        let cashCount = this.state.cashCount.bar_safe;
        let totals = this.state.totals;
        let billsCointArray = ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'];
        let total = 0;
        billsCointArray.forEach((billCoin) =>{
            let value = cashCount[shift][billCoin];
            if(typeof value === 'string' && !isNaN(parseInt(value))) {
                value = parseInt(value);
            }
            else if(typeof value !== 'number') {
                value = 0;
            }
            total += value;
        });
        totals.bar_safe[shift] = !isNaN(parseInt(total)) ? parseInt(total) : 0;

        this.setState({totals: totals});
    };

    calculateStartingBanksTotal = (shift) => {
        let startingBank = this.state.cashCount.starting_bank;
        let totals = this.state.totals;
        let banksArray = ['bank1','bank2','bank3','bank4'];
        let total = 0;
        banksArray.forEach((bank) =>{
            let value = startingBank[shift][bank];
            if(typeof value === 'string' && !isNaN(parseFloat(value))) {
                value = parseFloat(value);
            }
            else if(typeof value !== 'number') {
                value = 0;
            }
            total += value;
        });
        totals.starting_bank[shift] = !isNaN(parseFloat(total)) ? parseFloat(total) : 0.00;

        this.setState({totals: totals});
    };

    calculateDiscrepancys = (billCoin) => {
        let discrepancys = this.state.discrepancys;
        let expected = !isNaN(parseFloat(this.state.cashCount.cashier.expected[billCoin])) ?
            parseFloat(this.state.cashCount.cashier.expected[billCoin]) : 0;
        let actual = !isNaN(parseFloat(this.state.cashCount.cashier.actual[billCoin])) ?
            parseFloat(this.state.cashCount.cashier.actual[billCoin]) : 0;
        let paid = !isNaN(parseFloat(this.state.cashCount.cashier.paid[billCoin])) ?
            parseFloat(this.state.cashCount.cashier.paid[billCoin]) : 0;
        discrepancys[billCoin] = actual + paid - expected;

        this.setState({discrepancys: discrepancys});
    };

    calculateCashiersTotals = () => {
        let deposit = this.state.cashCount.cashier.actual;
        let discrepancys = this.state.discrepancys;
        let totals = this.state.totals;
        let billsCointArray = ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'];
        let depositTotal = 0;
        let discrepancyTotal = 0;
        billsCointArray.forEach((billCoin) =>{
            let discrepancyValue = discrepancys[billCoin];
            let depositValue = deposit[billCoin];
            if(typeof discrepancyValue === 'string' && !isNaN(parseFloat(discrepancyValue))) {
                discrepancyValue = parseFloat(discrepancyValue);
            }
            else if(typeof discrepancyValue !== 'number') {
                discrepancyValue = 0;
            }
            if(typeof depositValue === 'string' && !isNaN(parseFloat(depositValue))) {
                depositValue = parseFloat(depositValue);
            }
            else if(typeof depositValue !== 'number') {
                depositValue = 0;
            }
            depositTotal += depositValue;
            discrepancyTotal += discrepancyValue;
        });
        totals.cashiers.discrepancy = !isNaN(parseFloat(discrepancyTotal)) ? parseFloat(discrepancyTotal) : 0;
        totals.cashiers.deposit = !isNaN(parseFloat(depositTotal)) ? parseFloat(depositTotal) : 0;

        this.setState({totals: totals});
    };

    calculateAllTotals = () => {
        ['open', 'mid', 'bclose', 'eclose'].forEach((shift)=>{
            this.calculateBarSafeTotal(shift);
        });
        ['open', 'mid', 'close'].forEach((shift)=>{
            this.calculateStartingBanksTotal(shift);
        });
        ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].forEach((billCoin)=>{
            this.calculateDiscrepancys(billCoin);
        });
        this.calculateCashiersTotals();
    };
    // END TOTALS

    // TABLES
    getBarSafeTable = () => {
        return (
            <table className="bar-table" align="center">
                <thead>
                <tr>
                    <th className="bar-table-top-left-cell"/>
                    <th colSpan='6' className="bar-table-bills-coins">Bills</th>
                    <th colSpan='5' className="bar-table-bills-coins">Coins</th>
                    <th className="bar-table-top-right-cell" />
                </tr>
                <tr>
                    <th className="bar-table-top-left-second-cell" />
                    <th>$100</th>
                    <th>$50</th>
                    <th>$20</th>
                    <th>$10</th>
                    <th>$5</th>
                    <th>$1</th>
                    <th>$1/50¢</th>
                    <th>25¢</th>
                    <th>10¢</th>
                    <th>5¢</th>
                    <th>1¢</th>
                    <th colSpan='1' className="bar-table-total-cell">Total</th>
                </tr>
                </thead>
                <tbody>{ this.state.cashCount && this.state.cashCount.bar_safe ?
                    ['open', 'mid', 'bclose', 'eclose'].map((shift, index)=> {
                        let header = {
                            open: 'Open',
                            mid: 'Mid',
                            bclose: 'Begin Close',
                            eclose: 'End Close'
                        };
                        return (
                            <tr key={index}>
                                <td className="shift-cell">{header[shift]}</td>
                                {
                                    ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index)=>{
                                        return (
                                            <td key={shift + '-barsafe-' + billCoin}>
                                                <input type="text" className="cell"
                                                       onBlur={(e)=>this.handleChangeBarSafeInput(e, shift, billCoin)}
                                                       onChange={(e)=>this.handleChangeBarSafeInput(e, shift, billCoin, true)}
                                                       value={this.state.cashCount.bar_safe[shift][billCoin] || 0}
                                                />
                                            </td>
                                        );
                                    })
                                }
                                <td>
                                    <p>
                                        {this.state.totals.bar_safe[shift]}
                                    </p>
                                </td>
                            </tr>
                        );
                    })
                    : ''
                }
                </tbody>
            </table>
        );
    };

    getStartingBanksTable = () => {
        return (
            <table className="starting-bank-table" align="center">
                <thead>
                <tr>
                    <th className="bar-table-top-left-cell"/>
                    <th colSpan='1'>Starting Bank 1</th>
                    <th colSpan='1'>Starting Bank 2</th>
                    <th colSpan='1'>Starting Bank 3</th>
                    <th colSpan='1'>Starting Bank 4</th>
                    <th colSpan='1'>Total</th>
                </tr>
                </thead>
                <tbody>
                {this.state.cashCount && this.state.cashCount.starting_bank ?
                    ['open', 'mid', 'close'].map((shift, index)=> {
                        let header = {
                            open: 'Open',
                            mid: 'Mid',
                            close: 'Close'
                        };
                        return (
                            <tr key={index}>
                                <td className="shift-cell">{header[shift]}</td>
                                {
                                    ['bank1','bank2','bank3','bank4'].map((bank, index)=>{
                                        return (
                                            <td key={shift + '-startingbank-' + bank}>
                                                <input type="text" className="cell"
                                                       onBlur={(e)=>this.handleChangeStartingBanksInput(e, shift, bank)}
                                                       onChange={(e)=>this.handleChangeStartingBanksInput(e, shift, bank, true)}
                                                       value={this.state.cashCount.starting_bank[shift][bank] || 0}
                                                />
                                            </td>
                                        );
                                    })
                                }
                                <td>
                                    <p>
                                        {this.state.totals.starting_bank[shift]}
                                    </p>
                                </td>
                            </tr>
                        );
                    }) : ''
                }
                </tbody>
            </table>
        );
    };

    getCashiersTable = () => {
        return (
            <table className="cashier-table" align="center">
                <thead>
                <tr>
                    <th colSpan='12'>Cashier</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="cashier-descripiton-header-cell">Name</td>
                    {
                        this.state.cashCount.cashier_names
                        && this.state.cashCount.cashier_names.name ?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={'names-' + index}>
                                        <input type="text" className="cell"
                                               onChange={(e)=>this.handleChangeCashiersNameInput(e, billCoin)}
                                               value={this.state.cashCount.cashier_names.name[billCoin] || ''}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Starting Banks</td>
                    {
                        this.state.cashCount.cashier &&
                        this.state.cashCount.cashier.bank?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={'bank-' + index}>
                                        <input type="text" className="cell"
                                               onBlur={(e)=>this.handleChangeCashiersBankInput(e, billCoin)}
                                               onChange={(e)=>this.handleChangeCashiersBankInput(e, billCoin, true)}
                                               value={this.state.cashCount.cashier.bank[billCoin] || 0}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Post Cash Expected</td>
                    {
                        this.state.cashCount.cashier &&
                        this.state.cashCount.cashier.expected?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={'expected-' + index}>
                                        <input type="text" className="cell"
                                               onBlur={(e)=>this.handleChangeCashiersExpectedInput(e, billCoin)}
                                               onChange={(e)=>this.handleChangeCashiersExpectedInput(e, billCoin, true)}
                                               value={this.state.cashCount.cashier.expected[billCoin] || 0}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Paid Outs</td>
                    {
                        this.state.cashCount.cashier &&
                        this.state.cashCount.cashier.paid?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={'paid-' + index}>
                                        <input type="text" className="cell"
                                               onBlur={(e)=>this.handleChangeCashiersPaidInput(e, billCoin)}
                                               onChange={(e)=>this.handleChangeCashiersPaidInput(e, billCoin, true)}
                                               value={this.state.cashCount.cashier.paid[billCoin] || 0}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Actual Cash</td>
                    {
                        this.state.cashCount.cashier &&
                        this.state.cashCount.cashier.actual?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={'actual-' + index}>
                                        <input type="text" className="cell"
                                               onBlur={(e)=>this.handleChangeCashiersActualInput(e, billCoin)}
                                               onChange={(e)=>this.handleChangeCashiersActualInput(e, billCoin, true)}
                                               value={this.state.cashCount.cashier.actual[billCoin] || 0}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Discrepancy</td>
                    {
                        this.state.discrepancys ?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={'discrepancys-' + index} className="cashier-descripiton-cell">
                                        <p>{this.state.discrepancys[billCoin] || 0}</p>
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr>
                    <td colSpan='6'>Total Deposit: {this.state.totals.cashiers.deposit || 0}</td>
                    <td colSpan='6'>Total Discrepancy: {this.state.totals.cashiers.discrepancy || 0}</td>
                </tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr className="cashier-table-blank-row"></tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Cashier Initials</td>
                    {
                        this.state.cashCount.cashier_names &&
                        this.state.cashCount.cashier_names.cinit?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={index}>
                                        <input type="text" className="cell"
                                               onChange={(e)=>this.handleChangeCashiersCinitInput(e, billCoin)}
                                               value={this.state.cashCount.cashier_names.cinit[billCoin] || ''}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                <tr>
                    <td className="cashier-descripiton-header-cell">Shift Lead Initials</td>
                    {
                        this.state.cashCount.cashier_names &&
                        this.state.cashCount.cashier_names.linit?
                            ['b100', 'b50','b20','b10','b5','b1','c50','c25','c10','c5','c1'].map((billCoin, index) => {
                                return (
                                    <td key={index}>
                                        <input type="text" className="cell"
                                               onChange={(e)=>this.handleChangeCashiersLinitInput(e, billCoin)}
                                               value={this.state.cashCount.cashier_names.linit[billCoin] || ''}
                                        />
                                    </td>
                                )
                            })
                            : ''
                    }
                </tr>
                </tbody>
            </table>
        )
    };
    // TABLES

    render() {
        return (
            <div className="cash-count-sheet">
                <Title
                    paramsPageTitle="Cash Count Sheet"
                />
                <CheckListFilters
                    onGetStores={this.onGetStores}
                    date={this.state.urlParams.date}
                    onChangeDate={this.handleChangeDate}
                    onChangeStore={this.handleChangeStore}
                />
                <div className="cash-count-content">
                    {this.state.renderContent ?
                        <div>
                            <div className="shift-lead-inputs">
                                {this.getShiftLeadInputs()}
                            </div>
                            <div className="bar-table-div">
                                {
                                    this.getBarSafeTable()
                                }
                            </div>
                            <div className="starting-banks-table-div">
                                {
                                    this.getStartingBanksTable()
                                }
                            </div>
                            <div className="cashiers-table-div">
                                {
                                    this.getCashiersTable()
                                }
                            </div>
                            <div className="cashiers-note-div">
                                {
                                    <Grid>
                                        <Grid.Row >
                                            <Grid.Column width={16} className="cashiers-note-textarea">
                                                <Form>
                                                    <TextArea
                                                        className="cashiers-textarea"
                                                        value={this.state.cashCount.leads.notes || ''}
                                                        onChange={this.handleChangeNote}
                                                    />
                                                </Form>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16} className="cashiers-note-savebutton">
                                                <Button
                                                    onClick={this.saveCashCount}
                                                    content='Save'
                                                    floated='left'
                                                    color='orange'
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                }
                            </div>
                        </div>
                        :
                        <div>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                            <br/>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                            <br/>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        </div>
                    }
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default CashCountSheet;
