import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import BurgerSideBar from 'components/Sidebar';
import Header from 'components/Header';
import Content from 'components/Content';
import Login from 'pages/Login';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import PasswordResetPage from 'pages/PasswordResetPage';
import UserInvitePage from 'pages/UserInvitePage';

class App extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            showLogin: this.showLogin(),
            showForgotPassword: this.showForgotPassword(),
            showPasswordReset: this.showPasswordReset(),
            showUserInvite: this.showUserInvite()
        };
    }

    render() {
        return (
            <div id="App">
                {this.getContent()}
            </div>
        )
    }

    getContent = () => {
        if (this.state.showLogin) {
            return (
                <Login />
            );
        }

        if (this.state.showForgotPassword) {
            return (
                <ForgotPasswordPage />
            );
        }

        if (this.state.showPasswordReset !== false) {
            return (
                <PasswordResetPage code={this.state.showPasswordReset}/>
            );
        }

        if (this.state.showUserInvite !== false) {
            return (
                <UserInvitePage code={this.state.showUserInvite}/>
            );
        }

        return (
            <Router>
                <BurgerSideBar/>
                <Header/>
                <Content/>
            </Router>
        );
    };

    showLogin = () => {
        return window.location.pathname === '/login';
    };

    showForgotPassword = () => {
        return window.location.pathname === '/forgot-password';
    };

    showPasswordReset = () => {
        if (window.location.pathname.startsWith('/reset-password')) {
            let index = window.location.pathname.lastIndexOf("/");
            let code = window.location.pathname.substring(index+1);
            return code;
        }
        else {
            return false;
        }
    };

    showUserInvite = () => {
        if (window.location.pathname.startsWith('/user-invite')) {
            let index = window.location.pathname.lastIndexOf("/");
            let code = window.location.pathname.substring(index+1);
            return code;
        }
        else {
            return false;
        }
    };
}

export default App;
