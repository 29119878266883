import Cookies from "universal-cookie";

const requestPromise = require('request-promise');

class Request
{
    baseUrl = process.env.REACT_APP_URL;
    token = null;
    managementToken = null;

    constructor() {
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if(decodedCookie.token) {
            this.token = decodedCookie.token;
        }
        if(decodedCookie['management-token']) {
            this.managementToken = decodedCookie['management-token'];
        }
    }

    redirectToLogin ()
    {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/logout';
    }

    redirectToDashboard ()
    {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url;
    }

    get (path, resolve, reject, customBaseUrl = null, management = null)
    {
        let url = customBaseUrl ? customBaseUrl : this.baseUrl;
        let options = {
            method: 'GET',
            uri: url + path
        };

        if(this.managementToken) {
            options.headers = {
                'Authorization': 'Bearer ' + this.managementToken
            };
        }
        else if(this.token) {
            options.headers = {
                'Authorization': 'Bearer ' + this.token
            };
        }
        else {
            this.redirectToLogin();
        }

        requestPromise(options)
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                let message = error.message.length > 50 ? null : error.message;
                error = error.response;
                if(error.statusCode === 401) {
                    this.redirectToLogin();
                }
                if(error.statusCode === 403) {
                    this.redirectToDashboard();
                }
                reject(message);
            });
    }

    delete (path, resolve, reject, customBaseUrl = null, management = null)
    {
        let url = customBaseUrl ? customBaseUrl : this.baseUrl;
        let options = {
            method: 'DELETE',
            uri: url + path
        };

        if(this.managementToken) {
            options.headers = {
                'Authorization': 'Bearer ' + this.managementToken
            };
        }
        else if(this.token) {
            options.headers = {
                'Authorization': 'Bearer ' + this.token
            };
        }
        else {
            this.redirectToLogin();
        }

        requestPromise(options)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                let errorObject = error && error.error ? JSON.parse(error.error) : {};
                let message = errorObject.message ? errorObject.message : null;
                error = error.response;
                if(error.statusCode === 401) {
                    this.redirectToLogin();
                }
                if(error.statusCode === 403) {
                    this.redirectToDashboard();
                }
                reject(message);
            });
    }

    put (path, body, resolve, reject, customBaseUrl = null, management = null)
    {
        let url = customBaseUrl ? customBaseUrl : this.baseUrl;
        let options = {
            method: 'PUT',
            uri: url + path,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        if(this.managementToken) {
            options.headers.Authorization = 'Bearer ' + this.managementToken
        }
        else if(this.token) {
            options.headers.Authorization = 'Bearer ' + this.token
        }
        else {
            this.redirectToLogin();
        }

        if(body !== null) {
            options.body = JSON.stringify(body);
        }

        requestPromise(options)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                let errorObject = error && error.error ? JSON.parse(error.error) : {};
                let message = errorObject.message ? errorObject.message : null;
                error = error.response;
                if(error.statusCode === 401) {
                    this.redirectToLogin();
                }
                if(error.statusCode === 403) {
                    this.redirectToDashboard();
                }
                reject(message);
            });
    }

    post (path, body, resolve, reject, customBaseUrl = null, management = null)
    {
        let url = customBaseUrl ? customBaseUrl : this.baseUrl;
        let options = {
            method: 'POST',
            uri: url + path,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        if(body !== null) {
            options.body = JSON.stringify(body);
        }

        if(this.managementToken) {
            options.headers.Authorization = 'Bearer ' + this.managementToken
        }
        else if(this.token) {
            options.headers.Authorization = 'Bearer ' + this.token
        }
        else {
            this.redirectToLogin();
        }

        requestPromise(options)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                let errorObject = error && error.error ? JSON.parse(error.error) : {};
                let message = errorObject.message ? errorObject.message : null;
                error = error.response;
                if(error.statusCode === 401) {
                    this.redirectToLogin();
                }
                if(error.statusCode === 403) {
                    this.redirectToDashboard();
                }
                reject(message);
            });
    }
}

export default Request;