import React from 'react';
import LandingPageMenu from 'components/LandingPageMenu';
import MenuItems from '../../config/MenuItems';

class RecipesNav extends React.Component
{
    render() {
        return this.getContent();
    }

    getContent = () => {
        const menuItems = MenuItems();
        const checklistMenu = menuItems['recipes']['submenu'];
        return (
            <div>
                <h1>RECIPES</h1>
                <br/><br/><br/>
                <LandingPageMenu menuItems={checklistMenu}/>
            </div>
        )
    }
}

export default RecipesNav;
