import React from 'react';
import './../../assets/css/pages/admin/promotions-admin-edit.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import {Button, Card, Input} from "semantic-ui-react";
import ModalImage from "react-modal-image";
import Request from "../../Request";
import RichTextEditor from "react-rte";
import PropTypes from "prop-types";

class PromotionsEdit extends React.Component {
    state = {
        promotion: null,
        file: null,
        filename: null,
        files: [],
        renderPromotion: false,
        loading: false,
        announcementValue: RichTextEditor.createEmptyValue(),
    };

    toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'},
            {label: 'Strikethrough', style: 'STRIKETHROUGH'}
        ],
        BLOCK_TYPE_BUTTONS: [
            {label: 'UL', style: 'unordered-list-item'},
            {label: 'OL', style: 'ordered-list-item'}
        ]
    };

    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.request = new Request();
    }

    componentDidMount() {
        this.getPromotion();
    }

    getPromotion = () => {
        new Promise((resolve, reject) => {
            let path = '/promotion';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let promotion = JSON.parse(response);
                if(promotion && promotion.success === true && promotion.promotion) {
                    if(promotion.promotion.description) {
                        this.setState({announcementValue: this.getRichTextValueParsed(promotion.promotion.description)})
                    }
                    this.setState({promotion: promotion.promotion, files: promotion.files}, ()=>{
                        this.setState({renderPromotion:true, loading: false});
                    })
                }
                else {
                    this.setState({renderPromotion:true, loading: false});
                }
            })
            .catch(error => {
                this.setState({renderPromotion:true, loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Promotion', 'Error');
                }
            });
    };

    handleRemoveImage = (index) => {
        let media = this.state.files.splice(index, 1);

        if(Array.isArray(media) && media.length ===1 && media[0].url) {
            new Promise((resolve, reject) => {
                let apiUrl = media[0].url;
                this.request.delete('', resolve, reject, apiUrl);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully removed media content', 'Success');
                        this.getPromotion();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove media content', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove media content', 'Error');
        }
    };

    getRichTextValueParsed = (value) => {
        return value ? RichTextEditor.createValueFromString(value, 'html') : RichTextEditor.createEmptyValue()
    };

    handleInputChanged = (event, field) => {
        let promotion = this.state.promotion;
        promotion[field] = event.target.value;
        this.setState({promotion: promotion});
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({filename: event.target.files[0].name});
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result
                });
            };
        }
    };

    resetFile = () => {
        this.setState({file: null, filename: null});
    };

    savePromotion = () => {
        this.setState({loading: true});
        new Promise((resolve, reject) => {
            let path = '/promotion';
            let params = {
                description: this.state.announcementValue.toString('html'),
                title: this.state.promotion.title,
                file: this.state.file || null,
                filename: this.state.filename
            };
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                this.resetFile();
                this.getPromotion();
                NotificationManager.success('Successfully updated Promotion', 'Success');
            })
            .catch(error => {
                this.setState({loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not update Promotion', 'Error');
                }
            });
    };

    handleDescriptionChange = (value) => {
        if(value) {
            this.setState({announcementValue: value});
        }
    };

    getPromotionContent = () => {
        let promotion = this.state.promotion;
        let media = this.sortMedia(this.state.files);
        if(this.state.renderPromotion) {
            return (
                <div>
                    <div className="promotion-edit-card-fields">
                        <b>Title:</b>
                        <Input
                            fluid
                            value={promotion.title || ''}
                            onChange={(e)=>this.handleInputChanged(e, 'title')}
                        /><br/>
                        <b>Description:</b><br/>
                        <RichTextEditor
                            editorClassName="textEditor"
                            value={this.state.announcementValue}
                            onChange={this.handleDescriptionChange}
                            toolbarConfig={this.toolbarConfig}
                        /><br/>
                        <div className="promotion-edit-card-file-input">
                            <div>
                                <input ref={this.fileInput} type="file" accept=".jpeg, .jpg, .png, .svg, .mp4, .mov, .gif" onChange={this.handleFileChanged}/>
                            </div>
                            <div className="fileInputTypes">(jpg, jpeg, png, gif, svg, mp4, mov)</div>
                        </div>
                        <br/>
                        <br/>
                        {this.state.loading ?
                            <Button loading color="orange">
                                Loading
                            </Button>
                            :
                            <Button color="orange" onClick={() => this.savePromotion()}>Update</Button>
                        }
                    </div>
                    <hr/>
                    <div>
                        <div className="promotion-edit-card-media">
                            {
                                media.map((file, index) => {
                                    return (
                                        <div key={index}>
                                            { file.is_video ?
                                                <div className="promotion-edit-card-video-media-div" key={index}>
                                                    <video className="promotion-edit-card-videos" controls key={index + 'schema-edit-video'}>
                                                        <source src={file.url} type="video/mp4"/>
                                                    </video>
                                                    <div className="promotion-edit-card-remove-image-link">
                                                        <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                                                    </div>
                                                </div>
                                                :
                                                <div className="promotion-edit-card-images-media-div" key={index}>
                                                    <ModalImage
                                                        key={index + 'schema-edit-image'}
                                                        className="promotion-edit-card-images"
                                                        small={file.url}
                                                        large={file.url}
                                                        alt=""
                                                    />
                                                    <div className="promotion-edit-card-remove-image-link">
                                                        <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <NotificationContainer/>
                </div>
            );
        }
        return '';
    };

    render() {
        return (
            <Card className="promotion-edit-card" fluid>
                <Card.Content className="promotion-edit-card-header" header="Promotions edit" />
                <Card.Content description={this.state.renderPromotion ? this.getPromotionContent() : ''} />
            </Card>
        )
    }

}

export default PromotionsEdit;