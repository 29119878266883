import React from 'react';
import {Card} from "semantic-ui-react";
import './../assets/css/pages/schematics.css';
import ModalImage from "react-modal-image";
import {NotificationManager, NotificationContainer} from "react-notifications";
import Request from "../Request";
import Title from "../components/Title";

class Schematics extends React.Component
{
    state = {
        schematics: [],
        renderSchematics: false
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getSchematics();
    }

    getSchematics = () => {
        new Promise((resolve, reject) => {
            let path = '/schematics/files';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let schematics = JSON.parse(response);
                if(schematics && schematics.success === true && schematics.data) {
                    if(Array.isArray(schematics.data) && schematics.data.length >0) {
                        this.setState({schematics: schematics.data}, () => {
                            //console.log(this.state)
                            this.setState({renderSchematics:true});
                        });
                    }
                }
                else {
                    this.setState({renderSchematics:true});
                }
            })
            .catch(error => {
                this.setState({schematics: []}, ()=>{
                    this.setState({renderSchematics:true});
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Schematics', 'Error');
                }
            });
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    getCardContent = (media) => {
        media = this.sortMedia(media);
        return (
            <div className="schematics-media">
                {
                    media.map((file, index) => {
                        return (
                                <div key={index}>
                                    { file.is_video ?
                                        <div className="schematics-videos-media-div" >
                                            <video className="schematics-videos" controls key={index + 'schema-video'}>
                                                <source src={file.url} type="video/mp4"/>
                                            </video>
                                        </div>
                                        :
                                        <div className="schematics-images-media-div" >
                                            <ModalImage
                                                key={index + 'schema-image'}
                                                className="schematics-images"
                                                small={file.url}
                                                large={file.url}
                                                alt=""
                                            />
                                        </div>
                                    }
                                </div>
                        );
                    })
                }
            </div>
        );
    };


    render() {
        return (
            <div className="schematics-page">
                <Title
                    paramsPageTitle="Schematics"
                />
                <div className="schematics-content">
                            {  this.state.renderSchematics && this.state.schematics ?
                                this.state.schematics.map((schema, index) => {
                                    return (
                                        <div key={index}>
                                            <Card className="schema-card" fluid>
                                                <Card.Content className="schema-card-header" header={schema.name} />
                                                <Card.Content className="schema-card-content" description={this.getCardContent(schema.files)} />
                                            </Card>
                                        </div>
                                    );
                                })
                                : ''}
                </div>
                <NotificationContainer/>
            </div>
        );
    }
}

export default Schematics;
