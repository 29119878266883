import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/dashboard.css';
import {Input, Button} from "semantic-ui-react";
import Request from "../../Request";
import RichTextEditor from "react-rte";
import PropTypes from "prop-types";
import ModalImage from "react-modal-image";
import Title from 'components/Title';

class DashboardEdit extends React.Component {

    state = {
        dashboard: {},
        announcementValue: RichTextEditor.createEmptyValue(),
        file: null,
        filename: null,
        loading: false
    };

    toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'},
            {label: 'Strikethrough', style: 'STRIKETHROUGH'}
        ],
        BLOCK_TYPE_BUTTONS: [
            {label: 'UL', style: 'unordered-list-item'},
            {label: 'OL', style: 'ordered-list-item'}
        ]
    };

    static propTypes = {
        onChange: PropTypes.func
    };

    constructor() {
        super();
        this.request = new Request();
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        this.getDashboardData();
    }

    getDashboardData = () => {
        new Promise((resolve, reject) => {
            let path = '/dashboard';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let dashboard = JSON.parse(response);
                if(dashboard && dashboard.success === true && dashboard.data) {
                    if(dashboard.data.announcement) {
                        this.setState({announcementValue: this.getRichTextValueParsed(dashboard.data.announcement)})
                    }
                    if(dashboard.data.file) {
                        this.setState({file: dashboard.data.file});
                    }
                    this.setState({dashboard: dashboard.data, loading: false});
                }
                else {
                    this.setState({dashboard: {}, loading: false});
                }
            })
            .catch(error => {
                this.setState({dashboard: [], loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Dashboard data', 'Error');
                }
            });
    };

    updateDashboard = () => {
        this.setState({loading: true});
        new Promise((resolve, reject) => {
            let path = '/dashboard';
            let params = {
                announcement: this.state.announcementValue.toString('html'),
                title: this.state.dashboard.title,
                description: this.state.dashboard.description,
                file: this.state.file === this.state.dashboard.file ? null : this.state.file,
                filename: this.state.filename
            };
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                this.resetFile();
                this.getDashboardData();
                NotificationManager.success('Successfully updated Dashboard', 'Success');
            })
            .catch(error => {
                this.setState({loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not update Dashboard', 'Error');
                }
            });
    };

    getRichTextValueParsed = (value) => {
        return value ? RichTextEditor.createValueFromString(value, 'html') : RichTextEditor.createEmptyValue()
    };

    handleDescriptionChange = (value) => {
        if(value) {
            this.setState({announcementValue: value});
        }
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({filename: event.target.files[0].name});
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result
                });
            };
        }
    };

    resetFile = () => {
        this.setState({file: null, filename: null});
    };

    handleInputChanged = (event, field) => {
        let dashboard = this.state.dashboard;
        dashboard[field] = event.target.value;
        this.setState({dashboard: dashboard});
    };

    render() {
        let dashboard = this.state.dashboard;
        return (
            <div className="dashboard-admin-edit-root">
                <Title
                    paramsPageTitle="dashboard Edit"
                />
                <div className="dashboard-admin-edit-fields">
                    <b>Announcement:</b><br/>
                    <RichTextEditor
                        editorClassName="textEditor"
                        value={this.state.announcementValue}
                        onChange={this.handleDescriptionChange}
                        toolbarConfig={this.toolbarConfig}
                    /><br/>
                    <b>Title:</b>
                    <Input
                        fluid
                        value={dashboard.title || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'title')}
                    /><br/>
                    <b>Description:</b>
                    <Input
                        fluid
                        value={dashboard.description || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'description')}
                    /><br/>
                    <div className="dashboard-admin-edit-file-input">
                        <div>
                            <input ref={this.fileInput} type="file" accept=".jpeg, .jpg, .png, .gif, .svg" onChange={this.handleFileChanged}/>
                        </div>
                        <div className="fileInputTypes">(jpg, jpeg, png, gif, svg)</div>
                    </div>
                    <div className="home-promotion-card-edit-image-div">
                        <ModalImage
                            className="home-promotion-card-images"
                            small={this.state.file}
                            large={this.state.file}
                            alt=""
                        />
                    </div>
                    <br/>
                    {this.state.loading ?
                        <Button loading color="orange">
                            Loading
                        </Button>
                        :
                        <Button color="orange" size="tiny" onClick={this.updateDashboard}>Update</Button>
                    }
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default DashboardEdit;