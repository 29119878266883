import React from 'react';
import {Button, Card, Grid, Modal, Popup, Select} from "semantic-ui-react";
import './../assets/css/pages/batchRecipes.css';
import ModalImage from "react-modal-image";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../Request";
import Title from "../components/Title";
class DrinkRecipes extends React.Component
{
    state = {
        recipes: [],
        recipesOptions: [],
        recipe: {
            id: 0,
            key: 0,
            value: 0,
            text: 'Select Recipe'
        },
        renderRecipes: false,

        store: {},
        globalStore: {
            franchisees: [],
            managers: [],
            store: {
                title: 'Global'
            }
        },
        changeStore: false,
        stores: [],
        storesOptions: [],
        openModal: false,
        renderStoreInfos: false,
        isGlobalStore: true,

        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360 // for the popup position
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getStoresData();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    // FILTERS

    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    this.setState({stores: stores.data, store: this.state.globalStore}, () => {
                        this.transformStoresToOptions();
                        this.setState({renderStoreInfos: true});
                        this.getRecipes();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({stores: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({renderStoreInfos: true});
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [
            {
                id: 99999,
                key: 99999,
                value: 99999,
                text: 'Global'
            }
        ];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({storesOptions: options})
    };

    handleStoresInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                </div>
                                :''
                            }
                        </Grid.Column> :''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 :16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.setState({changeStore:false, store: foundStore, isGlobalStore: false}, () => {
                this.getRecipes();
            });
        }
        else if(parseInt(data.value) === 99999) {
            this.setState({changeStore:false, store: this.state.globalStore, isGlobalStore: true}, () => {
                this.getRecipes();
            });
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br/>
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username} onClick={()=>this.handleRedirectToProfile(manager.username)}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    :''

                                }
                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    // FILTERS END

    getRecipes = () => {
        this.emptyRecipesOptions();
        new Promise((resolve, reject) => {
            let store = this.state.store.store.id ? '?store_id=' + this.state.store.store.id : '';
            let path = '/recipes/drink' + store;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let recipes = JSON.parse(response);
                if(recipes && recipes.success === true && recipes.data) {
                    if(Array.isArray(recipes.data) && recipes.data.length >0) {
                        this.setState({recipes: recipes.data}, () => {
                            this.transformRecipesToOptions();
                        });
                    }
                    else {
                        this.setState({recipes: []}, () => {
                            this.transformRecipesToOptions();
                        });
                    }
                }
                else {
                    this.setState({renderRecipes:true}, () => {
                        this.transformRecipesToOptions();
                    });
                }
            })
            .catch(error => {
                this.setState({recipes: []}, ()=>{
                    this.transformRecipesToOptions();
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Recipes', 'Error');
                }
            });
    };

    getRecipe = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/recipes/' + id;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let recipe = JSON.parse(response);
                    if(recipe && recipe.success === true && recipe.data) {
                        this.setState({recipe: recipe.data}, ()=>{
                            this.setState({renderRecipes:true});
                        })
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Recipe', 'Error');
                    }
                });
        }
    };

    emptyRecipesOptions = () => {
        let defaultRecipe = {
            id: 0,
            key: 0,
            value: 0,
            text: 'Select Recipe',
            recipe: {}
        };
        let options = [
            defaultRecipe
        ];
        this.setState({recipesOptions: options, renderRecipes: false, recipe: defaultRecipe});
    };

    transformRecipesToOptions = () => {
        let recipes = this.state.recipes;
        let options = [
            {
                id: 0,
                key: 0,
                value: 0,
                text: 'Select Recipe',
                recipe: {}
            }
        ];
        recipes.forEach((recipe, index) => {
            let tempOption = {
                id: recipe.id,
                key: recipe.id,
                value: recipe.id,
                text: recipe.name
            };
            options.push(tempOption);
        });
        this.setState({recipesOptions: options});
    };

    handleChangeRecipe = (event, data) => {
        const foundRecipe = this.state.recipes.find(element => element.id === parseInt(data.value));
        if(foundRecipe) {
            this.getRecipe(foundRecipe.id);
        } else if(!foundRecipe && data.value === 0) {
            let defaultRecipe = {
                id: 0,
                key: 0,
                value: 0,
                text: 'Select Recipe',
                recipe: {}
            };
            this.setState({recipe: defaultRecipe, renderRecipes: false});
        }
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    getCardContent = (media, text) => {
        media = this.sortMedia(media);
        return (
            <div className="batch-recipes-media">
                <div className="batch-recipes-text-div" dangerouslySetInnerHTML={{__html: text}}>
                </div>
                { Array.isArray(media) && media.length > 0 ?
                    <hr/>
                    :''
                }
                {
                    media.map((file, index) => {
                        return (
                            <div key={index}>
                                { file.is_video ?
                                    <div className="batch-recipes-videos-media-div" >
                                        <video className="batch-recipes-videos" controls key={index + 'batch-recipes-video'}>
                                            <source src={file.url} type="video/mp4"/>
                                        </video>
                                    </div>
                                    :
                                    <div className="batch-recipes-images-media-div" >
                                        <ModalImage
                                            key={index + 'batch-recipes-image'}
                                            className="batch-recipes-images"
                                            small={file.url}
                                            large={file.url}
                                            alt=""
                                        />
                                    </div>
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    getNewOrdersFilters = () => {
        return (
            <div className="recipes-admin-filters">
                <Grid stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column width={5}>
                            <div className="recipes-admin-stores">
                                <div className="recipes-admin-content">
                                    <div className="store-number">
                                        <Select
                                            text={this.state.store.store.title}
                                            value={this.state.store.store.id}
                                            options={this.state.storesOptions}
                                            onChange={this.handleChangeStore}/>
                                    </div>
                                </div>
                                <div className="store-info-icon">
                                    <div className="ui icon buttons filters-buttons">
                                        { !this.state.isGlobalStore ?
                                            <button className="ui button" onClick={this.handleStoresInfoButton}>
                                                {!this.state.openModal ?
                                                    <Popup
                                                        on='click'
                                                        trigger={<i className="info circle icon"></i>}
                                                        size='small'
                                                        pinned
                                                        wide='very'
                                                        position={this.state.mobileSize ? 'bottom center' : 'bottom left'}
                                                    >
                                                        {
                                                            this.state.renderStoreInfos ?
                                                                this.getStoresPopupInfos()
                                                                : ''
                                                        }
                                                    </Popup> : ''
                                                }
                                            </button>
                                            :''
                                        }
                                        <button className="ui button" onClick={this.handleEditStore}><i className="edit outline icon"></i></button>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div className="recipes-select">
                                <Select
                                    text={this.state.recipe.name}
                                    value={this.state.recipe.id}
                                    options={this.state.recipesOptions}
                                    onChange={this.handleChangeRecipe}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={5}>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    };

    render() {
        return (
            <div className="recipes-page">
                {this.getStoresModalInfos()}
                <Title
                    paramsPageTitle="Drink Recipes"
                />
                {this.state.renderStoreInfos ? this.getNewOrdersFilters() : ''}
                <br/>
                <div className="recipes-content">
                    { this.state.renderRecipes ?
                        <Card className="batch-recipe-card" fluid>
                            <Card.Content className="batch-recipe-card-header" header={this.state.recipe.recipe.name} />
                            <Card.Content className="batch-recipe-card-content" description={this.getCardContent(this.state.recipe.files, this.state.recipe.recipe.content)} />
                        </Card>
                        :
                        ''
                    }
                </div>
                <NotificationContainer/>
            </div>
        );
    }
}

export default DrinkRecipes;
