import React from 'react';
import {Card} from "semantic-ui-react";
import './../assets/css/pages/promotion.css';
import ModalImage from "react-modal-image";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../Request";
import Title from "../components/Title";

class Promotion extends React.Component {
    state = {
        promotion: [],
        renderPromotion: false,
        files: []
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getPromotion();
    }

    getPromotion = () => {
        new Promise((resolve, reject) => {
            let path = '/promotion';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let promotion = JSON.parse(response);
                if(promotion && promotion.success === true && promotion.promotion) {
                    this.setState({promotion: promotion.promotion, files: promotion.files}, ()=>{
                        this.setState({renderPromotion:true});
                    })
                }
                else {
                    this.setState({renderPromotion:true});
                }
            })
            .catch(error => {
                this.setState({renderPromotion:true});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Promotion', 'Error');
                }
            });
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    getCardContent = (media, text) => {
        media = this.sortMedia(media);
        return (
            <div className="promotion-media">
                <div className="promotion-text-div" dangerouslySetInnerHTML={{__html: text}}>
                </div>
                { Array.isArray(media) && media.length > 0 ?
                    <hr/>
                    :''
                }
                {
                    media.map((file, index) => {
                        return (
                            <div key={index}>
                                { file.is_video ?
                                    <div className="promotion-videos-media-div" >
                                        <video className="promotion-videos" controls key={index + 'promotion-video'}>
                                            <source src={file.url} type="video/mp4"/>
                                        </video>
                                    </div>
                                    :
                                    <div className="promotion-images-media-div" >
                                        <ModalImage
                                            key={index + 'promotion-image'}
                                            className="promotion-images"
                                            small={file.url}
                                            large={file.url}
                                            alt=""
                                        />
                                    </div>
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    render() {
        let promotion = this.state.promotion;
        return (
            <div className="promotion-page">
                <Title
                    paramsPageTitle="Promotion"
                />
                <div className="promotion-content">
                    {  this.state.renderPromotion ?
                        <Card className="promotion-card" fluid>
                            <Card.Content className="promotion-card-header" header={promotion.title || ''} />
                            <Card.Content className="promotion-card-content" description={this.getCardContent(this.state.files, promotion.description)} />
                        </Card>
                        : ''}
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default Promotion;