import React from 'react';

class Header extends React.Component
{
    render() {
        return (
            <div className='header-main'>
                <a href="/" title="Dripp Management Portal" className="navbar-brand">Dripp</a>
            </div>
        );
    }
}

export default Header;
