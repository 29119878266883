import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import {Button, Grid, Icon, Modal, Popup, Select} from "semantic-ui-react";
import '../../assets/css/pages/managment/orderHistory.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ReactToPrint from 'react-to-print';
import Request from "../../Request";
import Title from 'components/Title';

class OrderHistory extends React.Component
{
    state = {
        date: new Date(),
        selectionRange: {
            startDate: moment(),
            startDateId: '1',
            endDate: moment(),
            endDateId: '2',
            focusedInput: null
        },
        defaultDateRanges: [
            {
                id: 1,
                key: 1,
                value: 'today',
                text: 'Today'
            },
            {
                id: 2,
                key: 2,
                value: 'yesterday',
                text: 'Yesterday'
            },
            {
                id: 3,
                key: 3,
                value: '7days',
                text: 'Last 7 Days'
            },
            {
                id: 4,
                key: 4,
                value: '30days',
                text: 'Last 30 Days'
            },
            {
                id: 5,
                key: 5,
                value: '60days',
                text: 'Last 60 Days'
            },
            {
                id: 6,
                key: 6,
                value: 'thisMonth',
                text: 'This Month'
            },
            {
                id: 7,
                key: 7,
                value: 'lastMonth',
                text: 'Last Month'
            }
        ],
        store: {},
        stores: null,
        changeStore: false,
        history: [],
        openModal: false,
        renderHistory: false,
        renderStoreInfos: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        sort: {
            time: {
                sort: true,
                type: 'DESC'
            },
            vendor: {
                sort: false,
                type: 'DESC'
            },
            total: {
                sort: false,
                type: 'DESC'
            }
        },
        firstSort: true,
        sortItem: null,
        vendor: {},
        vendors: [],
        vendorOptions: [],
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.setState({renderHistory:false, renderStoreInfos: false});
        this.getStoresData();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    getHistoryData = () => {
        let startDate = moment(this.state.selectionRange.startDate).format('YYYY-MM-DD');
        let endDate = moment(this.state.selectionRange.endDate).format('YYYY-MM-DD');
        let vendorId = this.state.vendor.id;
        if(vendorId) {
            new Promise((resolve, reject) => {
                let path = '/stores/'+this.state.store.store.id+'/orders?vendorId='+ vendorId +'&startDate=' + startDate + '&endDate=' + endDate;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let history = JSON.parse(response);
                    if(history && history.success === true && history.data) {
                        this.setState({history: history.data}, () => {
                            this.setState({renderHistory: true});
                            this.mapHistoryProducts();
                        });
                    }
                    else {
                        NotificationManager.error('Could not get Order History', 'Error');
                    }
                })
                .catch(error => {
                    this.setState({history: []});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Order History', 'Error');
                    }
                    this.setState({renderHistory: true});
                });
        }
    };

    mapHistoryProducts = () => {
        let history = this.state.history;
        if(history && Array.isArray(history)) {
            history.forEach((historyEntry, index) => {
                historyEntry.order.showProducts = false;
            })
        }
        this.setState({history: history})
    };

    toogleProducts = (index) => {
        let history = this.state.history;
        history[index].order.showProducts = !history[index].order.showProducts;
        this.setState({history: history});
    };

    getUnsortedObject = () => {
        let sort = {
            time: {
                sort: false,
                type: 'DESC'
            },
            vendor: {
                sort: false,
                type: 'DESC'
            },
            total: {
                sort: false,
                type: 'DESC'
            }
        };

        if(this.state.firstSort) {
            sort.time = {
                sort: true,
                type: 'DESC'
            };
            this.setState({firstSort: false})
        }

        this.setState({sort: sort});
        return sort;
    };

    sortTable = (header) => {
        let sortItem = this.state.sortItem;
        let sort;
        if(sortItem !== header) {
            sort = this.getUnsortedObject();
        }
        else {
            sort = this.state.sort;
        }
        let sortHeader = sort[header];
        sortHeader.sort = true;
        sortHeader.type = sortHeader.type === 'ASC' ? 'DESC' : 'ASC';
        sort[header] = sortHeader;
        this.setState({sort : sort, renderHistory: false, sortItem: header});
        this.sortTableAsc(header, sortHeader.type);
    };

    sortTableAsc = (header, type) => {
        let orders = this.state.history;
        if(header === 'time') {
            if(type === 'ASC') {
                orders.sort(
                    (a,b) => (
                        moment(a.order.created_at, 'ddd, MMM Do LT').isAfter(moment(b.order.created_at, 'ddd, MMM Do LT'))
                            ? 1 :
                        (moment(a.order.created_at, 'ddd, MMM Do LT').isBefore(moment(b.order.created_at, 'ddd, MMM Do LT')) ? -1 : 0)
                ))
            }
            else {
                orders.sort(
                    (a,b) => (
                        moment(a.order.created_at, 'ddd, MMM Do LT').isBefore(moment(b.order.created_at, 'ddd, MMM Do LT'))
                        ? 1 :
                        (moment(a.order.created_at, 'ddd, MMM Do LT').isAfter(moment(b.order.created_at, 'ddd, MMM Do LT')) ? -1 : 0)
                ))
            }
        }
        else if(header === 'vendor') {
            if(type === 'ASC') {
                orders.sort(
                    (a,b) => (a.order.vendor > b.order.vendor) ? 1 : ((b.order.vendor > a.order.vendor) ? -1 : 0)
                )
            }
            else {
                orders.sort(
                    (a,b) => (a.order.vendor < b.order.vendor) ? 1 : ((b.order.vendor < a.order.vendor) ? -1 : 0)
                )
            }
        }
        else if(header === 'total') {
            if(type === 'ASC') {
                orders.sort(
                    (a,b) => (parseFloat(a.order.cost_sum) > parseFloat(b.order.cost_sum)) ? 1 : ((parseFloat(b.order.cost_sum) > parseFloat(a.order.cost_sum)) ? -1 : 0)
                )
            }
            else {
                orders.sort(
                    (a,b) => (parseFloat(a.order.cost_sum) < parseFloat(b.order.cost_sum)) ? 1 : ((parseFloat(b.order.cost_sum) < parseFloat(a.order.cost_sum)) ? -1 : 0)
                )
            }
        }

        this.setState({history: orders, renderHistory: true})
    };

    getOrderRow = (order, index, showProducts) => {
        return (
            <tr key={index} onClick={(event) => this.toogleProducts(index)}>
                <td>
                    <div className="history-table-action-cell">
                        <Button icon>
                            <Icon name={showProducts ? 'minus' : 'plus'} />
                        </Button>
                    </div>
                    <div className="history-table-date-cell">
                        {order.created_at || ''}
                    </div>
                </td>
                <td>{order.vendor || ''}</td>
                <td>{order.cost_sum || ''}</td>
            </tr>
        );
    };

    getExtraOrderRows = (historyEntry, index) => {
        if(historyEntry.order.showProducts && historyEntry.products && Array.isArray(historyEntry.products)) {
            let productRows = [];
            historyEntry.products.forEach((product, productsIndex) => {
                let row = <tr className="history-extra-table-row" key={index + '-products-extra-row-' + productsIndex}>
                    <td>{product.item_no || ''}</td>
                    <td>{product.description || ''}</td>
                    <td>{product.cost || ''}</td>
                    <td>{product.on_hand || ''}</td>
                    <td>{product.qty || ''}</td>
                    <td>{product.um || ''}</td>
                </tr>;
                productRows.push(row);
            });
            let productTable = <tr key={'product-table-' + index}>
                <td colSpan={3} className="history-extra-table-root-td">
                    <table className="history-extra-table" align="center">
                        <thead>
                        <tr className="history-extra-table-header-row">
                            <th>Item</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>O/H</th>
                            <th>QTY</th>
                            <th>U/M</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            productRows
                        }
                        </tbody>
                    </table>
                </td>
            </tr>;
            return productTable;
        }
        return ''
    };

    getOrderHistoryTable = () => {
        let history = this.state.history;
        let sort = this.state.sort;
        return (
            <table className="history-table" align="center">
                <thead>
                    <tr className="history-table-header-row">
                        <th onClick={(e) => this.sortTable('time')}>Time
                            {sort.time.sort ?
                                <Icon size='tiny' name={sort.time.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                                :''}
                        </th>
                        <th onClick={(e) => this.sortTable('vendor')}>Vendor
                            {sort.vendor.sort ?
                                <Icon size='tiny' name={sort.vendor.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                                :''}
                        </th>
                        <th onClick={(e) => this.sortTable('total')}>Total
                            {sort.total.sort ?
                                <Icon size='tiny' name={sort.total.type === 'ASC' ? 'arrow up' : 'arrow down'} />
                                :''}
                        </th>
                    </tr>
                </thead>
                <tbody>
                { this.state.renderHistory && history && Array.isArray(history) ?
                    history.map((historyEntry, index) => {
                        return [
                            this.getOrderRow(historyEntry.order, index, historyEntry.order.showProducts),
                            this.getExtraOrderRows(historyEntry, index)
                        ];
                    })
                    :''
                }
                </tbody>
            </table>
        );
    };

    // FILTERS PART
    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br/>
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username} onClick={()=>this.handleRedirectToProfile(manager.username)}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index)=>{
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    :''

                                }
                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    let activeStore = localStorage.getItem('activeStore');
                    if(activeStore && typeof activeStore !== 'undefined') {
                        activeStore = {
                            franchisees: [],
                            managers: [],
                            store: JSON.parse(activeStore)
                        };
                    } else {
                        activeStore = stores.data[0]
                    }
                    this.setState({stores: stores.data, store: activeStore}, () => {
                        this.transformStoresToOptions();
                        this.setState({renderStoreInfos: true});
                        this.getVendorsData();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({stores: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({renderStoreInfos: true});
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({options: options})
    };

    handleStoresInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username} onClick={()=>this.handleRedirectToProfile(manager.username)}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username} onClick={()=>this.handleRedirectToProfile(franchise.username)}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                </div>
                                :''
                            }
                        </Grid.Column> :''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 :16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.setState({changeStore:false, store: foundStore, renderHistory:false}, () => {
                this.getVendorsData();
                this.getHistoryData();
            });
            localStorage.setItem('activeStore', JSON.stringify(foundStore.store));
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    handleChangeDateInput = (startDate, endDate) => {
        let selection = this.state.selectionRange;
        selection.startDate = startDate;
        selection.endDate = endDate;
        this.setState({selectionRange: selection}, () => {
            this.getHistoryData();
        });
    };

    changedDatePickerFocus = (focusedInput) => {
        let selection = this.state.selectionRange;
        selection.focusedInput = focusedInput;
        this.setState({selectionRange: selection});
    };

    changedDefaultRange = (event, data) => {
        let selectionRange = this.state.selectionRange;
        if(data.value) {
            switch (data.value) {
                case 'today': {
                    selectionRange.startDate = moment();
                    selectionRange.endDate = moment();
                    break;
                }
                case 'yesterday': {
                    selectionRange.startDate = moment().subtract(1, 'day');
                    selectionRange.endDate = moment().subtract(1, 'day');
                    break;
                }
                case '7days': {
                    selectionRange.startDate = moment().subtract(6, 'day');
                    selectionRange.endDate = moment();
                    break;
                }
                case '30days': {
                    selectionRange.startDate = moment().subtract(29, 'day');
                    selectionRange.endDate = moment();
                    break;
                }
                case '60days': {
                    selectionRange.startDate = moment().subtract(59, 'day');
                    selectionRange.endDate = moment();
                    break;
                }
                case 'thisMonth': {
                    selectionRange.startDate = moment().startOf('month');
                    selectionRange.endDate = moment().endOf('month');
                    break;
                }
                case 'lastMonth': {
                    selectionRange.startDate = moment().startOf('month').subtract(1, 'month');
                    selectionRange.endDate = moment().endOf('month').subtract(1, 'month');
                    break;
                }
                default: {
                    selectionRange.startDate = moment();
                    selectionRange.endDate = moment();
                }
            }
            this.setState({selectionRange: selectionRange}, () => {
                this.getHistoryData();
            })
        }
    };

    getOrderHistoryFilters = () => {
        return (
            <div className="order-history-filters">
                <Grid stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <div className="new-orders-stores">
                                <div className="store-info-icon">
                                    <div className="ui icon buttons filters-buttons">
                                        <button className="ui button" onClick={this.handleStoresInfoButton}>
                                            {   !this.state.openModal ?
                                                <Popup
                                                    on='click'
                                                    trigger={<i className="info circle icon"></i>}
                                                    size='small'
                                                    pinned
                                                    wide='very'
                                                    position={this.state.mobileSize ? 'bottom center' : 'bottom left'}
                                                >
                                                    {
                                                        this.state.renderStoreInfos ?
                                                            this.getStoresPopupInfos()
                                                            : ''
                                                    }
                                                </Popup> : ''
                                            }
                                        </button>
                                        <button className="ui button" onClick={this.handleEditStore}><i className="edit outline icon"></i></button>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8} className="order-history-date-filters-grid">
                            <div className="order-history-date-filters-div-range">
                                <DateRangePicker
                                    startDate={this.state.selectionRange.startDate} // momentPropTypes.momentObj or null,
                                    startDateId={this.state.selectionRange.startDateId} // PropTypes.string.isRequired,
                                    endDate={this.state.selectionRange.endDate} // momentPropTypes.momentObj or null,
                                    endDateId={this.state.selectionRange.endDateId} // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => this.handleChangeDateInput(startDate, endDate)} // PropTypes.func.isRequired,
                                    focusedInput={this.state.selectionRange.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.changedDatePickerFocus(focusedInput)} // PropTypes.func.isRequired,
                                    minDate={moment(moment().subtract(100, 'years'))}
                                    isOutsideRange={(day) => moment() < moment(moment().subtract(100, 'years'))}
                                    numberOfMonths={this.state.tabletSize ? 1 :2}
                                    displayFormat="YYYY-MM-DD"
                                />
                            </div>
                            { this.state.renderHistory ?
                                <div className="order-history-date-filters-div-default-range">
                                    <Select className="order-history-date-select" placeholder="Date Range" options={this.state.defaultDateRanges} onChange={this.changedDefaultRange}/>
                                    <Select
                                        text={this.state.vendor.name}
                                        value={this.state.vendor.id}
                                        options={this.state.vendorOptions}
                                        onChange={this.handleChangeVendor}/>
                                    <div className="ui icon buttons new-orders-filters-buttons">
                                        <button className="ui button" onClick={this.handleVendorsInfoButton}>
                                            {   !this.state.openVendorsModal ?
                                                <Popup
                                                    on='click'
                                                    trigger={<i className="info circle icon"></i>}
                                                    size='small'
                                                    pinned
                                                    wide='very'
                                                    position={this.state.mobileSize ? 'bottom center' : 'bottom left'}
                                                >
                                                    {
                                                        this.state.renderVendorInfos ?
                                                            this.getVendorsPopupInfos()
                                                            : ''
                                                    }
                                                </Popup> : ''
                                            }
                                        </button>
                                    </div>
                                </div>
                                : <Select placeholder="Date Range" options={this.state.defaultDateRanges} onChange={this.changedDefaultRange}/>}
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <div className="new-orders-print-button">
                                <ReactToPrint
                                    trigger={() => <a href={'#print'}>
                                        <Button icon floated={this.state.tabletSize ? '' : 'right'}>
                                            <Icon name='print' />
                                        </Button>
                                    </a>}
                                    content={() => this.componentRef}
                                />

                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    };

    getVendorsData = () => {
        let store = this.state.store;
        new Promise((resolve, reject) => {
            let path = '/stores/'+ store.store.id +'/vendors';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let vendors = JSON.parse(response);
                if(vendors && vendors.success === true && vendors.data) {
                    this.setState({vendors: vendors.data, vendor: vendors.data[0]}, () => {
                        this.transformVendorsToOptions();
                        this.setState({renderVendorInfos: true});
                        this.getHistoryData();
                    });
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            })
            .catch(error => {
                this.setState({vendors: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
                this.setState({renderVendorInfos: true});
            });
    };

    transformVendorsToOptions = () => {
        let vendors = this.state.vendors;
        let options = [];
        vendors.forEach((vendor, index) => {
            let tempOption = {
                id: vendor.id,
                key: vendor.id,
                value: vendor.id,
                text: vendor.name
            };
            options.push(tempOption);
        });
        this.setState({vendorOptions: options})
    };

    handleChangeVendor = (event, data) => {
        const foundStore = this.state.vendors.find(element => element.id === data.value);
        if(foundStore) {
            this.setState({vendor: foundStore}, ()=> {
                this.getHistoryData();
            });
        }
    };

    handleVendorsInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openVendorsModal: true})
        } else {
            this.setState({ openVendorsModal: false });
        }
    };

    handleCloseVendorsModal = () => {
        this.setState({ openVendorsModal: false });
    };

    getVendorsModalInfos = () => {
        let emailString = this.state.vendor.email;
        let emailArray = [];
        if(emailString !== '' && emailString) {
            if(emailString.indexOf(',') !== -1) {
                emailArray = emailString.split(',');
            }
            else {
                emailArray =[emailString];
            }
        }
        return (
            <Modal className="vendors-info-modal" open={this.state.openVendorsModal} onClose={this.handleCloseVendorsModal} size="mini">
                <Modal.Header>Vendor Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openVendorsModal?
                            <div className="modal-vendors-info">
                                <h5>{this.state.vendor.name || ''}</h5>
                                <p>{this.state.vendor.street1 || ''}</p>
                                <p>{this.state.vendor.street2 || ''}</p>
                                <h5>Account #</h5>
                                <p>{this.state.vendor.accountNo || ''}</p>
                                <h5>Sales Contact</h5>
                                <p>{this.state.vendor.salesContact || ''}</p>
                                {emailArray.length > 0 ?
                                    <div>
                                        <h5>Email</h5>
                                        {emailArray.map((email, index) => {
                                            return (
                                                <a href={'mailto:' + email} target="_top" key={index}>
                                                    {email}
                                                </a>
                                            );
                                        })}
                                    </div> : ''
                                }
                                <h5>Phone</h5>
                                <p>{this.state.vendor.phone || ''}</p>
                                <h5>Phone 2</h5>
                                <p>{this.state.vendor.phone2 || ''}</p>

                            </div>
                            :''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseVendorsModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    getVendorsPopupInfos = () => {
        let emailString = this.state.vendor.email;
        let emailArray = [];
        if(emailString !== '' && emailString) {
            if(emailString.indexOf(',') !== -1) {
                emailArray = emailString.split(',');
            }
            else {
                emailArray =[emailString];
            }
        }
        return (
            <div className="vendor-info-popup-content">
                {this.state.renderVendorInfos?
                    <div className="modal-vendors-info">
                        <h5>{this.state.vendor.name || ''}</h5>
                        <p>{this.state.vendor.street1 || ''}</p>
                        <p>{this.state.vendor.street2 || ''}</p>
                        <h5>Account #</h5>
                        <p>{this.state.vendor.accountNo || ''}</p>
                        <h5>Sales Contact</h5>
                        <p>{this.state.vendor.salesContact || ''}</p>
                        {emailArray.length > 0 ?
                            <div>
                                <h5>Email</h5>
                                {emailArray.map((email, index) => {
                                    return (
                                        <a href={'mailto:' + email} target="_top" key={index}>
                                            {email}
                                        </a>
                                    );
                                })}
                            </div> : ''
                        }
                        <h5>Phone</h5>
                        <p>{this.state.vendor.phone || ''}</p>
                        <h5>Phone 2</h5>
                        <p>{this.state.vendor.phone2 || ''}</p>

                    </div>
                    :''}
            </div>
        );
    };

    getStoresHtml = () => {
        return (
            <div className="new-orders-content">
                <div className="store-number">
                    <Select
                        text={this.state.store.store.title}
                        value={this.state.store.store.id}
                        options={this.state.options}
                        onChange={this.handleChangeStore}/>
                </div>
            </div>
        )
    }

    // FILTERS PART END

    render() {
        return (
            <div className="order-history-root-div" ref={el => (this.componentRef = el)}>
                {this.getStoresModalInfos()}
                {this.getVendorsModalInfos()}
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                            <Title
                                paramsPageTitle="Order History"
                            />
                        </Grid.Column>
                        <Grid.Column width={8} className="col-right">
                            {this.state.renderStoreInfos ? this.getStoresHtml() : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.state.renderStoreInfos ? this.getOrderHistoryFilters() : ''}
                 <div className="order-history-table-div">
                     {this.getOrderHistoryTable()}
                 </div>
                <NotificationContainer/>
            </div>
        );
    }
}

export default OrderHistory;
