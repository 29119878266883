import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/products-admin-edit.css';
import {Input, Button, Select, Form, TextArea} from "semantic-ui-react";
import ModalImage from "react-modal-image";
import Request from "../../Request";
import Title from 'components/Title';

class ProductsEdit extends React.Component {

    state = {
        editId: null,
        vendor_id: null,
        product:{},
        category: {},
        categories: [],
        categoryOptions: [],
        renderCategory: false,
        file: null,
        filename: null,
        loading: false,
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            if(this.props.match.params.id !== 'new') {
                this.setState({editId: this.props.match.params.id}, ()=>{
                    this.getProduct();
                });
            }
            else {
                this.setState({editId: this.props.match.params.id})
            }

        }
        if (this.props.match.params.vendorId) {
            this.setState({vendor_id: this.props.match.params.vendorId}, ()=>{
                this.getCategories();
            })
        }
    }

    getProduct = () => {
        if(this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/products/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let product = JSON.parse(response);
                    if(product && product.success === true && product.data) {
                        this.setState({product: product.data, vendor_id: product.data.vendor_id}, () => {
                            this.getCategories();
                        });
                    }
                    else {
                        this.setState({product: {}});
                    }
                })
                .catch(error => {
                    this.setState({product: {}});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get User', 'Error');
                    }
                });
        }
    };

    getCategories = () => {
        if(this.state.vendor_id) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + this.state.vendor_id + '/categories';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let noCategory = {id:0, name:"No Category"};
                    let categories = JSON.parse(response);
                    if(categories && categories.success === true && categories.data) {
                        categories.data.unshift(noCategory);
                        this.setState({categories: categories.data, loading: false}, () => {
                            this.transformCategoriesToOptions();
                            this.pickDefaultCategory();
                            this.setState({renderCategory: true});
                        });
                    }
                    else {
                        this.setState({categories: [noCategory], renderCategory: true, loading: false});
                    }
                })
                .catch(error => {
                    this.setState({categories: [],renderCategory: true, loading: false});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Categories', 'Error');
                    }
                });
        }
    };

    saveNewProduct = () => {
        this.setState({loading: true});
        let product = this.state.product;
        let vendor_id = this.state.vendor_id;
        let editId = this.state.editId;
        if(editId === 'new') {
            new Promise((resolve, reject) => {
                let path = '/products/' + vendor_id;
                let params = {
                    category_id: this.state.category.id,
                    item_no: product.item_no,
                    description: product.description,
                    cost: product.cost,
                    wholesale: product.wholesale,
                    retail: product.retail,
                    um: product.um
                };
                if(this.state.filename && this.state.file) {
                    params.file = this.state.file;
                    params.filename = this.state.filename;
                }
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved Product', 'Success');
                    this.resetFile();
                    this.redirectToProducts();
                })
                .catch(error => {
                    this.setState({loading: false});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save Product', 'Error');
                    }
                });
        }
    };

    updateProduct = () => {
        this.setState({loading: true});
        let product = this.state.product;
        let editId = this.state.editId;
        if(editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/products/' + editId;
                let params = {
                    category_id: this.state.category.id,
                    item_no: product.item_no,
                    description: product.description,
                    cost: product.cost,
                    wholesale: product.wholesale,
                    retail: product.retail,
                    um: product.um
                };
                if(this.state.filename && this.state.file) {
                    params.file = this.state.file;
                    params.filename = this.state.filename;
                }
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Product', 'Success');
                    this.resetFile();
                    this.getProduct();
                })
                .catch(error => {
                    this.setState({loading: false});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Product', 'Error');
                    }
                });
        }
    };

    pickDefaultCategory = () => {
        if(this.state.product.category_id) {
            const foundCategory = this.state.categories.find(element => element.id === parseInt(this.state.product.category_id));
            if(foundCategory) {
                this.setState({category: foundCategory});
            }
        }
        else {
            this.setState({category: this.state.categories[0]});
        }

    };

    transformCategoriesToOptions = () => {
        let categories = this.state.categories;
        let options = [];
        categories.forEach((categorie, index) => {
            let tempOption = {
                id: categorie.id,
                key: categorie.id,
                value: categorie.id,
                text: categorie.name
            };
            options.push(tempOption);
        });
        this.setState({categoryOptions: options})
    };

    handleChangeCategory = (event, data) => {
        const foundCategory = this.state.categories.find(element => element.id === parseInt(data.value));
        if(foundCategory) {
            this.setState({category: foundCategory});
        }
    };

    redirectToProducts = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/products'
    };

    handleInputChanged = (event, field) => {
        let product = this.state.product;
        product[field] = event.target.value;
        this.setState({product: product});
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({filename: event.target.files[0].name});
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result
                });
            };
        }
    };

    resetFile = () => {
        this.setState({file: null, filename: null});
    };

    render() {
        let product = this.state.product;
        return (
            <div className="products-admin-edit-root">
                <Title
                    paramsPageTitle={this.state.editId === 'new' ? 'Product NEW' : 'Product EDIT'}
                />
                <div className="products-admin-edit-fields">
                    <b>Item #:</b>
                    <Input
                        fluid
                        value={product.item_no}
                        onChange={(e)=>this.handleInputChanged(e, 'item_no')}
                    />
                    <br/>
                    <b>Description:</b>
                    <Form>
                        <TextArea value={product.description} onChange={(e)=>this.handleInputChanged(e, 'description')}/>

                    </Form>
                    <br/>
                    <b>Cost:</b>
                    <Input
                        fluid
                        value={product.cost}
                        onChange={(e)=>this.handleInputChanged(e, 'cost')}
                    />
                    <br/>
                    <b>Wholesale:</b>
                    <Input
                        fluid
                        value={product.wholesale}
                        onChange={(e)=>this.handleInputChanged(e, 'wholesale')}
                    />
                    <br/>
                    <b>Retail:</b>
                    <Input
                        fluid
                        value={product.retail}
                        onChange={(e)=>this.handleInputChanged(e, 'retail')}
                    />
                    <b>U/M:</b>
                    <Input
                        fluid
                        value={product.um}
                        onChange={(e)=>this.handleInputChanged(e, 'um')}
                    />
                    <br/>
                    <b>Category:</b>
                    <Select
                        className="products-admin-edit-categorys"
                        text={this.state.category.name}
                        value={this.state.category.id}
                        options={this.state.categoryOptions}
                        onChange={this.handleChangeCategory}
                    />
                    <div className="products-admin-edit-file-input-div">
                        <div className="fileInput">
                            <input ref={this.fileInput} type="file" accept=".jpeg, .jpg, .png, .gif, .svg" onChange={this.handleFileChanged}/>
                        </div>
                        <div className="fileInputTypes">(jpg, jpeg, png, gif, svg)</div>
                    </div>
                    { this.state.product.file ?
                        <div className="products-edit-images-media-div">
                            <ModalImage
                                className="products-edit-images"
                                small={this.state.product.file}
                                large={this.state.product.file}
                                alt=""
                            />
                        </div>
                        :''
                    }
                    <div className="products-admin-edit-buttons-div">
                        {this.state.loading ?
                            <Button loading color="orange" size="tiny">
                                Loading
                            </Button>
                            :
                            this.state.editId === 'new' ?
                                <Button color="orange" size="tiny" onClick={this.saveNewProduct}>Submit</Button>
                                :
                                <Button color="orange" size="tiny" onClick={this.updateProduct}>Update</Button>
                        }
                        <Button color="orange" size="tiny" onClick={this.redirectToProducts}>Cancel</Button>
                    </div>
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default ProductsEdit;