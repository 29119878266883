import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import '../../assets/css/pages/managment/address-book-edit.css';
import {Grid, Input, Button, Checkbox, Select} from "semantic-ui-react";
import Request from "../../Request";
import Title from 'components/Title';

class AddressBookEdit extends React.Component {

    state = {
        renderCategories: false,
        editId: null,
        address: {},
        categories: [],
        tabletSize: window.innerWidth <= 768,
        mobileSize: window.innerWidth <= 450,
        store: {},
        stores: [],
        storeOptions: [],
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({editId: this.props.match.params.id}, () => {
                this.getAddress();
                this.getStores();
            })
        }
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({tabletSize: window.innerWidth <= 768, mobileSize: window.innerWidth <= 450});
    }

    getAddress = () => {
        if(this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/address-book/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let address = JSON.parse(response);
                    if(address && address.success === true && address.data && address.data[0]) {
                        this.setState({address: address.data[0]}, () => {
                            this.getAddressBookCategories();
                        });
                    }
                    else {
                        this.setState({address: {}}, () => {
                            this.getAddressBookCategories();
                        });
                    }
                })
                .catch(error => {
                    this.setState({address: {}}, () => {
                        this.getAddressBookCategories();
                    });
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Address', 'Error');
                    }
                });
        }
        else {
            this.setState({address: {}}, () => {
                this.getAddressBookCategories();
            });
        }
    };

    getAddressBookCategories = () => {
        new Promise((resolve, reject) => {
            let path = '/address-book/categories';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let categories = JSON.parse(response);
                if(categories && categories.success === true && categories.data) {
                    this.setState({categories: categories.data}, () => {
                        this.checkCategories();
                    });
                }
                else {
                    this.setState({categories: []}, () => {
                        this.checkCategories();
                    });
                }
            })
            .catch(error => {
                this.setState({categories: []}, () => {
                    this.checkCategories();
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Address Book categories', 'Error');
                }
            });
    };

    updateAddress = () => {
        let categories = this.getAllCheckedCategories();
        let address = this.state.address;
        let editId = this.state.editId;

        if(editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/address-book/' + editId;
                let params = {
                    name: address.name,
                    email: address.email,
                    contact_name: address.contactName,
                    street: address.street,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    phone: address.phone,
                    phone2: address.phone2,
                    fax: address.fax,
                    website: address.website,
                    categories: categories.join(',')
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated address', 'Success');
                    this.redirectBack();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update address', 'Error');
                    }
                });
        }
    };

    getStores = () => {
        if(this.state.editId === 'new') {
            new Promise((resolve, reject) => {
                let path = '/stores';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let stores = JSON.parse(response);
                    if(stores && stores.success === true && stores.data) {
                        this.setState({stores: stores.data, store: stores.data[0]}, () => {
                            this.transformStoresToOptions();
                        });
                    }
                    else {
                        this.setState({stores: []});
                    }
                })
                .catch(error => {
                    this.setState({stores: []});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Stores', 'Error');
                    }
                });
        }
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.id,
                key: store.id,
                value: store.id,
                text: store.title
            };
            options.push(tempOption);
        });
        this.setState({storeOptions: options})
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/management/address-book';
    };

    saveNewAddress = () => {
        let categories = this.getAllCheckedCategories();
        let address = this.state.address;
        let editId = this.state.editId;
        if(editId === 'new' && this.state.store.id) {
            new Promise((resolve, reject) => {
                let path = '/address-book/' + this.state.store.id;
                let params = {
                    name: address.name,
                    email: address.email,
                    contact_name: address.contactName,
                    street: address.street,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    phone: address.phone,
                    phone2: address.phone2,
                    fax: address.fax,
                    website: address.website,
                    categories: categories.join(',')
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved address', 'Success');
                    this.redirectBack();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save address', 'Error');
                    }
                });
        }
    };

    getAllCheckedCategories = () => {
        let categories = this.state.categories;
        let checked = [];

        categories.forEach((categorie) => {
            if(categorie.included) {
                checked.push(categorie.id);
            }
        });
        return checked;
    };

    checkCategories = () => {
        let categories = this.state.categories;
        let address = this.state.address;
        if(address && address.categories && address.categories.length > 0 && categories.length > 0) {
            categories.forEach((categorie) => {
                categorie.included = address.categories.includes(categorie.id);
            });
        }
        else {
            categories.forEach((categorie) => {
                categorie.included = false;
            });
        }
        this.setState({categories: categories}, () => {
            this.setState({renderCategories: true})
        });
    };

    checkboxChanged = (index) => {
        let categories = this.state.categories;
        let categorie = categories[index];
        if(categorie) {
            categorie.included = !categorie.included;
            categories[index] = categorie;
            this.setState({categories: categories});
        }
    };

    getCategoriesGrid = () => {
        let categories = this.state.categories;
        return (
            <Grid>
                <Grid.Row columns={this.state.mobileSize ? 1 : (this.state.tabletSize ? 3 : 6)}>
                    {categories.map((categorie, index) => {
                        return (
                            <Grid.Column key={index} className="address-book-edit-checkbox-column">
                                <Checkbox className="address-book-edit-checkbox" label={{ children: categorie.name }} checked={categorie.included} onChange={()=>this.checkboxChanged(index)}/>
                            </Grid.Column>
                        );
                    })}
                </Grid.Row>
            </Grid>
        );
    };

    handleInputChanged = (event, field) => {
        let address = this.state.address;
        address[field] = event.target.value;
        this.setState({address: address});
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.id === parseInt(data.value));
        if(foundStore) {
            this.setState({store: foundStore});
        }
    };


    getInputFiels = () => {
        let address = this.state.address;
        return (
            <div>
                { this.state.editId === 'new' ?
                    <div className="address-book-edit-stores">
                        <b>Store:</b><br/>
                        <Select
                            text={this.state.store.title}
                            value={this.state.store.id}
                            options={this.state.storeOptions}
                            onChange={this.handleChangeStore}
                        />
                    </div>
                    :''
                }
                <b>Name: </b><br/>
                <Input
                    fluid
                    value={address.name || ''}
                    onChange={(e) => this.handleInputChanged(e, 'name')}
                />
                <br/>
                <b>Email: </b><br/>
                <Input
                    fluid
                    value={address.email || ''}
                    onChange={(e) => this.handleInputChanged(e, 'email')}
                />
                <br/>
                <b>Contact Name: </b><br/>
                <Input
                    fluid
                    value={address.contactName || ''}
                    onChange={(e) => this.handleInputChanged(e, 'contactName')}
                />
                <br/>
                <b>Street: </b><br/>
                <Input
                    fluid
                    value={address.street || ''}
                    onChange={(e) => this.handleInputChanged(e, 'street')}
                />
                <br/>
                <b>City: </b><br/>
                <Input
                    fluid
                    value={address.city || ''}
                    onChange={(e) => this.handleInputChanged(e, 'city')}
                />
                <br/>
                <b>State: </b><br/>
                <Input
                    fluid
                    value={address.state || ''}
                    onChange={(e) => this.handleInputChanged(e, 'state')}
                />
                <br/>
                <b>ZIP Code: </b><br/>
                <Input
                    fluid
                    value={address.zip || ''}
                    onChange={(e) => this.handleInputChanged(e, 'zip')}
                />
                <br/>
                <b>Phone number: </b><br/>
                <Input
                    fluid
                    value={address.phone || ''}
                    onChange={(e) => this.handleInputChanged(e, 'phone')}
                />
                <br/>
                <b>Phone number 2: </b><br/>
                <Input
                    fluid
                    value={address.phone2 || ''}
                    onChange={(e) => this.handleInputChanged(e, 'phone2')}
                />
                <br/>
                <b>Fax: </b><br/>
                <Input
                    fluid
                    value={address.fax || ''}
                    onChange={(e) => this.handleInputChanged(e, 'fax')}
                />
                <br/>
                <b>Website: </b><br/>
                <Input
                    fluid
                    value={address.website || ''}
                    onChange={(e) => this.handleInputChanged(e, 'website')}
                />
            </div>
        );
    };

    render() {
        return (
            <div className="address-book-edit-root">                
                <Title
                    paramsPageTitle="Address book"
                />
                <div className="address-book-edit-categories-div">
                    {this.state.renderCategories ? this.getCategoriesGrid() : ''}
                </div>
                <div className="address-book-edit-inputs-div">
                    {this.getInputFiels()}
                </div>
                <Button color="orange" onClick={this.state.editId === 'new' ? this.saveNewAddress : this.updateAddress}>Save</Button>
                <Button color="orange" onClick={this.redirectBack}>Cancel</Button>
                <NotificationContainer/>
            </div>
        )
    }
}

export default AddressBookEdit;