import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/users-admin-edit.css';
import {Input, Button} from "semantic-ui-react";
import Request from "../../Request";
import Title from 'components/Title';

class VendorsAdminEdit extends React.Component {

    state = {
        editId: null,
        vendor: {}
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({editId: this.props.match.params.id}, ()=>{
                this.getVendor();
            })
        }
    }

    getVendor = () => {
        if(this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + this.state.editId + '/edit';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let vendor = JSON.parse(response);
                    if(vendor && vendor.success === true && vendor.data) {
                        this.setState({vendor: vendor.data});
                    }
                    else {
                        this.setState({vendor: {}});
                    }
                })
                .catch(error => {
                    this.setState({vendor: {}});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Vendor', 'Error');
                    }
                });
        }
    };

    saveNewVendor = () => {
        let vendor = this.state.vendor;
        let editId = this.state.editId;
        if(editId === 'new') {
            new Promise((resolve, reject) => {
                let path = '/vendors';
                let params = {
                    name: vendor.name,
                    account_no: vendor.accountNo,
                    email: vendor.email,
                    street1: vendor.street1,
                    street2: vendor.street2,
                    city: vendor.city,
                    state: vendor.state,
                    zip: vendor.zip,
                    phone: vendor.phone,
                    phone2: vendor.phone2,
                    sales_contact: vendor.salesContact,
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved Vendor', 'Success');
                    this.redirectBackToVendors();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save Vendor', 'Error');
                    }
                });
        }
    };

    updateVendor = () => {
        let vendor = this.state.vendor;
        let editId = this.state.editId;
        if(editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + editId;
                let params = {
                    name: vendor.name,
                    account_no: vendor.accountNo,
                    email: vendor.email,
                    street1: vendor.street1,
                    street2: vendor.street2,
                    city: vendor.city,
                    state: vendor.state,
                    zip: vendor.zip,
                    phone: vendor.phone,
                    phone2: vendor.phone2,
                    sales_contact: vendor.salesContact,
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Vendor', 'Success');
                    this.redirectBackToView();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Vendor', 'Error');
                    }
                });
        }
    };

    handleInputChanged = (event, field) => {
        let vendor = this.state.vendor;
        vendor[field] = event.target.value;
        this.setState({vendor: vendor});
    };

    redirectBackToVendors = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors';
    };

    redirectBackToView = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors/' + this.state.vendor.id;
    };

    render() {
        let vendor = this.state.vendor;
        return (
            <div className="vendor-admin-edit-root">                
                <Title
                    paramsPageTitle={this.state.editId === 'new' ? 'vendor NEW' : 'vendor EDIT'}
                />
                <div className="users-admin-edit-fields-div">
                    <b>Name*:</b><br/>
                    <Input
                        fluid
                        value={vendor.name || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'name')}
                    />
                    <br/>
                    <b>Account #*:</b><br/>
                    <Input
                        fluid
                        value={vendor.accountNo || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'accountNo')}
                    />
                    <br/>
                    <b>Email*:</b><br/>
                    <Input
                        fluid
                        value={vendor.email || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'email')}
                    />
                    <br/>
                    <b>Street1*:</b><br/>
                    <Input
                        fluid
                        value={vendor.street1 || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'street1')}
                    />
                    <br/>
                    <b>Street2:</b><br/>
                    <Input
                        fluid
                        value={vendor.street2 || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'street2')}
                    />
                    <br/>
                    <b>City*:</b><br/>
                    <Input
                        fluid
                        value={vendor.city || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'city')}
                    />
                    <br/>
                    <b>State*:</b><br/>
                    <Input
                        fluid
                        value={vendor.state || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'state')}
                    />
                    <br/>
                    <b>Zip*:</b><br/>
                    <Input
                        fluid
                        value={vendor.zip || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'zip')}
                    />
                    <br/>
                    <b>Phone*:</b><br/>
                    <Input
                        fluid
                        value={vendor.phone || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'phone')}
                    />
                    <br/>
                    <b>Phone2:</b><br/>
                    <Input
                        fluid
                        value={vendor.phone2 || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'phone2')}
                    />
                    <br/>
                    <b>Sales Contact*:</b><br/>
                    <Input
                        fluid
                        value={vendor.salesContact || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'salesContact')}
                    />
                    <br/>
                    {this.state.editId === 'new' ?
                        <div>
                            <Button color="orange" onClick={this.saveNewVendor}>Submit</Button>
                            <Button color="orange" onClick={this.redirectBackToVendors}>Back</Button>
                        </div>
                        :
                        <div>
                            <Button color="orange" onClick={this.updateVendor}>Update</Button>
                            <Button color="orange" onClick={this.redirectBackToView}>Cancel</Button>
                        </div>
                    }
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default VendorsAdminEdit;