import React from 'react';
import {Button, Card, Header, Icon, Image, Modal, Select} from "semantic-ui-react";
import './../../assets/css/pages/admin/products.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';

class Products extends React.Component {
    state = {
        vendor: {},
        vendors: [],
        vendorOptions: [],
        showDeactivatedItems: 1,
        products: [],
        renderProducts: false,
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getVendors();
    }

    getVendors = () => {
        new Promise((resolve, reject) => {
            let path = '/vendors';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let vendors = JSON.parse(response);
                if(vendors && vendors.success === true && vendors.data) {
                    this.setState({vendors: vendors.data, vendor: vendors.data[0]}, () => {
                        this.transformVendorsToOptions();
                        this.getProducts();
                    });
                }
                else {
                    this.setState({vendors: []});
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            })
            .catch(error => {
                this.setState({vendors: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            });
    };

    getProducts = () => {
        new Promise((resolve, reject) => {
            let path = '/vendors/' + this.state.vendor.id + '/products?activeP=' + this.state.showDeactivatedItems;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let products = JSON.parse(response);
                if(products && products.success === true && products.data) {
                    this.setState({products: products.data}, () => {
                        this.setState({renderProducts:true})
                    });
                }
                else {
                    this.setState({products: []}, () => {
                        this.setState({renderProducts:true})
                    });
                    NotificationManager.error('Could not get Products', 'Error');
                }
            })
            .catch(error => {
                this.setState({products: []}, () => {
                    this.setState({renderProducts:true})
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Products', 'Error');
                }
            });
    };

    deactivateProduct = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id + '/deactivate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deactivated Product', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Product', 'Error');
                    }
                });
        }
    };

    activateProduct = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id + '/activate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully activated Product', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate Product', 'Error');
                    }
                });
        }
    };

    deleteProduct = (id) => {
        this.setState({modalOpen: false});
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deleted Product', 'Success');
                    this.getProducts();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not delete Product', 'Error');
                    }
                });
        }
    };

    cloneProduct = (id) => {
        if(id) {
            new Promise((resolve, reject) => {
                let path = '/products/' + id + '/clone';
                this.request.post(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true && response.data && response.data.id) {
                        let id = response.data.id;
                        this.redirectToEdit(id);
                        NotificationManager.success('Successfully cloned Product', 'Success');
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not clone Product', 'Error');
                    }
                });
        }
    };

    transformVendorsToOptions = () => {
        let vendors = this.state.vendors;
        let options = [];
        vendors.forEach((vendor, index) => {
            let tempOption = {
                id: vendor.id,
                key: vendor.id,
                value: vendor.id,
                text: vendor.name
            };
            options.push(tempOption);
        });
        this.setState({vendorOptions: options})
    };

    handleChangeVendor = (event, data) => {
        const foundVendor = this.state.vendors.find(element => element.id === parseInt(data.value));
        if(foundVendor) {
            this.setState({vendor: foundVendor}, ()=> {
                this.getProducts();
            });
        }
    };

    showDeactivatedItemsToogle = () => {
        let showDeactivatedItems = this.state.showDeactivatedItems;
        showDeactivatedItems = showDeactivatedItems === 0 ? 1 : 0;
        this.setState({showDeactivatedItems: showDeactivatedItems}, () => {
            this.getProducts();
        });
    };

    getProductCards = () => {
        let cards = [];
        let products = this.state.products;
        products.forEach((product, index) => {
            cards.push(
                <Card className="products-admin-card" fluid key={index}>
                    <Card.Content className="users-admin-card-header" header={product.category.name} />
                    <Card.Content description={this.getOneCardDescription(product.products, index)} />
                </Card>
            );
        });
        return cards;
    };

    redirectToEdit = (productId) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/products/' + productId;
    };

    redirectToNew = (productId) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/products/' + productId + '/' + this.state.vendor.id;
    };

    getOneCardDescription = (products, index) => {
        return (
            <div key={'table-div-' + index}>
                <div className="products-admin-table-div">
                    <table className="products-admin-table" align="center">
                        <thead>
                        <tr>
                            <th className="products-admin-table-header">Image</th>
                            <th className="products-admin-table-header">Item #</th>
                            <th className="products-admin-table-header">Description</th>
                            <th className="products-admin-table-header">Cost</th>
                            <th className="products-admin-table-header">Retail</th>
                            <th className="products-admin-table-header">U/M</th>
                            <th className="products-admin-table-header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        { products && Array.isArray(products) ?
                            products.map((product, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            { product.file ?
                                                <Image avatar size="massive" src={product.file || ''} />
                                                : ''
                                            }
                                        </td>
                                        <td>
                                            <a href={'/admin/products/' + product.id}><b>{product.item_no || ''}</b></a>
                                        </td>
                                        <td>{product.description || ''}</td>
                                        <td>{product.cost || ''}</td>
                                        <td>{product.retail || ''}</td>
                                        <td>{product.um || ''}</td>

                                            { this.state.showDeactivatedItems ?
                                                <td className="products-admin-table-action-header">
                                                    <Button.Group icon>
                                                        <Button size='tiny' title="Edit Product" onClick={()=>this.redirectToEdit(product.id)}>
                                                            <Icon name='pencil' />Edit
                                                        </Button>
                                                        <Button size='tiny' title="Clone Product" onClick={()=>this.cloneProduct(product.id)}>
                                                            <Icon name='copy' />Clone
                                                        </Button>
                                                        <Button size='tiny' title="Deactivate Product" onClick={()=>this.deactivateProduct(product.id)}>
                                                            <Icon name='trash' />Deactivate
                                                        </Button>
                                                    </Button.Group>
                                                </td>
                                                :
                                                <td className="products-admin-table-action-header-deactivated">
                                                    <Button.Group icon>
                                                        <Button size='tiny' title="Edit Product" onClick={()=>this.redirectToEdit(product.id)}>
                                                            <Icon name='pencil' />Edit
                                                        </Button>
                                                        <Button size='tiny' title="Clone Product" onClick={()=>this.cloneProduct(product.id)}>
                                                            <Icon name='copy' />Clone
                                                        </Button>
                                                        <Button size='tiny' title="Restore Product" onClick={()=>this.activateProduct(product.id)}>
                                                            <Icon name='refresh' />Restore
                                                        </Button>
                                                        <Button size='tiny' title="Delete Product" onClick={()=>this.removeProductConfirmation(product.id)}>
                                                            <Icon name='times' />Delete
                                                        </Button>
                                                    </Button.Group>
                                                </td>
                                            }
                                    </tr>
                                )
                            })
                            :''
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if(removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Product' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Product?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={()=>this.deleteProduct(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    removeProductConfirmation = (id) => {
        this.setState({removeId: id, modalOpen:true});
    };

    render() {
        return (
            <div className="products-admin-root">
                <Title
                    paramsPageTitle="Products"
                />
                <div className="products-admin-filters">
                    <b>Vendor</b><br/>
                    <Select
                        className="products-admin-filters-vendors"
                        text={this.state.vendor.name}
                        value={this.state.vendor.id}
                        options={this.state.vendorOptions}
                        onChange={this.handleChangeVendor}
                    />
                    { !this.state.showDeactivatedItems ?
                        <div className="products-admin-filters-buttons">
                            <Button color="orange" onClick={this.showDeactivatedItemsToogle} size="tiny">Hide Deactivated Items</Button>
                        </div>
                        :
                        <div className="products-admin-filters-buttons">
                            <Button color="orange" onClick={this.showDeactivatedItemsToogle} size="tiny">Show Deactivated Items</Button>
                        </div>
                    }
                    <div className="products-admin-add-button">
                        <Button color="orange" onClick={()=>this.redirectToNew('new')} size="tiny">
                            <Icon name='plus' />
                            Product
                        </Button>
                    </div>
                    <div className="products-admin-cards">
                        {this.state.renderProducts ? this.getProductCards() : ''}
                    </div>
                </div>
                {this.getConfirmationModal()}
                <NotificationContainer/>
            </div>
        )
    }
}

export default Products;