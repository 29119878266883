import React from 'react';
import {Grid, Select, Header, Accordion, Icon} from "semantic-ui-react";
import '../../assets/css/pages/recipes.css';
import ModalImage from "react-modal-image";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Request from "../../Request";
import Title from "../../components/Title";

class AllRecipes extends React.Component
{
    state = {
        recipeType: null,
        activeRecipeName: '',
        recipes: [],
        renderRecipes: false,
        store: {},
        globalStore: {
            franchisees: [],
            managers: [],
            store: {
                title: 'Global'
            }
        },
        changeStore: false,
        stores: [],
        storesOptions: [],
        openModal: false,
        renderStoreInfos: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        isGlobalStore: true,
        activeIndex: 0,
        recipeTypeOptions: [
            {
                id: 0,
                key: 'espresso-bar',
                value: 'espresso-bar',
                text: 'Espresso Bar'
            },
            {
                id: 1,
                key: 'coffee-bar',
                value: 'coffee-bar',
                text: 'Coffee Bar'
            },
            {
                id: 2,
                key: 'tea-bar',
                value: 'tea-bar',
                text: 'Tea Bar'
            },
            {
                id: 3,
                key: 'cooling-aids',
                value: 'cooling-aids',
                text: 'Cooling Aids'
            },
            {
                id: 4,
                key: 'cofftails-dreams',
                value: 'cofftails-dreams',
                text: 'Cofftails & Dreams'
            },
            {
                id: 5,
                key: 'batch-recipes',
                value: 'batch-recipes',
                text: 'Batch Recipes'
            },
            {
                id: 6,
                key: 'hot-food-prep',
                value: 'hot-food-prep',
                text: 'Hot Food Prep'
            },
        ],
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getStoresData();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if(this.props.match.params.type) {
            const recipeName = this.state.recipeTypeOptions.find(element => element.key === this.props.match.params.type).text;
            this.setState({recipeType: this.props.match.params.type,  activeRecipeName: recipeName});
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.type !== this.state.recipeType) {
            this.getStoresData();
            const recipeName = this.state.recipeTypeOptions.find(element => element.key === this.props.match.params.type).text;
            this.setState({recipeType: this.props.match.params.type,  activeRecipeName: recipeName});
        }    
    }

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768}, () => {
            if(!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }

    // FILTERS

    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {

                    let activeStore = localStorage.getItem('activeStore');
                    if(activeStore && typeof activeStore !== 'undefined') {
                        activeStore = {
                            franchisees: [],
                            managers: [],
                            store: JSON.parse(activeStore)
                        };
                    } else {
                        activeStore = this.state.globalStore
                    }
                    
                    this.setState({stores: stores.data, store: activeStore}, () => {
                        this.transformStoresToOptions();
                        this.setState({renderStoreInfos: true});
                        this.getRecipes();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({stores: []});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({renderStoreInfos: true});
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [
            {
                id: 99999,
                key: 99999,
                value: 99999,
                text: 'Global'
            }
        ];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({storesOptions: options})
    };

    handleStoresInfoButton = () => {
        if(this.state.tabletSize) {
            this.setState({openModal: true})
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index)=>{
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                                None
                                            </span>
                                    }
                                </div>
                                :''
                            }
                        </Grid.Column> :''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 :16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({changeStore:true})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if(foundStore) {
            this.setState({changeStore:false, store: foundStore, isGlobalStore: false}, () => {
                this.getRecipes();
            });
            localStorage.setItem('activeStore', JSON.stringify(foundStore.store));
        }
        else if(parseInt(data.value) === 99999) {
            this.setState({changeStore:false, store: this.state.globalStore, isGlobalStore: true}, () => {
                this.getRecipes();
            });
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };
    // FILTERS END

    getRecipes = () => {
        new Promise((resolve, reject) => {
            let store = this.state.store.store.id ? '?store_id=' + this.state.store.store.id : '';
            let path = '/recipes/' + this.state.recipeType + '/files' + store;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let recipes = JSON.parse(response);
                if(recipes && recipes.success === true && recipes.data) {

                    if(Array.isArray(recipes.data) && recipes.data.length >0) {
                        this.setState({recipes: recipes.data}, () => {
                            this.setState({renderRecipes:true});
                        });
                    }
                    else {
                        this.setState({recipes: []}, () => {
                            this.setState({renderRecipes:true});
                        });
                    }
                }
                else {
                    this.setState({renderRecipes:true});
                }
            })
            .catch(error => {
                this.setState({recipes: []}, ()=>{
                    this.setState({renderRecipes:true});
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Recipes', 'Error');
                }
            });
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    getCardContent = (recipe, i) => {
        const { activeIndex } = this.state;
        return (
            <div className="accordion-item">
                <Accordion.Title
                    active={activeIndex === i}
                    index={i}
                    onClick={this.handleClick}
                    >
                    {recipe.name}
                    <Icon name='dropdown' />
                </Accordion.Title>
                <Accordion.Content 
                    active={activeIndex === i}
                    className='accordion-content'
                >
                    { 'undefined' !== typeof recipe.files &&
                        recipe.files.map((file, index) => {
                            return (
                                <div key={index} className='recipes-media'>
                                    { file.is_video ?
                                        <video className="batch-recipes-videos" controls key={index + 'batch-recipes-video'}>
                                            <source src={file.url} type="video/mp4"/>
                                        </video>
                                        :
                                        <div className="recipes-images-media-div" >
                                            <ModalImage
                                                key={index + 'recipes-image'}
                                                className="recipes-images"
                                                small={file.url}
                                                large={file.url}
                                                alt={recipe.name}
                                            />
                                            <span className='recipes-images-expand' onClick={(event) => this.openImageModal(event)}>Expand</span>
                                        </div>
                                        
                                    }
                                </div>
                                
                            );
                        })
                    }
                    <div className='recipes-content'>
                        <p dangerouslySetInnerHTML={{__html: recipe.content}}></p>
                    </div>
                </Accordion.Content>
            </div>  
        );
    };

    getNewOrdersFilters = () => {
        return (
            <div className="recipes-admin-filters">
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                            <Title
                                paramsPageTitle="Recipes"
                            />
                        </Grid.Column>
                        <Grid.Column width={8} className="col-right">
                            <Select
                                text={this.state.store.store.title}
                                value={this.state.store.store.id}
                                options={this.state.storesOptions}
                                onChange={this.handleChangeStore}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    };

    openImageModal = (event) => {
        const element = event.target;
        console.log(event);
        element.previousElementSibling.querySelector('img').click(); 
    }

    render() {
        return (
            <div className="recipes-page">
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                        <Title
                            paramsPageTitle="Recipes"
                        />
                        </Grid.Column>
                        { this.state.renderStoreInfos ?
                            <Grid.Column width={8} className="col-right">
                                <Select
                                    text={this.state.store.store.title}
                                    value={this.state.store.store.id}
                                    options={this.state.storesOptions}
                                    onChange={this.handleChangeStore}/>
                            </Grid.Column>
                        : '' }
                    </Grid.Row>
                </Grid>
                <br/>
                {
                    this.state.renderRecipes && this.state.recipes ?
                        this.state.recipes.length > 0 ?
                            <div className="recipes-content">
                                <Header as="h2">{this.state.activeRecipeName}</Header>
                                <Accordion fluid styled>   
                                    {       
                                        this.state.recipes.map((recipe, index) => {
                                            return this.getCardContent(recipe, index);
                                        })
                                    }
                                </Accordion>
                            </div>
                        : <p>No Recipes found</p>
                : '' }
                <br/>
                <NotificationContainer/>
            </div>
        );
    }
}

export default AllRecipes;
